import type { AdvisorNotificationCategories, ProactiveNotificationTypeCodes } from 'api/astrologers/interfaces';
import type { UserHoroscopeV2ItemPeriod } from 'api/horoscope/interfaces';
import type { CONTACT_US_METHODS } from 'constants/form';
import type { BANNERS as GUIDES_BANNERS } from 'constants/guides';
import type { PUSH_BEHAVIOR } from 'constants/messaging';
import type { MESSENGERS } from 'constants/messengers';
import type { MonetizationConfig } from 'constants/monetization-types';
import type { PATH_NAMES } from 'constants/routes';
import type { PRICE_LOCATION, TEXTS_ALIGN, SUB_FAMILY_TYPES, BADGE_ALIGN, SUB_TYPES } from 'constants/subscription';
import type { TAB_BAR_BADGES_TYPES } from 'constants/tab-bar-badges';
import type { PAYMENT_METHODS, Currency } from 'modules/payments/constants';
import type { REPORTS_PRODUCTS } from 'modules/payments/constants/product';
import type { ASTROLOGERS_PROMO_PLACES, TOP_TAB_IDS } from 'screens/advisors/constants';
import type { ADVISORS_CATALOG_BLOCK_IDS, AdvisorsCatalogStealthCategoryPid } from 'screens/advisors/screens/catalog/types';
import type {
  AstrologersMonetizationConfig,
  AstrologerConsultationPacks,
  AstrologerPackSpecialOffer,
  AstrologerPackWelcomeOffer,
  AstrologerPacksFlows,
  ASTROLOGER_TIP_PRODUCTS,
  AdvisorSoftCurrencyConfig,
} from 'screens/advisors/screens/monetization/constants/interfaces';
import type { AstroCalendarConfig, AstroCalendarNotificationConfig } from 'screens/astro-calendar/interfaces';
import type { TOP_TABS_IDS } from 'screens/birth-chart/constants';
import type { SKIP_BUTTON_PLACE } from 'screens/onboarding/palm/types';
import type { HelpDeskConfig } from 'screens/support/types';
import type { ADVISORS_ONB_PAGES_IDS, ASTROLOGERS_ONBOARDING_INAPP } from 'store/astrologers/onboarding/types';

import { defaultParams } from './default-params';
import type { ParamTypes } from './default-params';
import { getDefaultValue } from './utils';
// import type { SupportedLngs } from 'localization';

export interface SubscriptionButton {
  badgeTitle?: string;
  badgeAlign?: BADGE_ALIGN;
  isActive?: boolean;
  productId: string;
  offerId?: string;
  subTitle: string;
  title: string;
  rightText?: string;
  rightBottomText?: string;
  btnTitle?: string;
  disabled?: boolean;
}

export interface SubscriptionBase {
  title?: string;
  product?: string;
  btnTitle?: string;
  btnSubTitle?: string;
  priceText?: string;
  subTitle?: string;
  animatedBtn?: boolean;
  closeBtnTimeout?: number;
  subscribeOnToggleProduct?: boolean;
  hideSecurityText?: boolean;
  priceLocation?: PRICE_LOCATION;
  showTerms?: boolean;
}

export interface SubscriptionVertical extends SubscriptionBase {
  buttons: SubscriptionButton[];
  buttonsTextsPosition: TEXTS_ALIGN;
  buttonsWithCheckbox: boolean;
  hidePriceInfo: boolean;
}

interface SubscriptionFamilyBase extends SubscriptionBase {
  buttons: SubscriptionButton[];
  buttonsTextsPosition: TEXTS_ALIGN;
  buttonsWithCheckbox: boolean;
  hidePriceInfo: boolean;
  isCrossedPriceText?: boolean;
}

export interface SubscriptionPalm extends SubscriptionBase {
  product: string;
}

export interface SubscriptionPost extends SubscriptionBase {
  product: string;
  benefits: string[];
  btnBadgeTitle: string;
  hideBtnBadge: boolean;
}

export interface SubscriptionVerticalOneProduct extends Omit<SubscriptionBase, 'title'> {
  title: string | null;
  product: string;
  benefits: string[];
  btnBadgeTitle: string;
  hideBtnBadge: boolean;
}

export interface SubscriptionSubBenefits {
  title: string | null;
  subTitle: string | null;
  btnTitle: string | null;
  priceText: string | null;
  productId: string;
  closeBtnTimeout: number;
  productsByCurrency: {
    [key in Currency]?: string;
  };
  showTerms?: boolean;
  hideSecurityText?: boolean;
}

export interface SubscriptionBenefitsOffer {
  notActivePrice: string;
  trialText: string;
  trialTextEnabled: boolean;
  trialSubtitleText: string;
  trialSubtitleEnabled: boolean;
  btnTitle: string | null;
  btnBadgeEnabled: boolean;
  productId: string;
  closeBtnTimeout: number;
  productsByCurrency: {
    [key in Currency]?: string;
  };
  showTerms?: boolean;
  hideSecurityText?: boolean;
}

export interface Ads {
  bannersEnabled: boolean;
  interEnabled: boolean;
}

export interface Rescan {
  enabled: boolean;
  timeout: number;
  whoseHandPopupEnabled: boolean;
}

export interface SubscriptionFamily {
  isSwitchEnabledByDefault: boolean;
  [SUB_FAMILY_TYPES.BASIC]: SubscriptionFamilyBase;
  [SUB_FAMILY_TYPES.FAMILY]: SubscriptionFamilyBase;
}

export interface SurveyConfig {
  title: string;
  text: string;
  btnTitle: string;
  skipText: string;
  paidUrl: string;
  freeUrl: string;
  sessionNumber: number;
  intervalBetweenSessions: number;
  maxShowCount: number;
  version: number;
}

export interface SubscriptionTimer {
  showTerms: boolean;
  time: number;
  products: Array<{
    title: string;
    productId: string;
    isActive?: boolean;
  }>;
}

export interface SubscriptionSpecialOffer {
  productId: string;
  priceFormat: string;
  priceDescFormat: string;
  discount: number;
  time: number;
  btnTitle: string;
  closeBtnTimeout: number;
  showTerms: boolean;
  buttonAnimation: string;
}

export interface SubscriptionPromoOffer extends SubscriptionBase {
  buttons: SubscriptionButton[];
  buttonsTextsPosition: TEXTS_ALIGN;
  buttonsWithCheckbox: boolean;
  hidePriceInfo: boolean;
}

export interface PushConfig {
  afterUse: PUSH_BEHAVIOR;
  dailyHoroscope: PUSH_BEHAVIOR;
  other: PUSH_BEHAVIOR;
}

export interface WebOnetimeProduct {
  product: REPORTS_PRODUCTS;
  title: string;
  priceText: string;
  amount: number;
  currency: string;
  textLineThrough: string;
}

export interface ChatTooltipConfig {
  title: string;
  subtitle: string;
  buttonTitle: string;
  timer: number;
  enabled: boolean;
}

export interface AstroeventModalConfig {
  enable: boolean;
  timeout: number;
  noChatShowSession: number;
  noChatCloseRepeat: number;
  hasChatShowSession: number;
  hasChatCloseRepeat: number;
  hasChatResetShow: number;
}

export type AstrologersPromoConfig = {
  buttonTitle: string;
  places: {
    [key in ASTROLOGERS_PROMO_PLACES]?: boolean;
  };
};

export type MessengersModalConfig = {
  enabled: boolean;
  title?: string;
  buttonTitle?: string;
  buttons: {
    [key in MESSENGERS]?: boolean;
  };
};

export interface RateUs {
  enabled: boolean;
  isCustom: boolean;
  likesCountToShow: number;
  icons: 'smiles' | 'stars';
  nativeDelay: number;
  onboardingStep?: string;
  sessionStart: number;
  minRateToTrustPilot: number;
  showInSettings: boolean;
  showOnHomeDelay: number;
  showOnHomeSession: number;
  titleText?: string;
  // mode: 'modal' | 'banner';
}

export interface RemoteConfigState {
  isHoroscopeRandom: boolean;
  fbAEMenabled: boolean;
  isNotificationBadgeVisible: boolean;
  [SUB_TYPES.VERTICAL]: SubscriptionVertical;
  [SUB_TYPES.FAMILY]: SubscriptionFamily;
  [SUB_TYPES.PALM]: SubscriptionPalm;
  [SUB_TYPES.POST]: SubscriptionPost;
  [SUB_TYPES.PROMO_OFFER]: SubscriptionPromoOffer;
  promoOffers: {
    enabled: boolean;
    showLimitPerSession: number;
    showSessionsLimit: number;
  };
  subscriptionTimer: SubscriptionTimer;
  subscriptionSpecialOffer: SubscriptionSpecialOffer;
  [SUB_TYPES.VERTICAL_ONE]: SubscriptionVerticalOneProduct;
  [SUB_TYPES.SUB_BENEFITS]: SubscriptionSubBenefits;
  [SUB_TYPES.BENEFITS_OFFER]: SubscriptionBenefitsOffer;
  subBenefitsBannerEnabled: boolean;
  surveyConfig: SurveyConfig;
  monetizationConfig: MonetizationConfig;
  authZendeskToken: string;
  contactUsMethod: CONTACT_US_METHODS;
  helpdeskConfig: HelpDeskConfig;
  isDevScreenEnabled: boolean;
  subscriptions: string[];
  products: string[];
  showUpdateButtonOnYourDayWidget: boolean;
  showCancelSubscriptionBtn: boolean;
  remoteNotificationsEnabled: boolean;
  notificationsOnStart: boolean;
  notificationsOnDashboardSession: number;
  subAfterBackgroundSessionStart: number;
  availableSignInWithEmail: boolean;
  availableSignInSocial: boolean;
  isOnboarding: boolean;
  rescan: Rescan;
  onboardingLoadingDuration: number;
  onboardingLoadingAutoSkipDelay: number;
  onbPalmSkipBtnPlace: SKIP_BUTTON_PLACE | '';
  whereNavigateAfterOnboarding: PATH_NAMES | '';
  voiceCommandSuggestionMaxSeenTimes: number;
  voiceCommandSuggestionDisplayTimeout: number;
  tabBarBadges: {
    [key: string]: TAB_BAR_BADGES_TYPES;
  };
  todayWidgets: string[];
  cardOfDayWidgetBtnText: string | null;
  summaryWidgetConfig: {
    birthChartBlockEnabled: boolean;
    tagsEnabled: boolean;
    maxResponseWaitingTime: number;
    enableHoroscopesV2: {
      [key in UserHoroscopeV2ItemPeriod]: boolean;
    };
    pid: {
      [key in UserHoroscopeV2ItemPeriod]: number | string;
    };
  };
  readingsWidgets: string[];
  compatibilityWidgets: string[];
  randomWidgetsSequence: boolean;
  randomGuidesSequence: boolean;
  guideQuestionnaire: boolean;
  guideStyle: 'grid' | 'row';
  guidesBanners: {
    [GUIDES_BANNERS.ZODIAC_COMPATIBILITY]: boolean;
    [GUIDES_BANNERS.BIRTH_CHART]: boolean;
    [GUIDES_BANNERS.BIORHYTHMS]: boolean;
    [GUIDES_BANNERS.DATING]: boolean;
    [GUIDES_BANNERS.MARRIAGE_COMPATIBILITY]: boolean;
    [GUIDES_BANNERS.FIND_TRUE_LOVE]: boolean;
    [GUIDES_BANNERS.PALM_READING]: boolean;
  };
  rateUs: RateUs;
  sessionInterval: number;
  sessionsType: 'article' | 'bot' | 'podcast' | 'video';
  unlockAllSessions: boolean;
  modalCloseType: string;
  storyDurationTime: number;
  feedbackUrl: string;
  feedbackSession: number;
  prdReportsTimeout: number;
  prdInstructionEnabled: boolean;
  prdDetectLinesEnabled: boolean;
  prdDetectionEnabled: boolean;
  prdPointsAnimationDuration: number;
  defaultSubscriptionScreen: string;
  astrologersProduct: string;
  supportEmail: string;
  supportEmailWeb: string;
  policyLink: string;
  termsLink: string;
  onboardingFlow: string[];
  advisorsOnboardingFlow: ADVISORS_ONB_PAGES_IDS[];
  advisorsOnboardingInappType: ASTROLOGERS_ONBOARDING_INAPP;
  advisorsOnboardingSkipConnection: boolean;
  advisorsCatalogBlocks: ADVISORS_CATALOG_BLOCK_IDS[];
  advisorsCatalogStealthCategoryPid: AdvisorsCatalogStealthCategoryPid;
  advisorsPid: number | string;
  pushConfig: PushConfig;
  notificationCenter: {
    replaceTimeout: number;
    pwaInstructions: {
      enabled: boolean;
      title: string;
      sessionNumber: number;
      sessionStartNumber: number;
    };
    pdfReports: {
      enabled: boolean;
      title: string;
      text: string;
    };
    whatsapp: {
      enabled: boolean;
      title?: string;
      text?: string;
      sessionNumber: number;
      maxShowCount: number;
    };
    messengers: {
      enabled: boolean;
      title?: string;
      text?: string;
      sessionNumber: number;
      maxShowCount: number;
    };
    closeBtnSessionStartNumber: number;
  };
  pwaInstructions: {
    sessionStartNumber: number;
    showSessionsLimit: number;
    timeout: number;
    closeBtnTimeout: number;
    promocode: string;
    showAboutBtn: boolean;
    showInChat: boolean;
  };
  astrologistConfig: {
    connectionTimeout: number;
    connectionCloseTimeout: number;
    connectionButtonTitle: string;
    reconnectionTimeout: number;
    freeMinutes: number;
    freeMinutesForFreeUsers: number;
    enableAstrologistReview: boolean;
    pingTimeout: number;
    title?: string;
    btnTitle?: string;
    blurMessageEnabled?: boolean;
    skipConnection: boolean;
    connectionAutoNavigateToChat: boolean;
  };
  advisorNotificationConfig: {
    enabled: boolean;
    categories: AdvisorNotificationCategories[];
    numberOfDaysRandomMessages: number;
    numberOfDaysPause: number;
  };
  alternateAdvisorNotificationConfig: {
    enabled: boolean;
    type: ProactiveNotificationTypeCodes;
    categories: AdvisorNotificationCategories[];
    numberOfDaysAlternateMessages: number;
  };
  reactivateCloseButton: {
    link: string;
    title: string;
  };
  pdfReportsModalConfig: {
    title: string;
    text: string;
    btnTitle: string;
    timeout: number;
    sessionNumber: number;
    showSessionsLimit: number;
  };
  webOnetimeProductsDetails: WebOnetimeProduct[];
  isSkipAuthOnPressHiddenButtonEnabled: boolean;
  birthChartAvailableForInAppFromDate: string;
  birthChartTransitsTitle: string | null;
  birthChartBannerTargetTab: TOP_TABS_IDS;
  chatTimerTooltip: ChatTooltipConfig;
  astrologersMonetizationConfig: AstrologersMonetizationConfig;
  astrologersSecondsLeftToEnableTriggers: number;
  astrologerChatBtnTitle: string | null;
  astrologerChatDisclaimer: {
    enabled: boolean;
    expanded: boolean;
  };
  astroeventModalConfig: AstroeventModalConfig;
  astrologerFreeMinutesForRenew: number;
  astrologerFreeMinutesModalBtnTitle: string | null;
  astrologerFreeMinutesModalSession: number;
  minRateToTrustPilot: number;
  astrologersPromoConfig: AstrologersPromoConfig;
  messengersModalConfig: MessengersModalConfig;
  isNewYearBgEnabled: boolean;
  removeIdfmFromUrl: boolean;
  todayMatchesBlockV2Enabled: boolean;
  autoRefillModalConfig: {
    refillTimer: number;
    enableCloseButton: boolean;
  };
  enableAdvisorPalmReadingFirstSession: boolean;
  astrologerConsultationPacks: AstrologerConsultationPacks;
  astrologerConsultationDiscountPacks: AstrologerConsultationPacks;
  astrologerPackSpecialOffer: AstrologerPackSpecialOffer;
  astrologerPackWelcomeOffer: AstrologerPackWelcomeOffer;
  astrologerPackSpecialOffer2: AstrologerPackSpecialOffer;
  astrologerPacksFlows: AstrologerPacksFlows;
  isOneClickEnabled: boolean;
  advisorSoftCurrencyConfig: AdvisorSoftCurrencyConfig;
  tarotCardOfTheDayCalendarEnabled: boolean;
  astrologistBackendConfig: {
    sendPayments: boolean;
    palmReadingEnabled: boolean;
    symbolsPerSecond: number;
    maxTimeOfDelay: number;
  };
  receiveTarot: boolean;
  astroCalendarConfig: AstroCalendarConfig;
  astroCalendarNotificationConfig: AstroCalendarNotificationConfig;
  astroCalendarTutorialEnabled: boolean;
  astroCalendarWidgetTitle: string | null;
  astroCalendarWidgetButtonTitle: string | null;
  astroCalendarCompleteFirstTodoEnabled: boolean;
  tallyQuestionnaireConfig: {
    enabled: boolean;
    link: string;
    showCloseButton: boolean;
  };
  availablePaymentMethods: PAYMENT_METHODS[];
  advisorReviewTipsConfig: {
    enabled: boolean;
    availablePaymentMethods: PAYMENT_METHODS[];
    products: {
      [key in ASTROLOGER_TIP_PRODUCTS]: {
        activePrice: string;
        price: number;
        currency: Currency;
        EUR?: {
          activePrice: string;
          price: number;
        };
        CAD?: {
          activePrice: string;
          price: number;
        };
        AUD?: {
          activePrice: string;
          price: number;
        };
      };
    };
  };
  installMobileNotification: {
    enabled: boolean;
    closeEnabled: boolean;
    promocode: string;
  };
  installMobileAppModalConfig: {
    closeBtnTimeout: number;
    subtitle: string;
    title: string;
    showSessionLimits: number;
    enabled: boolean;
    promocode: string;
  };
  advisorsMyChatsListEnabled: boolean;
  advisorHoroscopeEnabled: boolean;
  advisorHoroscopeChatMsgEnabled: boolean;
  advisorHoroscopeTimerDuration: number;
  advisorTabs: TOP_TAB_IDS[];
  advisorsServicesConfig: {
    enabled: boolean;
    showPricesInList: boolean;
    showPricesInBtn: boolean;
    navigateAfterEndChatEnabled: boolean;
    products: {
      [key in string]: {
        activePrice: string;
        price: number;
        currency: Currency;
        EUR?: {
          activePrice: string;
          price: number;
        };
        CAD?: {
          activePrice: string;
          price: number;
        };
        AUD?: {
          activePrice: string;
          price: number;
        };
      };
    };
  };
  paymentFailedTriggerNotificationEnabled: boolean;
  todayContentTipsEnabled: boolean;
  todayContentDoDontEnabled: boolean;
  todayContentPid: {
    horoscopesTips: number | string;
    horoscopesDoDont: number | string;
    horoscopesTipsDoDont: number | string;
  };
  appLink: string;
  loyaltyCriteria: {
    purchases?: number;
    advisorRating?: number;
    appRating?: number;
  };
  whereNavigateAfterOnboardingPWA: PATH_NAMES | '';
  localizations: { [key in string]?: any }; // TODO: Replace to "key in SupportedLngs" when the issue will be fixed: https://github.com/vega/ts-json-schema-generator/issues/2012
  enableVoiceMessages: boolean;
  trustPilotPromoConfig: {
    enabled: boolean;
    promocode: string;
    title: string;
    btnTitle: string;
  };
  birthChartApiUrl: string;
  enableIncreasingTimer: boolean;
  avatarHoroscopeLabelEnabled: boolean;
  birthChartReportInSubEnabled: boolean;
  advisorDefaultSuggestCategory: string;
  advisorOnlineStatusesEnabled: boolean;
  stealthModeEnabled: boolean;
  readingChatWithInputFlowEnabled: boolean;
  greyEndChatBtnEnabled: boolean;
  advisorEndChatModalEnabled: boolean;
  softCurrencyShowOnAdvisorCard: boolean;
  installPWANotification: {
    enabled: boolean;
    closeEnabled: boolean;
    sessionStartNumber: number;
    showSessionsLimit: number;
  };
  chatTutorialConfig: {
    enabled: boolean;
    closeBtnAsContinue: boolean;
    palmReadingStepEnabled: boolean;
    showCloseBtnOnStep: number;
    startMonetizationTriggerDelay: number;
    symbolsPerSecond: number;
    maxTimeOfDelay: number;
  };
  chatAssistantConfig: {
    enabled: boolean;
    hiddenAdvisorId: number;
    suggestStepInputEnabled: boolean;
  };
  chatInputAlwaysEnabled: boolean;
}

export type RemoteConfigStateExport = Partial<RemoteConfigState>;

export type ParamNames = keyof RemoteConfigState;

export type ParamUniversalDefault<T extends ParamNames> = RemoteConfigState[T];

export type ParamPlatformDefault<T extends ParamNames> = {
  android: RemoteConfigState[T];
  ios: RemoteConfigState[T];
  web: RemoteConfigState[T];
};

export type ParamDefault<T extends ParamNames> = ParamUniversalDefault<T> | ParamPlatformDefault<T>;

export type Param<T extends ParamNames> = {
  default: ParamDefault<T>;
  name: T;
  type: ParamTypes;
};

export type ParamItem = {
  [T in ParamNames]: Param<T>;
}[ParamNames];

export const initialState: RemoteConfigState = defaultParams.reduce((result, param: Param<ParamNames>) => {
  result[param.name] = getDefaultValue(param.default);
  return result;
}, {} as any) as RemoteConfigState;

export type MutatorStateExport = Partial<RemoteConfigState>;
