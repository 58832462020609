import React, { memo } from 'react';
import type { FC } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import type { Astrologist } from 'api/astrology-chat/types';
import AdvisorAvatar from 'components/advisors/avatar';

interface Props {
  advisor: Astrologist;
  unreadCount: number;
  onPress: (id: number | string) => void;
}

const ChatPreviewItem: FC<Props> = ({ advisor, onPress, unreadCount }) => {
  const handlePress = () => {
    onPress(advisor.astrologer_id);
  };

  return (
    <TouchableOpacity style={styles.root} onPress={handlePress}>
      <AdvisorAvatar advisor={advisor} size={'medium'} nameEnabled unreadCount={unreadCount} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  root: {
    marginRight: 16,
  },
});

export default memo(ChatPreviewItem);
