import type { Product as RNProduct } from '@wowmaking/react-native-billing';
import { Platform } from 'react-native';
import format from 'string-format';

import type { WebProduct, WebPurchase, WebSubscription } from 'api/purchases/interfaces';
import { t2 } from 'localization';

import { WEB_SUBSCRIPTION_STATE, WEB_SUBSCRIPTION_STATUS, CURRENCY_SYMBOLS, PERIOD_TYPES } from '../constants';
import type { Currency } from '../constants';
import { PRODUCT_TYPES } from '../constants/product';
import type { Product, SubProduct } from '../interfaces/product';

export const getPriceText = (text: string, product: Product | null) => {
  const p = { ...product };
  if (p.type === PRODUCT_TYPES.SUBSCRIPTION && p?.period) {
    p.period = t2(`SUBSCRIPTION.PERIODS.${p.period}`);
  }

  return format(text, p);
};

export const getPreparedPeriod = (period: PERIOD_TYPES): PERIOD_TYPES => {
  switch (period) {
    case PERIOD_TYPES.P7D:
      return PERIOD_TYPES.P1W;
    case PERIOD_TYPES.P14D:
      return PERIOD_TYPES.P2W;
    case PERIOD_TYPES.P30D:
      return PERIOD_TYPES.P1M;
    case PERIOD_TYPES.P60D:
      return PERIOD_TYPES.P2M;
    case PERIOD_TYPES.P90D:
      return PERIOD_TYPES.P3M;
    case PERIOD_TYPES.P180D:
      return PERIOD_TYPES.P6M;
    case PERIOD_TYPES.P360D:
      return PERIOD_TYPES.P1Y;
    default:
      return period as PERIOD_TYPES;
  }
};

export const getCurrencySymbol = (currency: Currency): string => {
  return CURRENCY_SYMBOLS[currency as keyof typeof CURRENCY_SYMBOLS] ?? currency;
};

export const mapWebProduct = (product: WebProduct): WebProduct => {
  const { period } = product;
  return {
    ...product,
    period: getPreparedPeriod(period),
  } as WebProduct;
};

export const mapWebSubscription = (purchase: WebPurchase, products: WebProduct[]): WebSubscription => {
  const { trial_start_at, trial_end_at } = purchase;
  const purchaseState = purchase.state?.toLowerCase() as WEB_SUBSCRIPTION_STATE;
  let status = purchase.state?.toLowerCase() as WEB_SUBSCRIPTION_STATUS;
  const product = products.find(p => p.id === purchase.product) || {};

  let isActiveTrialPeriod = false;

  if (trial_start_at && trial_end_at) {
    const endDate = new Date(trial_end_at);
    const now = new Date();

    isActiveTrialPeriod = endDate >= now;
  }

  if (
    [WEB_SUBSCRIPTION_STATE.CANCELED, WEB_SUBSCRIPTION_STATE.CANCELLED].includes(purchaseState) ||
    (purchaseState === WEB_SUBSCRIPTION_STATE.ACTIVE && purchase.canceled_at)
  ) {
    status = WEB_SUBSCRIPTION_STATUS.CANCELED;
  }

  if (purchase.expire_at) {
    const now = new Date();
    const expireDate = new Date(purchase.expire_at.replace(' ', 'T'));

    if (now > expireDate) {
      status = WEB_SUBSCRIPTION_STATUS.EXPIRED;
    }
  }

  if (purchaseState === WEB_SUBSCRIPTION_STATE.REDEMPTION) {
    status = WEB_SUBSCRIPTION_STATUS.REDEMPTION;
  }

  return {
    ...product,
    ...purchase,
    status,
    is_active_trial_period: isActiveTrialPeriod,
  } as WebSubscription;
};

export const prepareProductToPaymentProduct = (product: WebProduct | RNProduct): Product | null => {
  if (!product) {
    return null;
  }

  if (Platform.OS === 'web') {
    if ('amount' in product && 'period' in product) {
      return {
        id: product.id,
        price: +product.amount,
        period: product.period,
        priceText: `${getCurrencySymbol(product.currency)}${product.amount}`,
        currency: product.currency,
        type: PRODUCT_TYPES.SUBSCRIPTION,
      };
    }
  }

  if ('priceValue' in product && 'productId' in product) {
    return {
      id: product.productId,
      price: product.priceValue,
      period: product.subscriptionPeriod,
      priceText: product.priceText,
      currency: product.currency as Currency,
      type: PRODUCT_TYPES.SUBSCRIPTION,
    };
  }

  return null;
};

export const prepareSubWebProductToPaymentProduct = (product: WebProduct | RNProduct, params?: { strikethroughText?: string }): SubProduct | null => {
  if (!product) {
    return null;
  }

  if (Platform.OS === 'web') {
    if ('amount' in product && 'period' in product) {
      return {
        id: product.id,
        price: +product.amount,
        period: product.period,
        priceText: `${getCurrencySymbol(product.currency)}${product.amount}`,
        trialPeriod: product.trial_period_days,
        trialPrice: +product.trial_price_amount,
        trialPriceText: product.trial_price_amount ? `${getCurrencySymbol(product.currency)}${product.trial_price_amount}` : '',
        currency: product.currency,
        textLineThrough: params?.strikethroughText ? `${getCurrencySymbol(product.currency)}${params.strikethroughText}` : '',
        type: PRODUCT_TYPES.SUBSCRIPTION,
      };
    }
  }

  if ('priceValue' in product && 'productId' in product) {
    return {
      id: product.productId,
      price: product.priceValue,
      period: product.subscriptionPeriod,
      priceText: product.priceText,
      currency: product.currency as Currency,
      trialPeriod: product.haveTrialPeriod ? `${product.trial}` : '',
      textLineThrough: params?.strikethroughText || '',
      type: PRODUCT_TYPES.SUBSCRIPTION,
    };
  }

  return null;
};

// For WebReactivation
export const prepareWebSubscriptionToProduct = ({ product, amount, currency, period }: WebSubscription): Product => {
  return {
    id: product,
    price: amount,
    currency: currency,
    title: '',
    priceText: `${getCurrencySymbol(currency)}${amount}`,
    period,
    textLineThrough: '',
    type: PRODUCT_TYPES.SUBSCRIPTION,
  };
};
