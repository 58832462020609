import dayjs from 'dayjs';
import { Platform } from 'react-native';

import Analytics from 'analytics';
import { generateProactiveNotification } from 'api/astrologers';
import { getPromocode } from 'api/astrology-chat';
import { EXTERNAL_TRIGGER_NOTIFICATIONS } from 'components/trigger-notification/components';
import { NOTIFICATIONS } from 'constants/notification-center';
import { ADVISORS_CHAT, ADVISORS_ROUTES, BIRTH_CHART_ROUTES, PATH_NAMES, PATH_ROUTES_MAP } from 'constants/routes';
import type { AppThunk } from 'store';
import { generateAppLink } from 'store/app/actions';
import { addNotificationToQueue, removeNotificationFromQueue } from 'store/notification-center/actions';
import { setUserParams } from 'store/profile/actions';
import { getDiffDaysFromNow, getISODate } from 'utils/date';
import { isStandalone } from 'utils/pwa';

import { handleExternalTrigger } from '../core/actions';
import { selectActiveChats } from '../selectors';

import { selectProactiveNotificationRequestData } from './selectors';
import type { ProactiveNotification, TriggerNotification } from './types';

import {
  incAlternateGenerationDaysCounter,
  incGenerationDaysCounter,
  resetGenerationDaysCounter,
  resetNotificationsData,
  setAlternateNotification,
  setIsPromocodeAddedInPwa,
  setNotification,
  setTriggerNotifications,
} from '.';

export const initAdvisorNotifications = (): AppThunk<void> => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      remoteConfig: {
        advisorNotificationConfig: { enabled, categories, numberOfDaysRandomMessages, numberOfDaysPause },
        stealthModeEnabled,
      },
      astrologers: {
        notifications: { generationDaysCounter },
      },
      profile: {
        profileData: { userParams },
      },
    } = state;

    if (!enabled) {
      return;
    }

    const lastUpdateDate = userParams?.last_proactive_notification || '';

    if (!stealthModeEnabled && (!lastUpdateDate || dayjs().isAfter(lastUpdateDate, 'days'))) {
      if (categories.length) {
        const daysFromLastUpdate = getDiffDaysFromNow(lastUpdateDate);

        const generateRandomNotification = async () => {
          const requestData = selectProactiveNotificationRequestData(state, 0);
          const res = await generateProactiveNotification(requestData);

          if (res?.status) {
            dispatch(incGenerationDaysCounter());
            dispatch(
              setUserParams({
                last_proactive_notification: getISODate(),
              }),
            );
          }
        };

        if (generationDaysCounter < numberOfDaysRandomMessages) {
          return generateRandomNotification();
        }

        if (daysFromLastUpdate > numberOfDaysPause) {
          dispatch(resetGenerationDaysCounter());
          return generateRandomNotification();
        }
      }
    }
  };
};

export const initAlternateAdvisorNotifications = (): AppThunk<void> => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      remoteConfig: {
        alternateAdvisorNotificationConfig: { enabled, type, numberOfDaysAlternateMessages },
        stealthModeEnabled,
      },
      astrologers: {
        notifications: { alternateGenerationDaysCounter },
      },
      profile: {
        profileData: { userParams },
      },
    } = state;

    if (!enabled) {
      return;
    }

    const activeChats = selectActiveChats(state);
    const lastUpdateAlternateDate = userParams?.last_alternate_proactive_notification || '';

    if (!stealthModeEnabled && activeChats.length && (!lastUpdateAlternateDate || dayjs().isAfter(lastUpdateAlternateDate, 'days'))) {
      if (alternateGenerationDaysCounter < numberOfDaysAlternateMessages) {
        const requestData = selectProactiveNotificationRequestData(state, type);
        const res = await generateProactiveNotification(requestData);

        if (res?.status) {
          dispatch(incAlternateGenerationDaysCounter());
          dispatch(
            setUserParams({
              last_alternate_proactive_notification: getISODate(),
            }),
          );
        }
      }
    }
  };
};

export const showProactiveNotificationMessage = (notification: ProactiveNotification): AppThunk<void> => {
  return dispatch => {
    switch (notification.type) {
      case 'pt0': {
        dispatch(setNotification(notification));
        dispatch(addNotificationToQueue(NOTIFICATIONS.PROACTIVE));
        break;
      }

      case 'pt1':
      case 'pt2':
      case 'pt3': {
        dispatch(setAlternateNotification(notification));
        dispatch(addNotificationToQueue(NOTIFICATIONS.PROACTIVE_ALTERNATE));
        break;
      }
    }
  };
};

export const resetAdvisorNotifications = (): AppThunk<void> => {
  return async dispatch => {
    dispatch(removeNotificationFromQueue(NOTIFICATIONS.PROACTIVE));
    dispatch(removeNotificationFromQueue(NOTIFICATIONS.PROACTIVE_ALTERNATE));
    dispatch(setNotification(null));
    dispatch(setAlternateNotification(null));
  };
};

export const fullResetProactiveNotifications = (): AppThunk<void> => {
  return async dispatch => {
    dispatch(resetNotificationsData());
    dispatch(resetAdvisorNotifications());
    dispatch(
      setUserParams({
        last_proactive_notification: '',
        last_alternate_proactive_notification: '',
      }),
    );
  };
};

export const addTriggerNotification = (notification: TriggerNotification): AppThunk<void> => {
  return (dispatch, getState) => {
    const {
      astrologers: {
        notifications: { triggerNotifications },
      },
    } = getState();

    if (!triggerNotifications?.length) {
      return dispatch(setTriggerNotifications([notification]));
    }

    const updatedTriggerNotifications = triggerNotifications.reduce((acc, item) => {
      if (item.type === notification.type) {
        return acc;
      }

      return [...acc, notification];
    }, triggerNotifications);

    const highPriorityNotifications = [EXTERNAL_TRIGGER_NOTIFICATIONS.PAYMENT_FAILED];

    const sortedNotifications = updatedTriggerNotifications.sort((a, b) => {
      const aPriority = highPriorityNotifications.includes(a.type) ? -1 : 1;
      const bPriority = highPriorityNotifications.includes(b.type) ? -1 : 1;
      return aPriority - bPriority;
    });

    return dispatch(setTriggerNotifications(sortedNotifications));
  };
};

export const removeTriggerNotifications = (notifications: EXTERNAL_TRIGGER_NOTIFICATIONS[]): AppThunk<void> => {
  return (dispatch, getState) => {
    const {
      astrologers: {
        notifications: { triggerNotifications },
      },
    } = getState();

    const formattedNotifications = triggerNotifications.filter(({ type }) => !notifications.includes(type));
    dispatch(setTriggerNotifications(formattedNotifications));
  };
};

export const showInstallMobileNotification = (): AppThunk<void> => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      remoteConfig: { installMobileNotification },
      profile: {
        profileData: { userParams },
        isUserLoyal,
      },
    } = state;

    const isEnabled = installMobileNotification?.enabled;
    const promocode = installMobileNotification?.promocode || 'rnw_to_app';

    if (!isEnabled || Platform.OS !== 'web' || isStandalone() || !isUserLoyal) {
      dispatch(removeTriggerNotifications([EXTERNAL_TRIGGER_NOTIFICATIONS.GO_TO_MOBILE_APP, EXTERNAL_TRIGGER_NOTIFICATIONS.INSTALL_MOBILE_APP]));
      return false;
    }

    if (userParams?.app_installed) {
      Analytics.trackEvent('TopNotification', 'Show', { type: EXTERNAL_TRIGGER_NOTIFICATIONS.GO_TO_MOBILE_APP });
      return dispatch(addTriggerNotification({ type: EXTERNAL_TRIGGER_NOTIFICATIONS.GO_TO_MOBILE_APP }));
    }

    const onShow = (seconds = 0) => {
      Analytics.trackEvent('TopNotification', 'Show', { type: EXTERNAL_TRIGGER_NOTIFICATIONS.INSTALL_MOBILE_APP, seconds, promocode });
      dispatch(addTriggerNotification({ type: EXTERNAL_TRIGGER_NOTIFICATIONS.INSTALL_MOBILE_APP, seconds }));
    };

    try {
      const promocodeInfo = await getPromocode(promocode);

      onShow(promocodeInfo?.seconds);
    } catch (error) {
      console.log('ERROR getPromocode', error);
      onShow();
    }
  };
};

export const handleInstallMobileNotification = (): AppThunk<void> => {
  return (dispatch, getState) => {
    const {
      remoteConfig: { installMobileNotification, appLink },
    } = getState();
    const promocode = installMobileNotification?.promocode;

    dispatch(transferInApp(appLink, promocode, true));
    dispatch(removeTriggerNotifications([EXTERNAL_TRIGGER_NOTIFICATIONS.INSTALL_MOBILE_APP]));
  };
};

export const transferInApp = (appLink: string, promocode?: string, isInstallApp = false): AppThunk<void> => {
  return async (dispatch, getState) => {
    const {
      navigation: { currentRoute },
    } = getState();

    let pathname = Object.entries(PATH_ROUTES_MAP).find(([_key, value]) => value === currentRoute)?.[0];

    if (BIRTH_CHART_ROUTES.includes(currentRoute)) {
      pathname = PATH_NAMES['birth-chart'];
    }

    if (ADVISORS_ROUTES.includes(currentRoute) && ADVISORS_CHAT !== currentRoute) {
      pathname = PATH_NAMES.advisors;
    }

    const link = await dispatch(generateAppLink(appLink, { promocode, install_app: isInstallApp, pathname }));

    window.open(link, '_blank');
  };
};

export const addPromocodeInPWA = (): AppThunk<void> => {
  return async (dispatch, getState) => {
    const {
      remoteConfig: { pwaInstructions },
      astrologers: {
        notifications: { isPromocodeAddedInPwa },
      },
    } = getState();

    const promocode = pwaInstructions?.promocode;

    if (!promocode?.length || !isStandalone() || isPromocodeAddedInPwa) {
      return false;
    }

    await dispatch(handleExternalTrigger(promocode));
    dispatch(setIsPromocodeAddedInPwa());
  };
};

export const showInstallPWANotification = (): AppThunk<void> => {
  return async (dispatch, getState) => {
    const state = getState();
    const {
      remoteConfig: {
        installPWANotification: { enabled, sessionStartNumber, showSessionsLimit },
        pwaInstructions: { promocode },
      },
    } = state;

    const currentSession = (Analytics.getSessionNumber() ?? 0) + 1;

    const pwaNotificationEndSession = sessionStartNumber + showSessionsLimit;
    const isShownInSession = currentSession >= sessionStartNumber && currentSession < pwaNotificationEndSession;

    if (!enabled || !isShownInSession || Platform.OS !== 'web' || isStandalone()) {
      dispatch(removeTriggerNotifications([EXTERNAL_TRIGGER_NOTIFICATIONS.INSTALL_PWA_APP]));
      return;
    }

    const onShow = (seconds = 0) => {
      Analytics.trackEvent('TopNotification', 'Show', { type: EXTERNAL_TRIGGER_NOTIFICATIONS.INSTALL_PWA_APP, seconds, promocode });
      dispatch(addTriggerNotification({ type: EXTERNAL_TRIGGER_NOTIFICATIONS.INSTALL_PWA_APP, seconds }));
    };

    if (!promocode) {
      onShow();
      return;
    }

    try {
      const promocodeInfo = await getPromocode(promocode);

      onShow(promocodeInfo?.seconds);
    } catch (error) {
      console.log('ERROR getPromocode', error);
      onShow();
    }
  };
};
