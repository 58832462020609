import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

import { initialState } from './types';
import type { ProactiveNotification, TriggerNotification } from './types';

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotification(state, { payload }: PayloadAction<ProactiveNotification | null>) {
      state.notification = payload;
    },
    setAlternateNotification(state, { payload }: PayloadAction<ProactiveNotification | null>) {
      state.alternateNotification = payload;
    },
    setIsPressed(state, { payload }: PayloadAction<boolean>) {
      state.isPressed = payload;
    },
    setTriggerNotifications(state, { payload }: PayloadAction<TriggerNotification[]>) {
      state.triggerNotifications = payload;
    },
    setIsPromocodeAddedInPwa(state) {
      state.isPromocodeAddedInPwa = true;
    },
    incGenerationDaysCounter(state) {
      state.generationDaysCounter++;
    },
    incAlternateGenerationDaysCounter(state) {
      state.alternateGenerationDaysCounter++;
    },
    resetGenerationDaysCounter(state) {
      state.generationDaysCounter = 0;
    },
    resetNotificationsData() {
      return initialState;
    },
  },
});

export const {
  setNotification,
  setAlternateNotification,
  setIsPressed,
  setTriggerNotifications,
  setIsPromocodeAddedInPwa,
  incGenerationDaysCounter,
  incAlternateGenerationDaysCounter,
  resetGenerationDaysCounter,
  resetNotificationsData,
} = slice.actions;

export default persistReducer(
  {
    timeout: 0,
    key: slice.name,
    storage: AsyncStorage,
    blacklist: ['isPressed', 'triggerNotifications'],
  },
  slice.reducer,
);
