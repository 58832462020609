import api from 'api';

import { MODIFY_CHAT_PARAMS } from './constants';
import type { ChatModifyParams, ChatModifyResponse } from './types';

const modifyChatParams = async (params: ChatModifyParams): Promise<ChatModifyResponse | null> => {
  const { advisorId, hiddenMessage = '', message = '', metadata = {} } = params;

  try {
    const response = await api.fetch(MODIFY_CHAT_PARAMS, {
      method: 'POST',
      data: {
        astrologer_id: advisorId,
        hiddenMessage,
        message,
        metadata,
        fromAstrologer: 1,
      },
    });

    if (response && 'chat_id' in response) {
      return response;
    }
  } catch (error) {
    console.log('ERROR [Astrologist chat] modifyChatParams', error);
  }

  return null;
};

export default modifyChatParams;
