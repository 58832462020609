import { PROFILE_TYPES } from 'store/profile/types';

import { TYPES } from './types';
import type { State, ActionType } from './types';

const initialState: State = {
  chats: {},
  unreadMessages: 0,
  currentChatId: 0,
  currentChats: [],
  hiddenMessage: '',
  question: null,
  messagePlace: null,
  isSuggestsVisible: true,
  palmPhoto: '',
  isPalmReadingOffer: false,
  currentAstrologerId: '',
  wasSessionConversation: false,
  playingVoiceId: null,
  typingIds: [],
  recordingIds: [],
  isReviewModalVisible: false,
  forcedOnlineAdvisorId: '',
  showSoftCurrencyRateInChat: true,
  forceTyping: false,
};

export default function (state = initialState, action: ActionType): State {
  switch (action.type) {
    case TYPES.SET_HIDDEN_MESSAGE:
      return {
        ...state,
        hiddenMessage: action.payload,
      };
    case TYPES.SET_CURRENT_CHATS:
      return {
        ...state,
        currentChats: action.payload,
      };
    case TYPES.SET_CURRENT_CHAT_ID:
      return {
        ...state,
        currentChatId: action.payload,
      };
    case TYPES.RESET_ALL_CHATS:
      return {
        ...state,
        chats: {},
      };
    case TYPES.SET_UNREAD_CHAT_COUNT:
      return {
        ...state,
        unreadMessages: action.payload,
      };
    case TYPES.SET_CHAT_MESSAGES:
      return {
        ...state,
        chats: {
          ...state.chats,
          [action.payload.advisorId]: {
            ...state.chats[action.payload.advisorId],
            messages: action.payload.messages,
          },
        },
      };
    case TYPES.SET_TYPING_IDS:
      return {
        ...state,
        typingIds: action.payload,
      };

    case TYPES.SET_RECORDING_IDS:
      return {
        ...state,
        recordingIds: action.payload,
      };

    case TYPES.SET_QUESTION:
      return {
        ...state,
        question: action.payload,
      };
    case TYPES.SET_MESSAGE_PLACE:
      return {
        ...state,
        messagePlace: action.payload,
      };
    case TYPES.SET_PALM_PHOTO:
      return {
        ...state,
        palmPhoto: action.payload,
      };
    case TYPES.SET_IS_PALM_READING_OFFER:
      return {
        ...state,
        isPalmReadingOffer: action.payload,
      };
    case TYPES.SET_SUGGESTS_VISIBLE:
      return {
        ...state,
        isSuggestsVisible: action.payload,
      };
    case TYPES.SET_CURRENT_ASTROLOGER_ID:
      return {
        ...state,
        currentAstrologerId: action.payload,
      };
    case TYPES.SET_CHAT_REVIEW_COMPLETED:
      return {
        ...state,
        chats: {
          ...state.chats,
          [action.payload.advisorId]: {
            ...state.chats[action.payload.advisorId],
            isReviewCompleted: action.payload.isReviewCompleted,
            rate: action.payload.rate,
          },
        },
      };
    case TYPES.SET_WAS_SESSION_CONVERSATION:
      return {
        ...state,
        wasSessionConversation: action.payload,
      };
    case TYPES.SET_REVIEW_MODAL_VISIBLE:
      return {
        ...state,
        isReviewModalVisible: action.payload,
      };
    case TYPES.SET_PLAYING_VOICE_ID:
      return {
        ...state,
        playingVoiceId: action.payload,
      };
    case TYPES.SET_FORCED_ONLINE_ADVISOR_ID:
      return {
        ...state,
        forcedOnlineAdvisorId: action.payload,
      };
    case TYPES.HIDE_SOFT_CURRENCY_RATE_IN_CHAT:
      return {
        ...state,
        showSoftCurrencyRateInChat: false,
      };
    case TYPES.SET_FORCE_TYPING:
      return {
        ...state,
        forceTyping: action.payload,
      };
    case PROFILE_TYPES.RESET_USER_DATA:
      return initialState;
    default:
      return state;
  }
}
