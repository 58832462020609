import api from 'api';

import { GET_REVIEWS } from './constants';
import type { Astrologist, AstrologistReviewItem } from './types';

const getAstrologistReviews = async (id: Astrologist['astrologer_id']): Promise<AstrologistReviewItem[]> => {
  try {
    const response = await api.fetch(`${GET_REVIEWS}?astrologer_id=${id}`, {
      method: 'GET',
    });

    return response;
  } catch (error) {
    console.log('ERROR [Astrologist chat] addReviews', error);
    return [];
  }
};

export default getAstrologistReviews;
