import { uniqBy } from 'lodash';
import { createSelector } from 'reselect';

import type { Astrologist, ChatMetadata } from 'api/astrology-chat/types';
import type { ActivityType } from 'screens/advisors/screens/chats/components/typing-component';
import type { RootState } from 'store';

import { getActiveAdvisors } from '../selectors';

const getAstrologersChats = (state: RootState) => state.astrologers.chat.chats;
const getTypingIds = (state: RootState) => state.astrologers.chat.typingIds;
const getRecordingIds = (state: RootState) => state.astrologers.chat.recordingIds;
const getAdvisorsPid = (state: RootState) => state.remoteConfig.advisorsPid;
const getAdvisorsCatalogStealthCategoryPid = (state: RootState) => state.remoteConfig.advisorsCatalogStealthCategoryPid;
const getStealthModeEnabled = (state: RootState) => state.remoteConfig.stealthModeEnabled;
const getAstrologistBackendConfig = (state: RootState) => state.remoteConfig.astrologistBackendConfig;
const getEnableVoiceMessages = (state: RootState) => state.remoteConfig.enableVoiceMessages;
const getCurrentChats = (state: RootState) => state.astrologers.chat.currentChats;

export const getChatsDataWithReviews = createSelector([getAstrologersChats], chats => {
  const chatsValues = Object.values(chats);
  return chatsValues.filter(chat => chat?.isReviewCompleted && chat?.rate);
});

export const getAdvisorsPositiveReview = createSelector([getChatsDataWithReviews], reviews => {
  const positiveReview = reviews.find(review => !!review?.rate && review?.rate >= 4);
  return positiveReview?.rate;
});

export const selectMessagesByAdvisorId = createSelector(
  [getAstrologersChats, (_state, advisorId: Astrologist['astrologer_id']) => advisorId],
  (chats, advisorId) => {
    return uniqBy(chats?.[advisorId]?.messages, 'created_at') || [];
  },
);

export const selectChatActivity = createSelector(
  [getTypingIds, getRecordingIds, (_state, chatId: number | null) => chatId],
  (chatsTypingIds, recordingAdvisors, chatId): ActivityType | null => {
    if (!chatId) {
      return null;
    }

    let activityType: ActivityType | null = null;

    if (recordingAdvisors.includes(chatId)) {
      activityType = 'recording';
    }

    if (chatsTypingIds.includes(chatId)) {
      activityType = 'typing';
    }

    return activityType;
  },
);

export const selectValidAdvisorsPid = createSelector(
  [getActiveAdvisors, getAdvisorsPid, getAdvisorsCatalogStealthCategoryPid, getStealthModeEnabled, (_state, id: Astrologist['astrologer_id']) => id],
  (advisors, advisorsPid, stealthCategories, stealthModeEnabled, id): number | string => {
    if (stealthModeEnabled) {
      const advisorData = advisors.find(a => a.astrologer_id === id);

      if (advisorData && advisorData?.stealth_category) {
        return stealthCategories[advisorData.stealth_category] || advisorsPid;
      }
    }

    return advisorsPid;
  },
);

export const selectChatMetadata = createSelector(
  [
    getAstrologistBackendConfig,
    getEnableVoiceMessages,
    getStealthModeEnabled,
    (state, id: Astrologist['astrologer_id']) => selectValidAdvisorsPid(state, id),
  ],
  (astrologistBackendConfig, enableVoiceMessages, stealthModeEnabled, pid): ChatMetadata => {
    const { symbolsPerSecond, maxTimeOfDelay, palmReadingEnabled, sendPayments } = astrologistBackendConfig || {};

    return {
      pid,
      sps: symbolsPerSecond ?? 5,
      mtod: maxTimeOfDelay ?? 30,
      tm: false,
      enableRag: false,
      sendPayments: !!sendPayments,
      palmReadingEnabled: !!palmReadingEnabled,
      isVoiceEnabled: !!enableVoiceMessages,
      stModeEnabled: !!stealthModeEnabled,
      notificationCategory: 'none',
      place: 'other',
    };
  },
);

export const selectChatIdByAdvisorId = createSelector(
  [getCurrentChats, (_state, id: Astrologist['astrologer_id']) => id],
  (chats, id): number | null => {
    const chatData = chats.find(chat => `${chat.astrologer_id}` === `${id}`);
    return chatData?.chatId ?? null;
  },
);
