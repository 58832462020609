import { I18N_NAMESPACE } from '@wowmaking/ui/src/constants/general';
import wowUiEn from '@wowmaking/ui/src/i18n/locales/en.json';

import { DEFAULT_LNG, DEFAULT_NS, LANGUAGES } from './const';
import coreEn from './translations/en/core.json';
import horoscopesEn from './translations/en/compatibility-love-horoscopes.json';
import chatTutorEn from './translations/en/chat-tutor.json';

/* Static Data */

export const resources = {
  ar: {
    [DEFAULT_NS]: {
      ...LANGUAGES.ar,
    },
  },
  de: {
    [DEFAULT_NS]: {
      ...LANGUAGES.de,
    },
  },
  en: {
    [DEFAULT_NS]: {
      ...LANGUAGES.en,
      ...coreEn,
      ...horoscopesEn,
      ...chatTutorEn,
      [I18N_NAMESPACE]: wowUiEn,
    },
  },
  es: {
    [DEFAULT_NS]: {
      ...LANGUAGES.es,
    },
  },
  fr: {
    [DEFAULT_NS]: {
      ...LANGUAGES.fr,
    },
  },
  pt: {
    [DEFAULT_NS]: {
      ...LANGUAGES.pt,
    },
  },
  ja: {
    [DEFAULT_NS]: {
      ...LANGUAGES.ja,
    },
  },
};

const DEFAULT_TRANSLATION = resources[DEFAULT_LNG][DEFAULT_NS];

export type DefaultTranslation = typeof DEFAULT_TRANSLATION;

export type SupportedLngs = keyof typeof resources;

/* Dynamic Data */

const loadAr = async () => {
  return {
    ...(await import(/* webpackChunkName: 'ar' */ '@wowmaking/ui/src/i18n/locales/ar.json')).default,
    ...(await import(/* webpackChunkName: 'ar' */ './translations/ar/core.json')).default,
    ...(await import(/* webpackChunkName: 'ar' */ './translations/ar/compatibility-love-horoscopes.json')).default,
    ...(await import(/* webpackChunkName: 'ar' */ './translations/ar/chat-tutor.json')).default,
  };
};

const loadDe = async () => {
  return {
    ...(await import(/* webpackChunkName: 'de' */ '@wowmaking/ui/src/i18n/locales/de.json')).default,
    ...(await import(/* webpackChunkName: 'de' */ './translations/de/core.json')).default,
    ...(await import(/* webpackChunkName: 'de' */ './translations/de/compatibility-love-horoscopes.json')).default,
    ...(await import(/* webpackChunkName: 'de' */ './translations/de/chat-tutor.json')).default,
  };
};

const loadEs = async () => {
  return {
    ...(await import(/* webpackChunkName: 'es' */ '@wowmaking/ui/src/i18n/locales/es.json')).default,
    ...(await import(/* webpackChunkName: 'es' */ './translations/es-ES/core.json')).default,
    ...(await import(/* webpackChunkName: 'es' */ './translations/es-ES/compatibility-love-horoscopes.json')).default,
    ...(await import(/* webpackChunkName: 'es' */ './translations/es-ES/chat-tutor.json')).default,
  };
};

const loadFr = async () => {
  return {
    ...(await import(/* webpackChunkName: 'fr' */ '@wowmaking/ui/src/i18n/locales/fr.json')).default,
    ...(await import(/* webpackChunkName: 'fr' */ './translations/fr/core.json')).default,
    ...(await import(/* webpackChunkName: 'fr' */ './translations/fr/compatibility-love-horoscopes.json')).default,
    ...(await import(/* webpackChunkName: 'fr' */ './translations/fr/chat-tutor.json')).default,
  };
};

const loadPt = async () => {
  return {
    ...(await import(/* webpackChunkName: 'pt' */ '@wowmaking/ui/src/i18n/locales/pt.json')).default,
    ...(await import(/* webpackChunkName: 'pt' */ './translations/pt-BR/core.json')).default,
    ...(await import(/* webpackChunkName: 'pt' */ './translations/pt-BR/compatibility-love-horoscopes.json')).default,
    ...(await import(/* webpackChunkName: 'pt' */ './translations/pt-BR/chat-tutor.json')).default,
  };
};

const loadJa = async () => {
  return {
    ...(await import(/* webpackChunkName: 'ja' */ '@wowmaking/ui/src/i18n/locales/ja.json')).default,
    ...(await import(/* webpackChunkName: 'ja' */ './translations/ja/core.json')).default,
    ...(await import(/* webpackChunkName: 'ja' */ './translations/ja/compatibility-love-horoscopes.json')).default,
    ...(await import(/* webpackChunkName: 'pt' */ './translations/ja/chat-tutor.json')).default,
  };
};

export const langLoader = {
  ar: loadAr,
  de: loadDe,
  es: loadEs,
  fr: loadFr,
  pt: loadPt,
  ja: loadJa,
};
