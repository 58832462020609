import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

import type { Astrologist } from 'api/astrology-chat/types';

import { initialState } from './types';

const slice = createSlice({
  name: 'chatTutorial',
  initialState,
  reducers: {
    setIsStarted(state) {
      state.isStarted = true;
    },
    setIsCompleted(state) {
      state.isCompleted = true;
    },
    setIsBusy(state, { payload }: PayloadAction<boolean>) {
      state.isBusy = payload;
    },
    setCurrentStep(state, { payload }: PayloadAction<number>) {
      state.currentStep = payload;
      state.currentSubstep = 0;
    },
    setCurrentSubstep(state, { payload }: PayloadAction<number>) {
      state.currentSubstep = payload;
    },
    setAdvisorId(state, { payload }: PayloadAction<Astrologist['astrologer_id'] | null>) {
      state.advisorId = payload;
    },
    setAdvisorFromAssistant(state, { payload }: PayloadAction<Astrologist | null>) {
      state.advisorFromAssistant = payload;
    },
    setMessageText(state, { payload }: PayloadAction<string>) {
      state.messageText = payload;
    },
    setSuggestCategory(state, { payload }: PayloadAction<string>) {
      state.suggestCategory = payload;
    },
    setTarotCard(state, { payload }: PayloadAction<string>) {
      state.tarotCard = payload;
    },
    setShowPalmScan(state, { payload }: PayloadAction<boolean>) {
      state.showPalmScan = payload;
    },
    setPalmPhotoUrl(state, { payload }: PayloadAction<string>) {
      state.palmPhotoUrl = payload;
    },
    toggleDebug(state) {
      state.debug = !state.debug;
    },
    resetChatTutorial() {
      return initialState;
    },
  },
});

export const {
  setIsStarted,
  setIsCompleted,
  setIsBusy,
  setCurrentStep,
  setCurrentSubstep,
  setAdvisorId,
  setAdvisorFromAssistant,
  setMessageText,
  setSuggestCategory,
  setTarotCard,
  setShowPalmScan,
  setPalmPhotoUrl,
  toggleDebug,
  resetChatTutorial,
} = slice.actions;

export default persistReducer(
  {
    timeout: 0,
    key: slice.name,
    storage: AsyncStorage,
    blacklist: ['isBusy'],
  },
  slice.reducer,
);
