import api from 'api';

import type { EventsRetrieveDataResponse } from './types';

const EVENTS_RETRIEVE = '/events/retrieve';

const getEventsRetrieveData = async (): Promise<EventsRetrieveDataResponse> => {
  try {
    const response = await api.fetch(EVENTS_RETRIEVE, { method: 'GET' });
    return response;
  } catch (error) {
    console.log('ERROR [Events retrieve] getEventsRetrieveData', error);
    return {
      events: [],
      statuses: {
        advisorsTyping: [],
        chatsTyping: [],
        chatsRecording: [],
      },
    };
  }
};

export default getEventsRetrieveData;
