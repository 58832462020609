import queryString from 'query-string';
import { createAction } from 'redux-actions';

import Analytics from 'analytics';
import { PRO, SKU } from 'constants/billing';
import * as MODALS from 'constants/modals';
import type { AppDispatch, AppGetState, AppThunk } from 'store';
import { setAstrologistFreeTime } from 'store/astrologers/time/actions';
import { checkIsReactivate } from 'store/billing/selectors';
import { showModal } from 'store/modals/actions';

import { trackEvent, cancelSubscription, fetchWebPurchases, verifyWebSubscriptions } from './general-actions';
import { BILLING_TYPES } from './types';

export const setPending = createAction(BILLING_TYPES.SET_PENDING);
export const setWebActiveTransactions = createAction(BILLING_TYPES.SET_WEB_TRANSACTIONS);
export const setWebAllSubscriptions = createAction(BILLING_TYPES.SET_WEB_ALL_SUBSCRIPTIONS);
export const setTransactions = createAction(BILLING_TYPES.SET_TRANSACTIONS);
export const setPurchased = createAction(BILLING_TYPES.SET_PURCHASED);
export const setPurchaseDate = createAction(BILLING_TYPES.SET_PURCHASE_DATE);
export const setProducts = createAction(BILLING_TYPES.SET_PRODUCTS);
export const setProductsLoadSuccess = createAction(BILLING_TYPES.SET_PRODUCTS_LOAD_SUCCESS);
export const setTempPurchaseActive = createAction(BILLING_TYPES.SET_TEMP_PURCHASE_ACTIVE);
export const setWebSubscriptionShowed = createAction(BILLING_TYPES.SET_WEB_SUBSCRIPTION_SHOWED);
export const switchFakeSubscriptionMode = createAction(BILLING_TYPES.SWITCH_FAKE_SUBSCRIPTION_MODE);

export const initBilling = (): AppThunk<any> => {
  return async (dispatch: AppDispatch) => {
    dispatch(setPending(true));
    const startTime = new Date().getTime();
    trackEvent('init_start', { startTime });

    try {
      await dispatch(fetchWebPurchases());
      const eventData = { executionTime: Date.now() - startTime };
      trackEvent('init_finish', eventData);

      dispatch(setupPurchasedState());
      dispatch(verifySubscriptions());

      dispatch(setPending(false));
    } catch (error) {
      trackEvent('init_error', { error });
      console.log(`[Billing] Init failed ${error}`);
      dispatch(setPending(false));
      return [];
    }
  };
};

export const addFakePurchase = () => {
  return (dispatch: AppDispatch) => {
    dispatch(setPurchased({ purchased: true, purchasedAstrologers: true }));
    dispatch(setAstrologistFreeTime());
  };
};

export const verifySubscriptions = () => {
  return async (dispatch: AppDispatch) => {
    const subscriptions = await dispatch(verifyWebSubscriptions());

    dispatch(reactivationHandler());

    return subscriptions;
  };
};

export const verifyMobilePurchases = (): AppThunk => {
  return async (_dispatch: AppDispatch) => {};
};

export const reactivationHandler = (): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const isReactivate = checkIsReactivate(state);

    if (isReactivate) {
      dispatch(showModal(MODALS.REACTIVATION));
      return true;
    }

    return false;
  };
};

export const setupPurchasedState = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      billing: { transactions, webTransactions, isTempPurchaseActive },
    } = getState();

    if (webTransactions.length && isTempPurchaseActive) {
      dispatch(setTempPurchaseActive(false));
    }

    let purchased = !!webTransactions?.length || transactions?.some(item => item?.productId?.indexOf(SKU) === 0) || isTempPurchaseActive;

    const purchasedAstrologers = transactions?.some(item => item?.productId?.includes?.(PRO));
    const p = queryString.parse(window.location.search);
    purchased = purchased || p.purchased !== undefined;

    dispatch(setPurchased({ purchased, purchasedAstrologers }));

    dispatch(setAstrologistFreeTime());

    const userType = purchased || purchasedAstrologers ? 'subscriber' : 'free';
    Analytics.setUserProperty('user_type', userType);
  };
};

export const subscribe = (_productId: string): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(setPending(true));
    try {
      dispatch(setPending(false));
    } catch (error: any) {
      // Empty
    }
  };
};

export const purchase = (_productId: string): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(setPending(true));
    try {
      dispatch(setPending(false));
    } catch (error: any) {
      // Empty
    }
  };
};

export const purchaseWithOffer = (): AppThunk => {
  return async (_dispatch: AppDispatch) => {};
};

export const purchaseAstrologer = (): AppThunk => {
  return async (_dispatch: AppDispatch) => {};
};

export const restore = (): AppThunk => {
  return async (_dispatch: AppDispatch) => {};
};

export const getProductDetails = (productId: string): any => {
  return (_dispatch: AppDispatch, getState: AppGetState) => {
    const {
      billing: { products },
    } = getState();

    return products.find(item => item.productId === productId);
  };
};

export const cancelSubscriptions = () => {
  return (dispatch: AppDispatch) => {
    return dispatch(cancelSubscription());
  };
};
