import api from 'api';

import { GET_BALANCE } from './constants';
import type { TimeBalance } from './types';

const getTimeBalance = async (): Promise<TimeBalance> => {
  try {
    const response = await api.fetch(`${GET_BALANCE}`, {
      method: 'GET',
    });

    return response;
  } catch (error) {
    console.log('ERROR [Astrologist chat] getTimeBalance', error);
    return {
      balance: 0,
      freeBalance: 0,
      isRewardedForFirstUse: 1,
      isRewardedForRenew: 1,
      isRewardedForUnpaidUser: 1,
      lastTransactionDate: null,
      lastTransactionStatus: null,
      totalTransactionsCount: 0,
    };
  }
};

export default getTimeBalance;
