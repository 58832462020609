import { some, sortBy } from 'lodash';
import { createSelector } from 'reselect';

import type { Astrologist, ChatMetadata } from 'api/astrology-chat/types';
import { APP_NAME } from 'constants/general';
import { t } from 'localization';
import { ASTROLOGERS_OPEN_PLACE } from 'screens/advisors/constants';
import { ADVISOR_ASSISTANT, ASSISTANT_CHAT_STEPS } from 'screens/advisors/screens/chat-assistant/constants';
import { TUTORIAL_SUGGEST_CATEGORIES, PALM_READING_STEPS, TUTOR_STEPS } from 'screens/advisors/screens/chat-tutorial/constants';
import type { TutorStep, TutorSubstepType } from 'screens/advisors/screens/chat-tutorial/types';
import type { RootState } from 'store';

import { selectChatMetadata, selectMessagesByAdvisorId } from '../chat/selectors';
import { getAdvisorName } from '../core/selectors';
import { QUESTION_TYPES } from '../core/types';
import type { Questions, Question } from '../core/types';
import { selectOnlineAdvisors } from '../selectors';

const getCurrentStepIndex = (state: RootState) => state.astrologers.chatTutorial.currentStep;
const getCurrentSubstepIndex = (state: RootState) => state.astrologers.chatTutorial.currentSubstep;
const getPalmPhotoUrl = (state: RootState) => state.astrologers.chatTutorial.palmPhotoUrl;
const getUserQuestion = (state: RootState) => state.astrologers.chatTutorial.messageText;
const getAdvisorFromAssistant = (state: RootState) => state.astrologers.chatTutorial.advisorFromAssistant;
const getAllQuestions = (state: RootState) => state.astrologers.core.questions;
const getChatTutorialConfig = (state: RootState) => state.remoteConfig.chatTutorialConfig;
const getChatAssistantConfig = (state: RootState) => state.remoteConfig.chatAssistantConfig;

export const selectIsChatAssistantEnabled = createSelector(
  [getChatTutorialConfig, getChatAssistantConfig],
  (tutorialConfig, assistantConfig): boolean => {
    return tutorialConfig.enabled && tutorialConfig.palmReadingStepEnabled && assistantConfig.enabled;
  },
);

export const selectAdvisorFromAssistantProfileData = createSelector(
  [getAdvisorFromAssistant, selectOnlineAdvisors],
  (advisor, availableAdvisors): Astrologist => {
    return advisor || availableAdvisors[0];
  },
);

export const selectAvailableChatTutorialSteps = createSelector(
  [getChatTutorialConfig, selectIsChatAssistantEnabled],
  (chatTutorialConfig, isChatAssistant): TutorStep[] => {
    if (isChatAssistant) {
      return ASSISTANT_CHAT_STEPS;
    }

    if (chatTutorialConfig.palmReadingStepEnabled) {
      return TUTOR_STEPS.filter(step => step.id !== 'palmReadingDisabled');
    }

    return TUTOR_STEPS.filter(step => !PALM_READING_STEPS.includes(step.id));
  },
);

export const selectCurrentStep = createSelector(
  [selectAvailableChatTutorialSteps, getCurrentStepIndex],
  (chatTutorialSteps, currentStepIndex): TutorStep | null => {
    return chatTutorialSteps[currentStepIndex] || null;
  },
);

export const selectCurrentSubstep = createSelector(
  [selectCurrentStep, getCurrentSubstepIndex],
  (currentStep, currentSubstepIndex): TutorSubstepType | null => {
    return currentStep?.substeps[currentSubstepIndex] || null;
  },
);

export const selectNextStepIndex = createSelector(
  [selectAvailableChatTutorialSteps, getCurrentStepIndex],
  (chatTutorialSteps, currentStepIndex): number | null => {
    return currentStepIndex + 1 < chatTutorialSteps.length ? currentStepIndex + 1 : null;
  },
);

export const selectNextSubstepIndex = createSelector(
  [selectCurrentStep, getCurrentSubstepIndex],
  (currentStep, currentSubstepIndex): number | null => {
    return currentStep && currentSubstepIndex + 1 < currentStep.substeps.length ? currentSubstepIndex + 1 : null;
  },
);

export const selectMessageParams = createSelector(
  [getAdvisorName, selectAdvisorFromAssistantProfileData],
  (advisorName, advisorFromAssistant): Record<string, string> => {
    return { advisorName, appName: APP_NAME as string, advisorFromAssistant: advisorFromAssistant.name };
  },
);

export const selectSuggestQuestionsForChatTutorial = createSelector([getAllQuestions], (allQuestions): Questions => {
  const questions = TUTORIAL_SUGGEST_CATEGORIES.reduce((acc: Questions, category: string) => {
    if (allQuestions[category]) {
      const tutorQuestions = allQuestions[category].questions
        .filter(question => question.metadata.type === QUESTION_TYPES.SUGGEST)
        .map(question => ({
          ...question,
          metadata: { ...question.metadata, category },
        }));

      return {
        ...acc,
        [category]: {
          ...allQuestions[category],
          questions: sortBy(tutorQuestions, ['id']),
        },
      };
    }
    return acc;
  }, {});

  return questions;
});

export const selectSuggestQuestionsForTutorialPreview = createSelector(
  [selectSuggestQuestionsForChatTutorial, getAllQuestions],
  (availableQuestions, allQuestions): Question[] => {
    const filteredQuestions = allQuestions.all.questions.filter(question => question.metadata.type === QUESTION_TYPES.SUGGEST);
    return TUTORIAL_SUGGEST_CATEGORIES.map((category, i) => {
      return availableQuestions[category]?.questions[0] || filteredQuestions[i];
    });
  },
);

export const selectSkipStepCondition = createSelector([selectCurrentStep, getPalmPhotoUrl], (currentStep, palmPhotoUrl) => {
  return (currentStep?.id === 'palmReadingFail' && palmPhotoUrl) || (currentStep?.id === 'palmReadingSuccess' && !palmPhotoUrl);
});

export const selectTotalTutorialStepsCount = createSelector([selectAvailableChatTutorialSteps], (chatTutorialSteps): number => {
  return chatTutorialSteps.reduce((total, step) => {
    return total + step.substeps.length;
  }, 0);
});

export const selectOverallStepNumber = createSelector(
  [selectAvailableChatTutorialSteps, getCurrentStepIndex, getCurrentSubstepIndex],
  (chatTutorialSteps, stepIndex, substepIndex): number => {
    let overallIndex = 0;

    for (let i = 0; i < chatTutorialSteps.length; i++) {
      const currentStep = chatTutorialSteps[i];
      const substepsCount = currentStep.substeps.length;

      if (i === stepIndex) {
        overallIndex += substepIndex;
        break;
      }
      overallIndex += substepsCount;
    }

    return overallIndex + 1;
  },
);

export const selectIsThereBlurredMessageInChat = createSelector(
  [(state, id: Astrologist['astrologer_id']) => selectMessagesByAdvisorId(state, id)],
  (messages): boolean => {
    return some(messages, 'is_blurred');
  },
);

export const selectAdvisorAssistantProfileData = createSelector([getChatAssistantConfig], (assistantConfig): Astrologist => {
  return { ...ADVISOR_ASSISTANT, astrologer_id: assistantConfig.hiddenAdvisorId };
});

export const selectStartChatMessageAfterAssistant = createSelector(
  [selectAdvisorFromAssistantProfileData, selectAdvisorAssistantProfileData, getPalmPhotoUrl, getUserQuestion],
  (advisorData, assistantData, palmPhoto, userQuestion): string => {
    return t(`chatAssistant.advisorWelcomeMsg.${palmPhoto ? 'withPalmScan' : 'withoutPalmScan'}`, {
      advisorName: advisorData.name,
      appName: APP_NAME,
      assistantName: assistantData.name,
      userQuestion,
    });
  },
);

export const selectTutorialStartChatMetadata = createSelector(
  [
    selectIsChatAssistantEnabled,
    getChatTutorialConfig,
    (state, params: { advisorId: Astrologist['astrologer_id']; tm: boolean }) => selectChatMetadata(state, params.advisorId),
    (_state, params: { advisorId: Astrologist['astrologer_id']; tm: boolean }) => params.tm,
  ],
  (assistantModeEnabled, tutorialConfig, baseChatMetadata, tm): ChatMetadata => {
    const metadata: ChatMetadata = {
      ...baseChatMetadata,
      sps: tutorialConfig.symbolsPerSecond ?? 5,
      mtod: tutorialConfig.maxTimeOfDelay ?? 30,
      tm,
      palmReadingEnabled: false,
      sendPayments: false,
      isVoiceEnabled: false,
      place: assistantModeEnabled ? ASTROLOGERS_OPEN_PLACE.CHAT_ASSISTANT : ASTROLOGERS_OPEN_PLACE.CHAT_TUTORIAL,
    };

    return metadata;
  },
);
