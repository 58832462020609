import { SOFT_CURRENCY_TYPES } from 'components/advisors/soft-currency/constants';
import * as ROUTES from 'constants/routes';

import { PackType, ViewType, PACK_SCREENS_IDS, ASTROLOGERS_TRIGGERS_TYPES, PACK_TRIGGER_IDS } from './interfaces';
import type {
  AstrologerConsultationPacks,
  AstrologerPackSpecialOffer,
  AstrologerPackWelcomeOffer,
  AstrologerPacksFlows,
  AstrologerPackRoutes,
  AstrologersMonetizationConfig,
  AdvisorSoftCurrencyConfig,
  AstrologersMonetizationDefaultTriggers,
} from './interfaces';

export const PACK_SCREENS_ROUTES: AstrologerPackRoutes = {
  [PACK_SCREENS_IDS.PACKS]: ROUTES.ADVISORS_PACKS,
  [PACK_SCREENS_IDS.WELCOME_OFFER]: ROUTES.ADVISORS_PACK_WELCOME_OFFER,
  [PACK_SCREENS_IDS.SPECIAL_OFFER]: ROUTES.ADVISORS_PACK_SPECIAL_OFFER,
  [PACK_SCREENS_IDS.SPECIAL_OFFER_2]: ROUTES.ADVISORS_PACK_SPECIAL_OFFER_2,
};

export const PRODUCT_TRIGGERS_BY_SCREEN_ID: { [key: string]: PACK_TRIGGER_IDS } = {
  [PACK_SCREENS_IDS.PACKS]: PACK_TRIGGER_IDS.PACKS,
  [PACK_SCREENS_IDS.WELCOME_OFFER]: PACK_TRIGGER_IDS.WELCOME_OFFER,
  [PACK_SCREENS_IDS.SPECIAL_OFFER]: PACK_TRIGGER_IDS.OFFER_1,
  [PACK_SCREENS_IDS.SPECIAL_OFFER_2]: PACK_TRIGGER_IDS.OFFER_2,
};

export const astrologerPacksFlows: AstrologerPacksFlows = {
  noPurchased: [PACK_SCREENS_IDS.WELCOME_OFFER],
  purchased: [PACK_SCREENS_IDS.PACKS],
};

export const advisorSoftCurrencyConfig: AdvisorSoftCurrencyConfig = {
  enabled: false,
  type: SOFT_CURRENCY_TYPES.COINS,
  rate: 60,
};

export const FORCE_TRIGGERS = [
  ASTROLOGERS_TRIGGERS_TYPES.CHAT_NOTIFICATION,
  ASTROLOGERS_TRIGGERS_TYPES.ADVISORS_ONBOARDING,
  ASTROLOGERS_TRIGGERS_TYPES.CHAT_BTN,
  ASTROLOGERS_TRIGGERS_TYPES.CHAT_INPUT,
  ASTROLOGERS_TRIGGERS_TYPES.BLUR_MESSAGE,
  ASTROLOGERS_TRIGGERS_TYPES.SUGGESTS,
  ASTROLOGERS_TRIGGERS_TYPES.SUGGESTS_POPUP,
  ASTROLOGERS_TRIGGERS_TYPES.AUTO_REFILL,
];

export const CHATS_TRIGGERS = [
  ASTROLOGERS_TRIGGERS_TYPES.BALANCE,
  ASTROLOGERS_TRIGGERS_TYPES.CHAT_BTN,
  ASTROLOGERS_TRIGGERS_TYPES.CHAT_INPUT,
  ASTROLOGERS_TRIGGERS_TYPES.CHAT_TIME_UP,
  ASTROLOGERS_TRIGGERS_TYPES.CHAT_NOTIFICATION,
  ASTROLOGERS_TRIGGERS_TYPES.END_CHAT,
  ASTROLOGERS_TRIGGERS_TYPES.BLUR_MESSAGE,
  ASTROLOGERS_TRIGGERS_TYPES.SUGGESTS,
  ASTROLOGERS_TRIGGERS_TYPES.SUGGESTS_POPUP,
  ASTROLOGERS_TRIGGERS_TYPES.AUTO_REFILL,
  ASTROLOGERS_TRIGGERS_TYPES.TUTORIAL_STARTED,
  ASTROLOGERS_TRIGGERS_TYPES.TUTORIAL_END_SESSION,
  ASTROLOGERS_TRIGGERS_TYPES.TUTORIAL_COMPLETED,
];

export const CHAT_TUTORIAL_TRIGGERS: ASTROLOGERS_TRIGGERS_TYPES[] = [
  ASTROLOGERS_TRIGGERS_TYPES.TUTORIAL_STARTED,
  ASTROLOGERS_TRIGGERS_TYPES.TUTORIAL_END_SESSION,
  ASTROLOGERS_TRIGGERS_TYPES.TUTORIAL_COMPLETED,
];

export const astrologersMonetizationConfig: AstrologersMonetizationConfig = {
  [ASTROLOGERS_TRIGGERS_TYPES.BALANCE]: true,
  [ASTROLOGERS_TRIGGERS_TYPES.CHAT_TIME_UP]: false,
  [ASTROLOGERS_TRIGGERS_TYPES.END_CHAT]: true,
  [ASTROLOGERS_TRIGGERS_TYPES.AUTO_REFILL]: false,
  [ASTROLOGERS_TRIGGERS_TYPES.BLUR_MESSAGE]: true,
  [ASTROLOGERS_TRIGGERS_TYPES.TUTORIAL_STARTED]: false,
  [ASTROLOGERS_TRIGGERS_TYPES.TUTORIAL_END_SESSION]: true,
  [ASTROLOGERS_TRIGGERS_TYPES.TUTORIAL_COMPLETED]: true,
};

export const astrologersMonetizationDefaultTriggers: AstrologersMonetizationDefaultTriggers = {
  [ASTROLOGERS_TRIGGERS_TYPES.CHAT_NOTIFICATION]: true,
  [ASTROLOGERS_TRIGGERS_TYPES.CHAT_BTN]: true,
  [ASTROLOGERS_TRIGGERS_TYPES.CHAT_INPUT]: true,
  [ASTROLOGERS_TRIGGERS_TYPES.SUGGESTS]: true,
  [ASTROLOGERS_TRIGGERS_TYPES.SUGGESTS_POPUP]: true,
};

export const astrologerConsultationPacks: AstrologerConsultationPacks = {
  title: '',
  subtitle: '',
  btnText: '',
  packType: PackType.ONE,
  footnoteText: '',
  withCloseIcon: true,
  viewType: ViewType.FREE_MINS,
  defaultProductId: 9,
  withSoftCurrencyRate: false,
  packs: [
    {
      title: '', // '3 \n min'
      minutesAmount: 3,
      activePrice: '$9.99',
      notActivePrice: '',
      freeMinsAmount: 0,
      saveAmount: '',
      currency: 'USD',
      price: 9.99,
      rightText: '',
      rightTextColor: '',
      rightSubText: '',
      badgeText: '',
      EUR: {
        activePrice: '€9.99',
        notActivePrice: '',
        price: 9.99,
      },
      CAD: {
        activePrice: '$13.99 CAD',
        notActivePrice: '',
        price: 13.99,
      },
      AUD: {
        activePrice: '$14.99 AUD',
        notActivePrice: '',
        price: 14.99,
      },
    },
    {
      title: '',
      minutesAmount: 9,
      activePrice: '$19.99',
      notActivePrice: '',
      freeMinsAmount: 3,
      saveAmount: '$10',
      currency: 'USD',
      price: 19.99,
      rightText: '',
      rightTextColor: '',
      rightSubText: '',
      badgeText: '',
      EUR: {
        activePrice: '€19.99',
        notActivePrice: '',
        price: 19.99,
      },
      CAD: {
        activePrice: '$26.99 CAD',
        notActivePrice: '',
        price: 26.99,
      },
      AUD: {
        activePrice: '$29.99 AUD',
        notActivePrice: '',
        price: 29.99,
      },
    },
    {
      title: '',
      minutesAmount: 15,
      activePrice: '$29.99',
      notActivePrice: '',
      freeMinsAmount: 6,
      saveAmount: '$20',
      currency: 'USD',
      price: 29.99,
      rightText: '',
      rightTextColor: '',
      rightSubText: '',
      badgeText: '_mostPopular',
      EUR: {
        activePrice: '€29.99',
        notActivePrice: '',
        price: 29.99,
      },
      CAD: {
        activePrice: '$39.99 CAD',
        notActivePrice: '',
        price: 39.99,
      },
      AUD: {
        activePrice: '$45.99 AUD',
        notActivePrice: '',
        price: 45.99,
      },
    },
    {
      title: '',
      minutesAmount: 30,
      activePrice: '$39.99',
      notActivePrice: '',
      freeMinsAmount: 18,
      saveAmount: '$60',
      currency: 'USD',
      price: 39.99,
      rightText: '',
      rightTextColor: '',
      rightSubText: '',
      badgeText: '',
      EUR: {
        activePrice: '€39.99',
        notActivePrice: '',
        price: 39.99,
      },
      CAD: {
        activePrice: '$54.99 CAD',
        notActivePrice: '',
        price: 54.99,
      },
      AUD: {
        activePrice: '$59.99 AUD',
        notActivePrice: '',
        price: 59.99,
      },
    },
  ],
};

export const astrologerPackSpecialOffer: AstrologerPackSpecialOffer = {
  title: '',
  subtitle: '',
  btnText: '',
  footnoteText: '',
  withSoftCurrencyRate: false,
  pack: {
    title: '',
    minutesAmount: 3,
    activePrice: '$4.99',
    notActivePrice: '$9.99',
    badgeText: '',
    freeMinsAmount: 0,
    saveAmount: '',
    currency: 'USD',
    price: 4.99,
    EUR: {
      activePrice: '€4.99',
      notActivePrice: '€9.99',
      price: 4.99,
    },
    CAD: {
      activePrice: '$6.99 CAD',
      notActivePrice: '$13.99 CAD',
      price: 6.99,
    },
    AUD: {
      activePrice: '$7.99 AUD',
      notActivePrice: '$14.99 AUD',
      price: 7.99,
    },
  },
};

export const astrologerPackWelcomeOffer: AstrologerPackWelcomeOffer = {
  title: '',
  percentsOff: 90,
  subtitle: 'Refill minutes to continue chatting',
  btnText: '',
  footnoteText: '',
  withSoftCurrencyRate: false,
  pack: {
    title: '',
    freeMinsAmount: 0,
    saveAmount: '',
    rightText: '',
    currency: 'USD',
    minutesAmount: 5,
    activePrice: '$0.99',
    notActivePrice: '$9.99',
    price: 0.99,
    EUR: {
      activePrice: '€0.99',
      notActivePrice: '€9.99',
      price: 0.99,
    },
    CAD: {
      activePrice: '$6.99 CAD',
      notActivePrice: '$13.99 CAD',
      price: 6.99,
    },
    AUD: {
      activePrice: '$7.99 AUD',
      notActivePrice: '$14.99 AUD',
      price: 7.99,
    },
  },
};

export const astrologerPackSpecialOffer2: AstrologerPackSpecialOffer = {
  title: '',
  subtitle: '',
  btnText: '',
  footnoteText: '',
  withSoftCurrencyRate: false,
  pack: {
    title: '',
    minutesAmount: 10,
    activePrice: '$2.99',
    notActivePrice: '$29.99',
    freeMinsAmount: 0,
    saveAmount: '',
    currency: 'USD',
    price: 2.99,
    badgeText: '',
    EUR: {
      activePrice: '€1.99',
      notActivePrice: '€21.99',
      price: 1.99,
    },
    CAD: {
      activePrice: '$3.99 CAD',
      notActivePrice: '$23.99 CAD',
      price: 3.99,
    },
    AUD: {
      activePrice: '$4.99 AUD',
      notActivePrice: '$24.99 AUD',
      price: 4.99,
    },
  },
};

export const astrologerConsultationDiscountPacks: AstrologerConsultationPacks = {
  title: '',
  subtitle: '',
  btnText: '',
  packType: PackType.ONE,
  footnoteText: '',
  withCloseIcon: true,
  viewType: ViewType.FREE_MINS,
  defaultProductId: 3,
  withSoftCurrencyRate: false,
  percentsOff: 2,
  packs: [
    {
      title: '',
      minutesAmount: 3,
      activePrice: '$1.00',
      notActivePrice: '$19.99',
      freeMinsAmount: 0,
      saveAmount: '',
      currency: 'USD',
      price: 1,
      rightText: '{pricePerMin}/min',
      rightTextColor: '',
      rightSubText: '',
      badgeText: '',
      EUR: {
        activePrice: '',
        notActivePrice: '',
        price: 1,
      },
      CAD: {
        activePrice: '',
        notActivePrice: '',
        price: 1,
      },
      AUD: {
        activePrice: '',
        notActivePrice: '',
        price: 1,
      },
    },
  ],
};
