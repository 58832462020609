export const ASTROLOGY = '/astrology-questions';
export const ADD_MESSAGE = `${ASTROLOGY}/add-message`;
export const ADD_FREE_TIME = `${ASTROLOGY}/add-free-time`;
export const ACCRUE_TIME = `${ASTROLOGY}/accrue-time`;
export const ADD_REVIEW = `${ASTROLOGY}/add-review`;
export const ADD_TIME = `${ASTROLOGY}/add-time`;
export const GET_HISTORY = `${ASTROLOGY}/history`;
export const GET_ASTROLOGERS_PROFILES = `${ASTROLOGY}/astrologers`;
export const GET_CHATS = `${ASTROLOGY}/chats`;
export const START_CHAT = `${ASTROLOGY}/start`;
export const MODIFY_CHAT_PARAMS = `${ASTROLOGY}/chat-modify`;
export const PING_CHAT = `${ASTROLOGY}/ping`;
export const GET_BALANCE = `${ASTROLOGY}/balance`;
export const CHARGE_TIME = `${ASTROLOGY}/charge-time`;
export const USE_TEMPLATE = `${ASTROLOGY}/use-template`;
export const READ_MESSAGES = `${ASTROLOGY}/view`;
export const GET_CODE = `${ASTROLOGY}/check-code`;
export const DAILY_REWARDS = `${ASTROLOGY}/daily-rewards`;
export const GET_REVIEWS = `${ASTROLOGY}/reviews`;

export const METADATA_TYPES = [
  'tarotCard',
  'palmReadingLeftHandData',
  'palmScanData',
  'palmScanFromTutor',
  'palmRequest',
  'voiceMessage',
  'horoscopeMessage',
] as const;
