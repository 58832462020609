import type { ProactiveTypesByCode } from './types';

export const PROACTIVE_TYPES = ['pt0', 'pt1', 'pt2', 'pt3'] as const;

export const PROACTIVE_TYPES_BY_CODE: ProactiveTypesByCode = {
  [0]: 'pt0',
  [1]: 'pt1',
  [2]: 'pt2',
  [3]: 'pt3',
};
