import api from 'api';
import { fixBooleanParams } from 'utils/api';

import { START_CHAT } from './constants';
import type { ChatPlatforms, Astrologist, ChatMetadata } from './types';

interface StartChatParams {
  advisorId: Astrologist['astrologer_id'];
  platform: ChatPlatforms;
  message: string;
  needResponse?: boolean;
  metadata: ChatMetadata;
  webProject: string;
  version: string;
}

export interface StartChatResponse {
  chat_id: number;
  error?: string;
}

const startChat = async (params: StartChatParams): Promise<StartChatResponse | null> => {
  const { advisorId, needResponse = true, ...rest } = params;

  const data = fixBooleanParams({
    astrologer_id: advisorId,
    needResponse,
    ...rest,
  });

  try {
    const response = await api.fetch(START_CHAT, {
      method: 'POST',
      data,
    });

    if (response && 'chat_id' in response) {
      return response;
    }
  } catch (error) {
    console.log('ERROR [Astrologist chat] startChat', error);
  }

  return null;
};

export default startChat;
