import dayjs from 'dayjs';

export const isDateToday = (date: string) => {
  return dayjs(date).isSame(dayjs.utc().local().startOf('day'), 'day');
};

export const formatSeconds = (seconds: number) => {
  let format = '0[min] s[s]';
  if (seconds > 59) {
    if (seconds % 60 === 0) {
      format = 'm[min]';
    } else {
      format = 'm[min] s[s]';
    }
  }

  if (seconds > 3599) {
    format = 'h[h] m[min] s[s]';
  }

  return dayjs.utc(dayjs.duration(seconds, 'seconds').asMilliseconds()).format(format);
};

export const formatMinutes = (seconds: number) => {
  return Math.round(seconds / 60);
};
