import api from 'api';

import { ADD_REVIEW } from './constants';
import type { Astrologist } from './types';

const addReview = async (id: Astrologist['astrologer_id'], review: string, mark: number): Promise<undefined> => {
  try {
    const response = await api.fetch(ADD_REVIEW, {
      method: 'POST',
      data: {
        astrologer_id: id,
        review,
        mark,
      },
    });

    return response;
  } catch (error) {
    console.log('ERROR [Astrologist chat] addReview', error);
  }
};

export default addReview;
