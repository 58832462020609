import dayjs from 'dayjs';
import { isEmpty, without, sortBy, isArray, concat, uniqBy, sample, some } from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import type { Astrologist, AstrologistReviewItem, Chats, HistoryItemMetadata } from 'api/astrology-chat/types';
import { ADVISORS_STATUSES } from 'components/advisors/active-status';
import * as TRIGGERS from 'constants/monetization-triggers';
import type { AdvisorHoroscopeContent } from 'interfaces/horoscope';
import { t, t2 } from 'localization';
import { TOP_TAB_IDS } from 'screens/advisors/constants';
import type { AdvisorHoroscopePeriods } from 'screens/advisors/constants';
import { isValidAdvisorTab } from 'screens/advisors/screens/advisor-page/utils';
import { ADVISORS_CATALOG_CUSTOM_BLOCK_IDS } from 'screens/advisors/screens/catalog/types';
import type { ADVISORS_CATEGORIES, ADVISORS_CATALOG_BLOCK_IDS, ADVISORS_STEALTH_CATEGORIES } from 'screens/advisors/screens/catalog/types';
import { isValidCatalogBlock } from 'screens/advisors/screens/catalog/utils';
import type { RootState } from 'store';
import { formatTextWithoutTags } from 'utils/text-format-replace-tags';

import { ADVISOR_HOROSCOPE_SUB_STATUSES } from './horoscope/types';
import type { AdvisorHoroscopeSubData } from './horoscope/types';
import { checkAdvisorWorkingStatus } from './utils';

const getPurchased = (state: RootState) => state.billing.purchased;
const getAstrologers = (state: RootState) => state.astrologers.core.allAstrologers;
const getCurrentChats = (state: RootState) => state.astrologers.chat.currentChats;
const getAstrologersChats = (state: RootState) => state.astrologers.chat.chats;
const getConsultationTime = (state: RootState) => state.astrologers.time.seconds;
const getDailyTips = (state: RootState) => state.dailyTips.tips;
const getCompatibilityResult = (state: RootState) => state.compatibility.result;
const getAdvisorsCatalogBlocks = (state: RootState) => state.remoteConfig.advisorsCatalogBlocks;
const getAdvisorsCatalogStealthCategoryPid = (state: RootState) => state.remoteConfig.advisorsCatalogStealthCategoryPid;
const getRemoteAstrologistConfig = (state: RootState) => state.remoteConfig.astrologistConfig;
const getCategories = (state: RootState) => state.astrologers.catalog.categories;
const getPalmReadingData = (state: RootState) => state.astrologers.core.palmReadingData;
const getCurrentAstrologist = (state: RootState) => state.astrologers.core.astrologist;
const getAstrologistReviews = (state: RootState) => state.astrologers.core.astrologistReviews;
const getHoroscopeSubscriptions = (state: RootState) => state.astrologers.horoscope.subscriptions;
const getHoroscopeContent = (state: RootState) => state.astrologers.horoscope.horoscopeContent;
const getAdvisorHoroscopeEnabled = (state: RootState) => state.remoteConfig.advisorHoroscopeEnabled;
const getAdvisorTabs = (state: RootState) => state.remoteConfig.advisorTabs;
const getAdvisorOnlineStatusesEnabled = (state: RootState) => state.remoteConfig.advisorOnlineStatusesEnabled;
const getMonetizationConfig = (state: RootState) => state.remoteConfig.monetizationConfig;
const getTypingIds = (state: RootState) => state.astrologers.chat.typingIds;
const getRecordingIds = (state: RootState) => state.astrologers.chat.recordingIds;
const getStealthModeStatus = (state: RootState) => state.remoteConfig.stealthModeEnabled;
const getForcedOnlineAdvisorId = (state: RootState) => state.astrologers.chat.forcedOnlineAdvisorId;
const getChatTutorialAdvisorId = (state: RootState) => state.astrologers.chatTutorial.advisorId;
const getChatAssistantConfig = (state: RootState) => state.remoteConfig.chatAssistantConfig;

export const getLastConnectedAstrologer = createSelector([getAstrologers, getCurrentChats], (astrologers, chats) => {
  if (isEmpty(chats)) {
    return null;
  }

  // remove chats without messages
  const filteredActiveChats = chats.filter(i => !isEmpty(i.lastMessage));

  if (isEmpty(filteredActiveChats)) {
    return null;
  }

  const lastActiveChat = filteredActiveChats.reduce((acc, chat): Chats => {
    const dateA = moment(acc.lastMessage.created_at).format('YYYY-MM-DD HH:mm:ss').valueOf();
    const dateB = moment(chat.lastMessage.created_at).format('YYYY-MM-DD HH:mm:ss').valueOf();

    return dateA < dateB ? chat : acc;
  });

  return astrologers.find((a: Astrologist) => +a?.astrologer_id === +lastActiveChat?.astrologer_id);
});

export const selectAdvisorForCrossPromo = createSelector(
  [getAstrologers, getCurrentChats, getPurchased, getMonetizationConfig, getAdvisorOnlineStatusesEnabled],
  (astrologers, chats, purchased, monetizationConfig, advisorOnlineStatusesEnabled): Astrologist => {
    const availableRegularAdvisors = astrologers.filter(a => {
      const isPremium =
        a.is_premium && some([TRIGGERS.PREMIUM_ADVISOR_CLICK, TRIGGERS.PREMIUM_ADVISOR_START_CHAT], trg => !isEmpty(monetizationConfig[trg]));

      if (advisorOnlineStatusesEnabled) {
        return checkAdvisorWorkingStatus(a.work_time) && !isPremium && a.is_active;
      }

      return !isPremium && a.is_active;
    });

    if (isEmpty(chats)) {
      return sample(availableRegularAdvisors) as Astrologist;
    }

    // remove chats without messages
    let filteredActiveChats = chats.filter(chat => !isEmpty(chat.lastMessage));

    if (!purchased) {
      const availableAdvisorIds = availableRegularAdvisors.map(a => +a.astrologer_id);
      filteredActiveChats = filteredActiveChats.filter(chat => availableAdvisorIds.includes(+chat.astrologer_id));
    }

    if (isEmpty(filteredActiveChats)) {
      return sample(availableRegularAdvisors) as Astrologist;
    }

    const lastActiveChat = sortBy(filteredActiveChats, chat => dayjs(chat.lastMessage.created_at).format()).reverse()[0];

    return (astrologers.find((a: Astrologist) => +a?.astrologer_id === +lastActiveChat?.astrologer_id) ||
      sample(availableRegularAdvisors)) as Astrologist;
  },
);

export const getFreeMinutes = createSelector([getPurchased, getRemoteAstrologistConfig], (purchased, astrologistConfig) => {
  return purchased ? astrologistConfig.freeMinutes : astrologistConfig.freeMinutesForFreeUsers;
});

export const selectFreeMinutesCount = createSelector([getAstrologersChats, getConsultationTime, getFreeMinutes], (chats, seconds, freeMinutes) => {
  return isEmpty(chats) && seconds > 0 && freeMinutes > 0 ? freeMinutes : 0;
});

export const getAstrologersPromoDailyTipsContext = createSelector([getDailyTips], tips => {
  let dailyTipsContext = '';
  if (tips.length > 0) {
    dailyTipsContext = tips.reduce((acc, item) => {
      if (item.category === 'daily_tips') {
        return acc;
      }

      const text = `${item.category}: ${formatTextWithoutTags(item.text)};`;

      if (acc.length > 0) {
        return `${acc} ${text}`;
      }

      return `${text}`;
    }, '');
  }

  return dailyTipsContext;
});

export const getAstrologersPromoCompatibilityContext = createSelector([getCompatibilityResult], compatibilityResult => {
  if (!compatibilityResult) {
    return '';
  }

  const { overview, toxicity, compatibilityLevel, aspects, wheelOfBalance, signs } = compatibilityResult;
  const overviewContext = `Relationship Overview: ${overview}`;
  const compatibilityLevelContext = `Compatibility Level:\nName: ${compatibilityLevel.name};\nDescription: ${compatibilityLevel.description};\nPercent: ${compatibilityLevel.percent};\nSigns: ${signs.first.sign} - ${signs.second.sign}`;
  const toxicityContext = `Toxicity score: ${toxicity.percent}; Toxicity level: ${t2(
    `COMPATIBILITY.PERCENTS_TITLE.${toxicity.name.toUpperCase()}`,
  )}; Toxicity description: ${toxicity.description}`;

  const aspectContext = aspects.reduce((acc, item) => {
    if (acc.length > 0) {
      return `${acc}\n\n${item.id}: Description: ${item.description}; Percent: ${item.percent}`;
    }

    return `Aspects:\n\n${item.id}: Description: ${item.description}; Percent: ${item.percent}`;
  }, '');

  const wheelOfBalanceContext = wheelOfBalance.reduce((acc, item) => {
    if (acc.length > 0) {
      return `${acc}\n${item.type} - Description: ${item.description}; Level: ${item.name}; Percent: ${item.percent};`;
    }

    return `Wheel Of Balance: ${item.type} - Description: ${item.description}; Level: ${item.name}; Percent: ${item.percent};`;
  }, '');

  return `Compatibility:\n${compatibilityLevelContext};\n${overviewContext};\n${wheelOfBalanceContext};\n${toxicityContext};\n${aspectContext}`;
});

export const selectActiveChats = createSelector([getAstrologersChats, getChatAssistantConfig], (chats, assistantConfig) => {
  const assistantId = `${assistantConfig.hiddenAdvisorId}`;
  const chatsHistory = Object.keys(chats)
    .filter(id => id !== assistantId)
    .map(id => {
      return {
        advisorId: id,
        messages: chats[id]?.messages,
      };
    });

  const activeChats = chatsHistory.filter(chat => {
    return chat?.messages?.length && !!chat?.messages[0] && !isArray(chat?.messages[0]);
  });

  return sortBy(activeChats, chat => chat.messages[0]?.created_at).reverse();
});

export const selectNumberOfUnreadMessagesById = createSelector(
  [selectActiveChats, (_state, advisorId: Astrologist['astrologer_id']) => advisorId],
  (chats, advisorId): number => {
    const chat = chats.find(ch => `${ch.advisorId}` === `${advisorId}`);

    if (chat) {
      const unreadMessages = chat.messages?.filter(message => !message?.is_viewed_by_user && message?.type === 'answer');
      return unreadMessages.length;
    }

    return 0;
  },
);

export const selectHoroscopeSubscriptionsAndActiveChats = createSelector(
  [getAdvisorHoroscopeEnabled, getHoroscopeSubscriptions, selectActiveChats],
  (advisorHoroscopeEnabled, subscriptions, activeChats) => {
    if (!advisorHoroscopeEnabled) {
      return activeChats;
    }

    const horoscopeSubscriptionChats = subscriptions.map(subscription => {
      return {
        advisorId: `${subscription.id}`,
        messages: [],
        created_at: dayjs.utc(dayjs(subscription.lastUpdatedAt)).format('YYYY-MM-DD HH:mm:ss'),
      };
    });

    const chats = uniqBy(concat(activeChats, horoscopeSubscriptionChats), 'advisorId');

    return sortBy(chats, chat => {
      if (chat.messages[0]) {
        return chat.messages[0].created_at;
      }

      if ('created_at' in chat) {
        return chat.created_at;
      }
    }).reverse();
  },
);

export const getAvailableAdvisorsCatalogBlocks = createSelector(
  [getAdvisorsCatalogBlocks, getAdvisorsCatalogStealthCategoryPid, getStealthModeStatus, selectHoroscopeSubscriptionsAndActiveChats],
  (blocks, stealthCategories, stealthMode, chats): ADVISORS_CATALOG_BLOCK_IDS[] => {
    let availableBlocks = stealthMode ? (Object.keys(stealthCategories) as ADVISORS_STEALTH_CATEGORIES[]) : blocks;
    availableBlocks.filter(isValidCatalogBlock);

    if (!chats.length) {
      availableBlocks = without(availableBlocks, ADVISORS_CATALOG_CUSTOM_BLOCK_IDS.CHATS);
    }
    return availableBlocks;
  },
);

export const getActiveAdvisors = createSelector([getAstrologers], (astrologers): Astrologist[] => {
  return astrologers.filter(astrologer => astrologer.is_active);
});

export const getAdvisorsByCategoryId = createSelector(
  [getActiveAdvisors, getCategories, getStealthModeStatus, (_state, categoryId: ADVISORS_CATEGORIES) => categoryId],
  (advisors, categories, stealthMode, categoryId): Astrologist[] => {
    const availableAdvisors = advisors.filter(astrologer => categories[categoryId]?.includes?.(astrologer.astrologer_id)) || [];
    return stealthMode ? sortBy(availableAdvisors, 'astrologer_id') : availableAdvisors;
  },
);

export const getAstrologistDataById = (state: RootState, id: Astrologist['astrologer_id']): Astrologist | null => {
  const allAstrologers = getAstrologers(state);
  return allAstrologers.find(item => `${item.astrologer_id}` === `${id}`) || null;
};

export const getPalmReadingMetadata = createSelector(
  [getAstrologers, getPalmReadingData, (_state, chatId: Astrologist['astrologer_id']) => chatId],
  (allAstrologers, palmReadingData, chatId): [string, HistoryItemMetadata] => {
    if (!palmReadingData) {
      return ['', {}];
    }

    const advisorName = allAstrologers.find(item => `${item.astrologer_id}` === `${chatId}`)?.name || '';
    const message = t('ADVISOR_PALM_READING.MESSAGE', { name: advisorName });

    const metadata: HistoryItemMetadata = {
      url: palmReadingData.image,
      hiddenMessage: palmReadingData.hiddenMessage,
      type: 'palmReadingLeftHandData',
    };

    return [message, metadata];
  },
);

export const getCurrentAstrologistReviews = (state: RootState): AstrologistReviewItem[] | null => {
  const astrologist = getCurrentAstrologist(state);
  const reviews = getAstrologistReviews(state);

  return reviews[astrologist.astrologer_id] || null;
};

export const checkAvailabilityOfSubscription = createSelector(
  [getHoroscopeSubscriptions, (_state, id: Astrologist['astrologer_id']) => id],
  (subscriptions, id): AdvisorHoroscopeSubData | null => {
    const horoscopeSubscription = subscriptions.find(subscription => subscription.id === id);

    return horoscopeSubscription || null;
  },
);

export const selectActiveHoroscopeSubscription = createSelector([getHoroscopeSubscriptions], (subscriptions): AdvisorHoroscopeSubData | null => {
  const horoscopeSubscription = subscriptions.find(subscription => subscription.status === ADVISOR_HOROSCOPE_SUB_STATUSES.ACTIVE);

  return horoscopeSubscription || null;
});

export const selectHoroscopeAdvisorId = createSelector(
  [getHoroscopeSubscriptions, getAdvisorHoroscopeEnabled],
  (subscriptions, advisorHoroscopeEnabled) => {
    if (!subscriptions.length || !advisorHoroscopeEnabled) {
      return null;
    }

    const currentSubscriptionId = subscriptions.reduce((acc, current) =>
      dayjs(current.lastUpdatedAt).isAfter(dayjs(acc.lastUpdatedAt)) ? current : acc,
    ).id;

    return currentSubscriptionId;
  },
);

export const selectAdvisorHoroscopeContentByPeriod = createSelector(
  [getHoroscopeContent, (_state, period: AdvisorHoroscopePeriods) => period],
  (horoscopeContent, period): AdvisorHoroscopeContent | null => {
    return horoscopeContent[period] || null;
  },
);

export const selectAdvisorTabs = createSelector(
  [getAdvisorTabs, getAdvisorHoroscopeEnabled],
  (advisorTabs, advisorHoroscopeEnabled): TOP_TAB_IDS[] => {
    const availableTabs = advisorTabs.filter(isValidAdvisorTab);
    const tabs = availableTabs.includes(TOP_TAB_IDS.CHAT) ? availableTabs : [TOP_TAB_IDS.CHAT, ...availableTabs];

    if (!advisorHoroscopeEnabled) {
      return tabs.filter(item => item !== TOP_TAB_IDS.HOROSCOPE);
    }

    return tabs;
  },
);

export const selectIsPremiumWithoutForcedOnlineAdvisor = createSelector(
  [getForcedOnlineAdvisorId, getChatTutorialAdvisorId, (_state, advisor?: Astrologist) => advisor],
  (forcedOnlineAdvisorId, chatTutorialAdvisorId, advisor): boolean => {
    if (!advisor) {
      return false;
    }

    const isForcedOnlineAdvisor =
      `${advisor.astrologer_id}` === `${forcedOnlineAdvisorId}` || `${advisor.astrologer_id}` === `${chatTutorialAdvisorId}`;

    return !!advisor.is_premium && !isForcedOnlineAdvisor;
  },
);

export const selectAdvisorStatus = createSelector(
  [
    getAstrologers,
    getAdvisorOnlineStatusesEnabled,
    getStealthModeStatus,
    getTypingIds,
    getRecordingIds,
    getForcedOnlineAdvisorId,
    getChatTutorialAdvisorId,
    getCurrentChats,
    (_state, advisorId: Astrologist['astrologer_id']) => advisorId,
  ],
  (
    allAdvisors,
    advisorOnlineStatusesEnabled,
    stealthMode,
    typingIds,
    recordingIds,
    forcedOnlineAdvisorId,
    chatTutorialAdvisorId,
    chats,
    advisorId,
  ): ADVISORS_STATUSES => {
    const advisor = allAdvisors.find(item => `${item.astrologer_id}` === `${advisorId}`);

    if (!advisor || !advisor.is_active) {
      return ADVISORS_STATUSES.OFFLINE;
    }

    if (`${advisorId}` === `${forcedOnlineAdvisorId}` || `${advisorId}` === `${chatTutorialAdvisorId}`) {
      return ADVISORS_STATUSES.ONLINE;
    }

    if (advisorOnlineStatusesEnabled && !stealthMode) {
      const chatId = chats.find(chat => `${chat.astrologer_id}` === `${advisorId}`)?.chatId;
      const isActive = chatId ? concat(typingIds, recordingIds).includes(chatId) : false;

      return checkAdvisorWorkingStatus(advisor.work_time) || isActive;
    }

    return ADVISORS_STATUSES.ONLINE;
  },
);

export const checkAdvisorIsOnline = (state: RootState, id: Astrologist['astrologer_id']): boolean => {
  const status = selectAdvisorStatus(state, id);
  return status === ADVISORS_STATUSES.ONLINE;
};

export const selectOnlineAdvisors = createSelector(
  [getAstrologers, getAdvisorOnlineStatusesEnabled, getStealthModeStatus],
  (advisors: Astrologist[], advisorOnlineStatusesEnabled, stealthMode): Astrologist[] => {
    const onlineAdvisors = advisors.filter(advisor => {
      if (advisorOnlineStatusesEnabled && !stealthMode) {
        return advisor.is_active && checkAdvisorWorkingStatus(advisor.work_time);
      }

      return advisor.is_active;
    });

    return onlineAdvisors;
  },
);
