import api from 'api';

import { GET_HISTORY } from './constants';
import type { HistoryItem, Astrologist } from './types';

const getHistory = async (id: Astrologist['astrologer_id'], receiveTarot: boolean = true): Promise<HistoryItem[]> => {
  try {
    const response = await api.fetch(`${GET_HISTORY}?astrologer_id=${id}&receiveTarot=${receiveTarot}`, {
      method: 'GET',
    });

    return response;
  } catch (error) {
    console.log('ERROR [Astrologist chat] getHistory', error);
    return [] as HistoryItem[];
  }
};

export default getHistory;
