import _ from 'lodash';
import { createAction } from 'redux-actions';

import Analytics from 'analytics';
import * as MODALS from 'constants/modals';
import { APP_FOREGROUND, PROMO_EXCLUDE_TRIGGERS, REACTIVATION_EXCLUDE_TRIGGERS } from 'constants/monetization-triggers';
import { MONETIZATION_TYPES } from 'constants/monetization-types';
import { BACKGROUND_EXCLUDE_ROUTES } from 'constants/routes';
import { SUB_SCREENS } from 'constants/subscription-screens';
import { isShowPromoLimitReached } from 'modules/promo-offers/store/actions';
import type { AppDispatch, AppGetState } from 'store';
import { checkIsReactivate } from 'store/billing/selectors';
import { showModal } from 'store/modals/actions';
import { navigate, navigateToSubscriptionScreen, replace, navigateToInAppModal } from 'store/navigation/actions';

import { TYPES } from './types';
import { checkMonetizationConfig, isPromoEnabledForTrigger } from './utils';

const setStep = createAction(TYPES.SET_STEP);
const setCurrentTrigger = createAction(TYPES.SET_CURRENT_TRIGGER);
const setLastTrigger = createAction(TYPES.SET_LAST_TRIGGER);
export const setBackgroundPlacementVisibility = createAction(TYPES.SET_BACKGROUND_PLACEMENT_VISIBILITY);

export const resetMonetizationFlow = () => {
  return (dispatch: AppDispatch, _getState: AppGetState) => {
    dispatch(setCurrentTrigger(null));
    dispatch(setStep(0));
  };
};

export const nextMonetizationStep = (isAfterSuccessPurchase = false) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const { currentTrigger } = getState().unlockContent;

    if (!currentTrigger) {
      return true;
    }

    return dispatch(navigateWithMonetization(currentTrigger, isAfterSuccessPurchase));
  };
};

export const navigateWithMonetization = (attributes: any, isAfterSuccessPurchase: boolean = false) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const {
      promoOffers: { active: isPromoOfferActive },
      unlockContent: { step },
      billing: { purchased },
    } = state;

    const { trigger, route, params = {}, resetStack, replaceStack, onSuccess, onSubClose } = attributes;
    const nextStep = step + 1;
    dispatch(setStep(nextStep));

    dispatch(setCurrentTrigger(attributes));
    dispatch(setLastTrigger(attributes));
    const monetizationFlow = checkMonetizationConfig(state, trigger);
    const isValidFlow = !_.isEmpty(monetizationFlow) && _.isArray(monetizationFlow);
    const isCompleted = isValidFlow && step >= monetizationFlow.length;
    const currentConfig: any = isValidFlow && monetizationFlow[step];
    const prevConfig: any = isValidFlow && step > 0 && monetizationFlow[step - 1];
    const isAfterSub = !_.isEmpty(prevConfig) && prevConfig?.type === MONETIZATION_TYPES.SUBSCRIPTION;
    const nextConfig: any = isValidFlow && !isCompleted && monetizationFlow[nextStep];

    if (__DEV__) {
      console.log(`
        trigger: ${trigger};
        route: ${route};
        step: ${step};
        monetizationFlow: ${isValidFlow ? JSON.stringify(monetizationFlow) : false};
        currentConfig: ${JSON.stringify(currentConfig)};
        nextConfig: ${JSON.stringify(nextConfig)};
        isCompleted: ${isCompleted};
        isValidFlow: ${isValidFlow};
        isAfterSub: ${isAfterSub};
        isPurchased: ${purchased};
      `);
    }

    const onCompleted = (isReplace = false) => {
      dispatch(resetMonetizationFlow());

      const run = isReplace || isAfterSub || replaceStack ? replace : navigate;
      if (typeof onSuccess === 'function') {
        onSuccess();
      }

      if (route) {
        run(route, params);
        return false;
      }

      // it means that back() will be triggered
      return true;
    };

    if (!_.isEmpty(currentConfig) && currentConfig.type === MONETIZATION_TYPES.IN_APP) {
      dispatch(resetMonetizationFlow());

      return dispatch(
        navigateToInAppModal({
          trigger,
          triggerConfig: currentConfig,
          onSuccess: () => onCompleted(false),
        }),
      );
    }

    if (purchased) {
      const exclude = PROMO_EXCLUDE_TRIGGERS.includes(trigger);
      const isLimitReached = dispatch(isShowPromoLimitReached());
      const isEnabledForTrigger = isPromoEnabledForTrigger(state, trigger);
      if (isPromoOfferActive && isEnabledForTrigger && step === 0 && !exclude && !isLimitReached) {
        return dispatch(
          navigateToSubscriptionScreen({
            trigger,
            triggerConfig: { screen: SUB_SCREENS.PROMO_OFFER },
            onSuccess: () => onCompleted(true),
          }),
        );
      }

      return onCompleted(isAfterSuccessPurchase);
    }

    if (!isCompleted && !_.isEmpty(currentConfig)) {
      if (currentConfig.type === MONETIZATION_TYPES.SUBSCRIPTION) {
        const isReactivate = checkIsReactivate(state);

        if (isReactivate) {
          dispatch(resetMonetizationFlow());
          if (REACTIVATION_EXCLUDE_TRIGGERS.includes(trigger)) {
            return onCompleted();
          }

          dispatch(showModal(MODALS.REACTIVATION));
          return true;
        }

        return dispatch(
          navigateToSubscriptionScreen({
            trigger,
            triggerConfig: currentConfig,
            resetStack,
            onSuccess: () => onCompleted(true),
          }),
        );
      }
    }

    if (isAfterSub) {
      dispatch(resetMonetizationFlow());
      if (typeof onSubClose === 'function') {
        onSubClose();
        return false;
      }
      return true;
    }
    return onCompleted();
  };
};

export const showBackgroundPlacement = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const {
      onboarding: { isOnboardingCompleted },
      promoOffers: { active: isPromoOfferActive },
      billing: { purchased },
      unlockContent: { step },
      navigation: { currentRoute },
      remoteConfig = { subAfterBackgroundSessionStart: 0 },
    } = state;

    const { subAfterBackgroundSessionStart } = remoteConfig;

    const sessionNumber = (Analytics.getSessionNumber() ?? 0) + 1;
    const monetizationFlow = checkMonetizationConfig(state, APP_FOREGROUND);
    const isValidFlow = !_.isEmpty(monetizationFlow) && _.isArray(monetizationFlow);
    const currentConfig: any = isValidFlow && monetizationFlow[step];

    if (BACKGROUND_EXCLUDE_ROUTES.includes(currentRoute)) {
      return dispatch(setBackgroundPlacementVisibility(false));
    }

    if (!isOnboardingCompleted) {
      return dispatch(setBackgroundPlacementVisibility(false));
    }

    if (isPromoOfferActive && purchased) {
      return dispatch(setBackgroundPlacementVisibility(true));
    }

    if (sessionNumber < subAfterBackgroundSessionStart) {
      return dispatch(setBackgroundPlacementVisibility(false));
    }

    if (monetizationFlow === null) {
      return dispatch(setBackgroundPlacementVisibility(false));
    }

    if (!currentConfig?.type) {
      return dispatch(setBackgroundPlacementVisibility(false));
    }

    return dispatch(setBackgroundPlacementVisibility(true));
  };
};
