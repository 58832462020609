import interpolate from 'interpolate';
import { sample } from 'lodash';

import Analytics from 'analytics';
import { getAdvisorsTarotCardNames } from 'api/astrologers';
import { startChat } from 'api/astrology-chat';
import addMessage from 'api/astrology-chat/add-message';
import modifyChatParams from 'api/astrology-chat/modify-chat-params';
import type { StartChatResponse } from 'api/astrology-chat/start-chat';
import type { HistoryItemMetadata, Astrologist, ChatMetadata } from 'api/astrology-chat/types';
import useTemplate from 'api/astrology-chat/use-template';
import { ADVISORS_CHAT_TUTORIAL } from 'constants/routes';
import { t } from 'localization';
import { ASTROLOGERS_OPEN_PLACE, TEMPLATE_IDS } from 'screens/advisors/constants';
import { DEFAULT_CARD_NAME } from 'screens/advisors/screens/chat-tutorial/constants';
import { isTutorSuggestCategory } from 'screens/advisors/screens/chat-tutorial/utils';
import type { AppThunk } from 'store';
import { navigate } from 'store/navigation/actions';
import { selectRightHandData } from 'store/palm-reading-daily/selectors';
import { setUserParams } from 'store/profile/actions';

import {
  setForceTyping,
  setCurrentChatId,
  addNewMessage,
  setLastMessageRead,
  getHistoryMessages,
  trackChatWithAstrologistEvent,
} from '../chat/actions';
import { selectChatMetadata } from '../chat/selectors';
import { getHistoryItemTemplate, getChatParams } from '../chat/utils';
import { setAstrologistAction, setQuestionsCategoryAction } from '../core/actions';
import { getAdvisorId } from '../core/selectors';
import type { ExtendedQuestion, Question } from '../core/types';
import { selectOnlineAdvisors } from '../selectors';

import {
  selectNextStepIndex,
  selectNextSubstepIndex,
  selectCurrentSubstep,
  selectMessageParams,
  selectCurrentStep,
  selectSkipStepCondition,
  selectOverallStepNumber,
  selectIsChatAssistantEnabled,
  selectAdvisorFromAssistantProfileData,
  selectStartChatMessageAfterAssistant,
  selectTutorialStartChatMetadata,
} from './selectors';

import {
  setIsCompleted,
  setCurrentStep,
  setCurrentSubstep,
  setIsStarted,
  setAdvisorId,
  setIsBusy,
  setTarotCard,
  setMessageText,
  setSuggestCategory,
  setAdvisorFromAssistant,
} from '.';

/* Chat tutorial init */
export const initTutor =
  (advisorId: Astrologist['astrologer_id']): AppThunk<void> =>
  dispatch => {
    dispatch(setAdvisorId(advisorId));
    dispatch(setIsStarted());
    dispatch(setUserParams({ tutorial_is_started: true }));
  };

/* Start chat */
export const startChatInTutor = (): AppThunk<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  const advisorId = getAdvisorId(state);
  const stepId = selectCurrentStep(state)?.id || 'greeting';
  const substep = selectCurrentSubstep(state);
  const tutorIsStarted = state.astrologers.chatTutorial.isStarted;

  if (!substep) {
    return;
  }

  const metadata = selectTutorialStartChatMetadata(state, { advisorId, tm: true });

  const { chat_id: chatId } =
    (await startChat({
      advisorId,
      message: '',
      needResponse: false,
      metadata,
      ...getChatParams(),
    })) || ({} as StartChatResponse);

  if (!chatId) {
    return;
  }

  Analytics.track('ChatTutorial_Start', { advisorId, chatId, stepId });

  if (!tutorIsStarted) {
    Analytics.track('ChatTutorial_Init', { advisorId, chatId });
    dispatch(initTutor(advisorId));
  }

  dispatch(setCurrentChatId(chatId));
  dispatch(generateRandomTarotCard());
  dispatch(generateRandomAdvisorFromAssistant());

  if (substep.type === 'assistantText') {
    dispatch(startForcedTyping());
  }

  if (substep.autorun) {
    dispatch(runCurrentSubstep());
  }
};

/* Finish chat */
export const endChatInTutor = (): AppThunk<Promise<void>> => async dispatch => {
  dispatch(setCurrentChatId(0));
  dispatch(setForceTyping(false));
};

/* Run step */
export const runCurrentSubstep = (): AppThunk<Promise<void>> => async (dispatch, getState) => {
  const state = getState();
  const step = selectCurrentStep(state);
  const substep = selectCurrentSubstep(state);
  const skipStep = selectSkipStepCondition(state);
  const advisorId = getAdvisorId(state);
  const { isCompleted, messageText, suggestCategory, tarotCard, palmPhotoUrl } = state.astrologers.chatTutorial;

  if (isCompleted) {
    return;
  }

  if (!step || !substep) {
    dispatch(completeTutor());
    return;
  }

  if (skipStep) {
    dispatch(completeStep());
    dispatch(runCurrentSubstep());
    return;
  }

  switch (substep.type) {
    case 'assistantText': {
      await dispatch(addTutorAssistantMessage({ text: `${t(substep.contentKey)}` }));
      break;
    }

    case 'userText': {
      dispatch(
        addTutorUserMessage({
          text: `${t(substep.contentKey)}`,
        }),
      );
      break;
    }

    case 'suggest': {
      dispatch(
        addTutorUserMessage({
          text: messageText,
        }),
      );
      break;
    }

    case 'tarot': {
      await useTemplate(
        advisorId,
        TEMPLATE_IDS.TAROT_IN_TUTOR,
        {
          tarotCard,
          question: messageText,
        },
        true,
      );
      break;
    }

    case 'tarotCard': {
      const metadata: HistoryItemMetadata = {
        cardName: DEFAULT_CARD_NAME,
        type: 'tarotCard',
        url: substep.imageURL,
      };

      await dispatch(addTutorAssistantMessage({ text: '', metadata }));
      break;
    }

    case 'takePalmPhoto': {
      if (palmPhotoUrl) {
        dispatch(
          addTutorUserMessage({
            text: '',
            metadata: {
              type: 'palmScanData',
              url: palmPhotoUrl,
            },
          }),
        );
      }
      break;
    }

    case 'palmIntro': {
      const contentKeySuffix = isTutorSuggestCategory(suggestCategory) ? suggestCategory : 'love';
      await dispatch(addTutorAssistantMessage({ text: t(`${substep.contentKey}.${contentKeySuffix}`) }));
      break;
    }

    case 'palmReport': {
      const hiddenMessage = selectRightHandData(state)?.astrologersMessage;
      const metadata = selectTutorialStartChatMetadata(state, { advisorId, tm: false });
      await useTemplate(advisorId, TEMPLATE_IDS.PALM_READING_IN_CHAT, {}, true, hiddenMessage);
      // Rewrite chat params (disable unlimited mode) to receive blurred messages
      await modifyChatParams({ advisorId, metadata });
      break;
    }

    case 'advisorFromAssistant': {
      const advisorFromAssistant = selectAdvisorFromAssistantProfileData(state);
      const advisorGender = advisorFromAssistant.gender.toLowerCase();
      await dispatch(addTutorAssistantMessage({ text: `${t(substep.contentKey)[advisorGender]}` }));
      break;
    }

    case 'endTutorial': {
      break;
    }
  }

  dispatch(completeSubstep());
  dispatch(setIsBusy(true));
};

/* Add assistant message */
export const addTutorAssistantMessage =
  (params: { text: string; metadata?: HistoryItemMetadata }): AppThunk<Promise<void>> =>
  async (_dispatch, getState) => {
    const { text, metadata } = params;

    const state = getState();
    const advisorId = getAdvisorId(state);
    const messageParams = selectMessageParams(state);

    await addMessage({
      advisorId,
      messageText: interpolate(text, messageParams),
      needAnswer: false,
      fromAstrologer: true,
      metadata,
    });
  };

/* Add user message */
export const addTutorUserMessage =
  (params: { text: string; metadata?: HistoryItemMetadata }): AppThunk<void> =>
  (dispatch, getState) => {
    const { text, metadata } = params;

    const state = getState();
    const advisorId = getAdvisorId(state);
    const messageParams = selectMessageParams(state);

    dispatch(
      addNewMessage({
        advisorId,
        message: getHistoryItemTemplate({
          message: interpolate(text, messageParams),
          metadata,
        }),
        needAnswer: false,
      }),
    );

    setTimeout(() => {
      dispatch(setLastMessageRead(advisorId));
    }, 500);

    setTimeout(() => {
      dispatch(startForcedTyping());
    }, 500);
  };

/* Typing immitation */
export const startForcedTyping = (): AppThunk<void> => dispatch => {
  dispatch(setForceTyping(true));

  setTimeout(() => {
    dispatch(setForceTyping(false));
  }, 2000);
};

/* Complete step */
export const completeSubstep = (): AppThunk<void> => (dispatch, getState) => {
  const state = getState();
  const step = selectCurrentStep(state);
  const substep = selectCurrentSubstep(state);
  const overallStepNumber = selectOverallStepNumber(state);

  if (step && substep) {
    Analytics.track('ChatTutorial_Substep_Completed', { stepId: step.id, type: substep.type, overallStepNumber });
  }

  const nextSubstepIndex = selectNextSubstepIndex(getState());

  if (nextSubstepIndex) {
    dispatch(setCurrentSubstep(nextSubstepIndex));
  } else {
    dispatch(completeStep());
  }
};

/* Complete substep */
export const completeStep = (): AppThunk<void> => (dispatch, getState) => {
  const nextStepIndex = selectNextStepIndex(getState());

  if (nextStepIndex) {
    dispatch(setCurrentStep(nextStepIndex));
  } else {
    dispatch(completeTutor());
  }
};

/* Complete tutor */
export const completeTutor = (): AppThunk<void> => dispatch => {
  Analytics.track('ChatTutorial_Completed');
  dispatch(setIsCompleted());
};

/* Select tarot card */
export const generateRandomTarotCard = (): AppThunk<void> => async (dispatch, getState) => {
  const state = getState();
  const currentCard = state.astrologers.chatTutorial.tarotCard;

  if (!currentCard) {
    const tarotCardNames = await getAdvisorsTarotCardNames();
    const tarotCard = sample(tarotCardNames);
    dispatch(setTarotCard(tarotCard?.name || DEFAULT_CARD_NAME));
  }
};

/* Select random advisor */
export const generateRandomAdvisorFromAssistant = (): AppThunk<void> => async (dispatch, getState) => {
  const state = getState();
  const isChatAssistantMode = selectIsChatAssistantEnabled(state);

  if (isChatAssistantMode) {
    const availableAdvisors = selectOnlineAdvisors(state);
    const advisor = sample(availableAdvisors) || availableAdvisors[0];
    dispatch(setAdvisorFromAssistant(advisor));
  }
};

export const checkTutorStatus = (): AppThunk<void> => (dispatch, getState) => {
  const {
    astrologers: {
      chatTutorial: { isCompleted, advisorId },
    },
  } = getState();

  if (isCompleted && advisorId) {
    dispatch(setAdvisorId(null));
  }
};

export const checkAndOpenChatTutorial =
  (advisor: Astrologist, place: ASTROLOGERS_OPEN_PLACE): AppThunk<boolean> =>
  (dispatch, getState) => {
    const {
      remoteConfig: {
        chatTutorialConfig: { enabled },
      },
      astrologers: {
        chatTutorial: { isCompleted, advisorId },
      },
    } = getState();

    if (enabled && !isCompleted) {
      if (`${advisorId}` === `${advisor.astrologer_id}`) {
        dispatch(setAstrologistAction(advisor));
        navigate(ADVISORS_CHAT_TUTORIAL, { place });
        return true;
      }
    }
    return false;
  };

export const setSuggestOrQuestionData =
  (suggestOrUserQuestion: Question): AppThunk<void> =>
  (dispatch, getState) => {
    const advisorDefaultSuggestCategory = getState().remoteConfig.advisorDefaultSuggestCategory;

    const { text, metadata } = suggestOrUserQuestion as Question as ExtendedQuestion;

    dispatch(setMessageText(text));
    dispatch(setSuggestCategory(metadata?.category || ''));
    dispatch(setQuestionsCategoryAction(advisorDefaultSuggestCategory));
  };

/* Generate the first message from the advisor when entering the chat immediately after the assistant */
export const prepareAndStartRegularChatAfterAssistant = (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
  const state = getState();
  const advisorId = selectAdvisorFromAssistantProfileData(state).astrologer_id;
  const startChatMessage = selectStartChatMessageAfterAssistant(state);
  const rightHandData = selectRightHandData(state);
  const palmPhoto = state.astrologers.chatTutorial.palmPhotoUrl;
  const question = state.astrologers.chatTutorial.messageText;

  let metadata: ChatMetadata = {
    ...selectChatMetadata(state, advisorId),
    tm: true,
    palmReadingEnabled: false,
    sendPayments: false,
    isVoiceEnabled: false,
    place: ASTROLOGERS_OPEN_PLACE.CHAT_ASSISTANT,
  };

  if (palmPhoto) {
    const palmReadingMetadata = {
      palmImage: palmPhoto,
      type: 'palmScanFromTutor',
      palmStats: {
        overview: rightHandData?.overview.lines,
      },
    };

    metadata = {
      ...metadata,
      ...palmReadingMetadata,
    };
  }

  const { chat_id: chatId } =
    (await startChat({
      advisorId,
      message: startChatMessage,
      needResponse: false,
      metadata,
      ...getChatParams(),
    })) || ({} as StartChatResponse);

  if (!chatId) {
    return false;
  }

  dispatch(trackChatWithAstrologistEvent('Astrologist_Chat_Started', { advisorId, place: metadata.place, chatId }));

  dispatch(setCurrentChatId(chatId));
  await dispatch(getHistoryMessages(advisorId));

  if (palmPhoto) {
    const report = rightHandData?.astrologersMessage || '';
    await useTemplate(
      advisorId,
      TEMPLATE_IDS.PALM_WITH_QUESTION,
      {
        question,
        report,
      },
      true,
    );
  }

  /* Disable unlimited mode if there are no minutes on the balance */
  await modifyChatParams({
    advisorId,
    metadata: {
      ...selectChatMetadata(state, advisorId),
    },
  });

  return true;
};
