import type { ImageSourcePropType } from 'react-native';

import type { Astrologist } from 'api/astrology-chat/types';
import { PERIODS_IDS } from 'constants/periods';
import * as ROUTES from 'constants/routes';
import type { ImageMaps } from 'interfaces/image';

import ASTROLOGER_AVATAR_IMG_PERL from '../images/avatars/adalilin_perl.png';
import ASTROLOGER_AVATAR_IMG_KORNET from '../images/avatars/annegret_kornet.png';
import ASTROLOGER_AVATAR_IMG_GREEN from '../images/avatars/chloe_green.png';
import ASTROLOGER_AVATAR_IMG_ROSE from '../images/avatars/claudia_rose.png';
import ASTROLOGER_AVATAR_IMG_DANIELA from '../images/avatars/daniela.png';
import DEFAULT_ASTROLOGER_AVATAR_IMG from '../images/avatars/default.png';
import ASTROLOGER_AVATAR_IMG_WEBER from '../images/avatars/lili_weber.png';

export enum Astrologists {
  PERL = 'PERL',
  KORNET = 'KORNET',
  GREEN = 'GREEN',
  ROSE = 'ROSE',
  DANIELA = 'DANIELA',
  WEBER = 'WEBER',
}

export type AstrologistsInfo = {
  [key in Astrologists]: AstrologistInfo;
};

export const ASTROLOGISTS_AVATAR_MAP: ImageMaps = {
  [Astrologists.PERL]: ASTROLOGER_AVATAR_IMG_PERL,
  [Astrologists.KORNET]: ASTROLOGER_AVATAR_IMG_KORNET,
  [Astrologists.GREEN]: ASTROLOGER_AVATAR_IMG_GREEN,
  [Astrologists.ROSE]: ASTROLOGER_AVATAR_IMG_ROSE,
  [Astrologists.DANIELA]: ASTROLOGER_AVATAR_IMG_DANIELA,
  [Astrologists.WEBER]: ASTROLOGER_AVATAR_IMG_WEBER,
};

export interface AstrologistInfo {
  name: string;
  skills: string[];
  experience: string;
  phoneNumber: number;
  avatar: ImageSourcePropType;
  rating?: number;
}

export const DEFAULT_ASTROLOGIST_INFO: AstrologistInfo & Astrologist = {
  phoneNumber: 9379992,
  avatar: DEFAULT_ASTROLOGER_AVATAR_IMG,
  astrologer_id: 1,
  gender: 'Female',
  name: 'Charita',
  rating: 4.9,
  reviews: 121,
  age: 41,
  origin: 'Bilaspur, India',
  location: 'Seattle, United States',
  expertise: 'Energy & Spirituality',
  skills: ['Vedic astrology', 'Tarot reading', 'Energy healing', 'Numerology'],
  experience: '15 years',
  short_description: 'Over 15 years of practice',
  description: '',
  is_active: 1,
  is_premium: 0,
};

export const ASTROLOGISTS_INFO: AstrologistsInfo = {
  [Astrologists.PERL]: {
    name: 'Adalilin Perl',
    skills: ['Psychologist', 'Karmologist', 'Tarot reading', 'Astrologer', 'Healer'],
    experience: 'Over 10 years of practice',
    phoneNumber: 19294376891,
    avatar: ASTROLOGISTS_AVATAR_MAP[Astrologists.PERL],
    rating: 1,
  },
  [Astrologists.KORNET]: {
    name: 'Annegret Korner',
    skills: ['Yoga Master', 'Esoteric', 'Energy Practices', 'Karma Cleaning', 'Tarot reading', 'Astrologer', 'Shamanic healing'],
    experience: 'Over 6 years of practice',
    phoneNumber: 19292141758,
    avatar: ASTROLOGISTS_AVATAR_MAP[Astrologists.KORNET],
  },
  [Astrologists.GREEN]: {
    name: 'Chloe Green',
    skills: ['Psychic', 'Past life reading', 'Chakras healing', 'Shamanic healing', 'Energetic reading', 'Energy channeling'],
    experience: 'Over 13 years of practice',
    phoneNumber: 15057380579,
    avatar: ASTROLOGISTS_AVATAR_MAP[Astrologists.GREEN],
  },
  [Astrologists.ROSE]: {
    name: 'Claudia Rose',
    skills: ['Psychologist', 'Tarot reading', 'Astrologer', 'Palmist', 'Energy channeling'],
    experience: 'Over 10 years of practice',
    phoneNumber: 14708938643,
    avatar: ASTROLOGISTS_AVATAR_MAP[Astrologists.ROSE],
  },
  [Astrologists.DANIELA]: {
    name: 'Daniela',
    skills: ['Psychologist', 'Tarot reader', 'Astrologer'],
    experience: 'Over 10 years of practice',
    phoneNumber: 15054487839,
    avatar: ASTROLOGISTS_AVATAR_MAP[Astrologists.DANIELA],
  },
  [Astrologists.WEBER]: {
    name: 'Lili Weber',
    skills: ['Psychologist', 'Shamanic healing', 'Energy channeling', 'Tarot reading', 'Esotericist', 'Chakras healing', 'Past life reading'],
    experience: 'Over 7 years of practice',
    phoneNumber: 16575823797,
    avatar: ASTROLOGISTS_AVATAR_MAP[Astrologists.WEBER],
  },
};

export enum TEMPLATE_IDS {
  ASTROEVENT = 1,
  PALM_READING = 2, // used in chat start (palm from web)
  PALM_READING_IN_CHAT = 3, // new palm scan in chat
  TAROT_IN_TUTOR = 7, // for chat tutorial
  PALM_WITH_QUESTION = 'palm_with_question', // for palm after assistant
}

export enum ASTROLOGERS_OPEN_PLACE {
  ASTROEVENT = 'astroevent',
  NOTIFICATION = 'notification',
  PUSH_NOTIFICATION = 'push_notification',
  ASTROLOGERS_PAGE = 'astrologers_page',
  ADVISOR_PAGE = 'advisor_page',
  CROSS_PROMO_BANNER = 'cross_promo_banner',
  ADVISORS_ONBOARDING = 'advisors_onboarding',
  ADVISORS_CATALOG = 'advisors_catalog',
  WHERE_TO_NAVIGATE = 'where_to_navigate',
  RENEW = 'renew',
  LINK = 'link',
  ASTRO_CALENDAR = 'astro_calendar',
  CHAT = 'chat',
  CHATS = 'chats',
  READING_CHAT = 'reading_chat',
  READING_CHAT_WITH_INPUT = 'reading_chat_with_input',
  CHAT_TUTORIAL = 'chat_tutorial',
  CHAT_ASSISTANT = 'chat_assistant',
}

export enum MESSAGE_PLACE {
  TYPING = 'typing',
  TYPING_BEFORE_CHAT = 'typing_before_chat',
  TYPING_IN_TUTOR = 'typing_in_tutor',
  SUGGEST_BEFORE_CHAT = 'suggest_before_chat',
  CROSS_PROMO = 'cross_promo',
  SUGGEST_IN_CHAT = 'suggest_in_chat',
  SUGGEST_IN_TUTOR = 'suggest_in_tutor',
}

export enum ASTROLOGERS_PROMO_PLACES {
  DAILY_TIPS = 'daily_tips',
  COMPATIBILITY = 'compatibility',
  TRANSIT = 'transit',
  LUCKY_NUMBER = 'lucky_number',
  LUCKY_COLOR = 'lucky_color',
  LUCKY_TIME = 'lucky_time',
  BIORHYTHM = 'biorhythm',
  LUNAR_CALENDAR = 'lunar_calendar',
  TAROT = 'tarot',
  PLANETS = 'planets',
  HOUSES = 'houses',
  PALM_READING = 'palm_reading',
  ADVISOR_HOROSCOPE = 'advisor_horoscope',
}

type LUCKY_KEYS = 'NUMBER' | 'COLOR' | 'TIME';

type LUCKY_KEYS_MAP = {
  [key in LUCKY_KEYS]: ASTROLOGERS_PROMO_PLACES.LUCKY_COLOR | ASTROLOGERS_PROMO_PLACES.LUCKY_NUMBER | ASTROLOGERS_PROMO_PLACES.LUCKY_TIME;
};

export const LUCKY_FEATURES_KEYS_MAP: LUCKY_KEYS_MAP = {
  NUMBER: ASTROLOGERS_PROMO_PLACES.LUCKY_NUMBER,
  COLOR: ASTROLOGERS_PROMO_PLACES.LUCKY_COLOR,
  TIME: ASTROLOGERS_PROMO_PLACES.LUCKY_TIME,
};

export enum TOP_TAB_IDS {
  CHATS = 'chats',
  SERVICES = 'services',
  CHAT = 'chat',
  HOROSCOPE = 'horoscope',
  ABOUT = 'about',
  REVIEWS = 'reviews',
}

export const TOP_TABS: TOP_TAB_IDS[] = [TOP_TAB_IDS.CHATS, TOP_TAB_IDS.SERVICES];

export const TOP_TAB_IDS_BY_ROUTE: { [key in string]: TOP_TAB_IDS } = {
  [ROUTES.ADVISORS_TABS_CHATS]: TOP_TAB_IDS.CHATS, // TODO: Need remove after refactoring
  [ROUTES.ADVISORS_TABS_SERVICES]: TOP_TAB_IDS.SERVICES, // TODO: Need remove after refactoring
  [ROUTES.ADVISOR_TAB_CHAT]: TOP_TAB_IDS.CHAT,
  [ROUTES.ADVISOR_TAB_HOROSCOPE]: TOP_TAB_IDS.HOROSCOPE,
  [ROUTES.ADVISOR_TAB_ABOUT]: TOP_TAB_IDS.ABOUT,
  [ROUTES.ADVISOR_TAB_REVIEWS]: TOP_TAB_IDS.REVIEWS,
};

export const TOP_TABS_ROUTES: { [key in TOP_TAB_IDS]?: string } = {
  [TOP_TAB_IDS.CHATS]: ROUTES.ADVISORS_TABS_CHATS,
  [TOP_TAB_IDS.SERVICES]: ROUTES.ADVISORS_TABS_SERVICES,
};

export const ADVISOR_PAGE_TOP_TABS_ROUTES: { [key in TOP_TAB_IDS]?: string } = {
  [TOP_TAB_IDS.CHAT]: ROUTES.ADVISOR_TAB_CHAT,
  [TOP_TAB_IDS.HOROSCOPE]: ROUTES.ADVISOR_TAB_HOROSCOPE,
  [TOP_TAB_IDS.ABOUT]: ROUTES.ADVISOR_TAB_ABOUT,
  [TOP_TAB_IDS.REVIEWS]: ROUTES.ADVISOR_TAB_REVIEWS,
};

export const MAX_NUMBER_OF_REVIEWS = 20;

export type AdvisorHoroscopePeriods = PERIODS_IDS.TODAY | PERIODS_IDS.WEEK | PERIODS_IDS.MONTH | PERIODS_IDS.YEAR;

export const ADVISOR_HOROSCOPES_PERIODS: AdvisorHoroscopePeriods[] = [PERIODS_IDS.TODAY, PERIODS_IDS.WEEK, PERIODS_IDS.MONTH, PERIODS_IDS.YEAR];
