import api from 'api';

import { READ_MESSAGES } from './constants';
import type { Astrologist } from './types';

const readMessages = async (id: Astrologist['astrologer_id']): Promise<null> => {
  try {
    return await api.fetch(READ_MESSAGES, {
      method: 'POST',
      data: {
        astrologer_id: id,
      },
    });
  } catch (error) {
    console.log('ERROR [Astrologist chat] readMessages', error);
    return null;
  }
};

export default readMessages;
