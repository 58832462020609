import _ from 'lodash';
import { createAction } from 'redux-actions';

import Analytics from 'analytics';
import type { EventName } from 'analytics/types';
import { sendAstrologersQuestion } from 'api/astrologers';
import type { AdvisorNotificationCategories } from 'api/astrologers/interfaces';
import { startChat, addMessage, getHistory, getChats, pingChat, readMessages } from 'api/astrology-chat';
import type { Astrologist, Chats, HistoryItem } from 'api/astrology-chat/types';
import useTemplate from 'api/astrology-chat/use-template';
import type { StatusesRetrieveData } from 'api/events-data/types';
import * as TRIGGERS from 'constants/monetization-triggers';
import { ADVISOR_PAGE, ADVISORS, ADVISORS_CONNECTION, MAIN } from 'constants/routes';
import { t, t2 } from 'localization';
import { ASTROLOGERS_OPEN_PLACE, ASTROLOGERS_PROMO_PLACES, TEMPLATE_IDS, MESSAGE_PLACE } from 'screens/advisors/constants';
import { ASTROLOGERS_TRIGGERS_TYPES } from 'screens/advisors/screens/monetization/constants/interfaces';
import type { AppDispatch, AppGetState, AppThunk } from 'store';
import { astrologersMonetizationHandler } from 'store/astrologers/monetization/actions';
import { back, canGoBack, navigate, reset } from 'store/navigation/actions';
import { selectRightHandData } from 'store/palm-reading-daily/selectors';
import { getAstroeventMessage } from 'store/transits/selectors';
import { navigateWithMonetization } from 'store/unlock-content/actions';

import { setAdvisorPalmReadingData, setLastConnectionSessionNumber } from '../core/actions';
import { QUESTION_TYPES } from '../core/types';
import type { Question } from '../core/types';
import { setAstroeventCounters, showAutoRefillModal } from '../modals/actions';
import { setIsPressed as setNotificationPressed } from '../notifications';
import { showProactiveNotificationMessage, resetAdvisorNotifications } from '../notifications/thunks';
import type { ProactiveNotification } from '../notifications/types';
import { setCompleted } from '../onboarding/actions';
import { getAstrologistDataById, getPalmReadingMetadata, selectAdvisorForCrossPromo, selectIsPremiumWithoutForcedOnlineAdvisor } from '../selectors';
import { setActiveServicesTab } from '../services/actions';
import { setChatTimer, setTimePaused, setTimerTooltipVisibility } from '../time/actions';

import { selectChatMetadata } from './selectors';
import { TYPES } from './types';
import type {
  SetChatReviewCompleted,
  SetChatMessages,
  SetUnreadMessagesCounter,
  SetTypingIds,
  SetRecordingIds,
  SetCurrentChatId,
  SetCurrentChats,
  SetHiddenMessage,
  SetQuestion,
  SetMessagePlace,
  SetCurrentAstrologerId,
  SetIsPalmReadingOffer,
  SetPalmPhoto,
  SetSuggestsVisible,
  SetWasSessionConversation,
  SetReviewModalVisible,
  SetPlayingVoiceId,
  SetForcedOnlineAdvisorId,
  HideSoftCurrencyRateInChat,
  SetForceTyping,
  ResetAllChats,
} from './types.ts';
import {
  convertMessagesToHistory,
  checkMessageMetadataType,
  appendMessageToHistory,
  getHistoryItemTemplate,
  getChatParams,
  trackSpecificMessageEvent,
  checkIsProactiveMessageType,
  isArraysDifferent,
} from './utils';

const resetAllChats = createAction<ResetAllChats['payload']>(TYPES.RESET_ALL_CHATS);
const setCurrentChats = createAction<SetCurrentChats['payload']>(TYPES.SET_CURRENT_CHATS);
const setUnreadChatCount = createAction<SetUnreadMessagesCounter['payload']>(TYPES.SET_UNREAD_CHAT_COUNT);
const setTypingIds = createAction<SetTypingIds['payload']>(TYPES.SET_TYPING_IDS);
const setRecordingIds = createAction<SetRecordingIds['payload']>(TYPES.SET_RECORDING_IDS);
export const setHiddenMessage = createAction<SetHiddenMessage['payload']>(TYPES.SET_HIDDEN_MESSAGE);
export const setChatMessages = createAction<SetChatMessages['payload']>(TYPES.SET_CHAT_MESSAGES);
export const setCurrentChatId = createAction<SetCurrentChatId['payload']>(TYPES.SET_CURRENT_CHAT_ID);
export const setQuestionAction = createAction<SetQuestion['payload']>(TYPES.SET_QUESTION);
export const setChatReviewCompleted = createAction<SetChatReviewCompleted['payload']>(TYPES.SET_CHAT_REVIEW_COMPLETED);
export const setMessagePlace = createAction<SetMessagePlace['payload']>(TYPES.SET_MESSAGE_PLACE);
export const setCurrentAstrologerId = createAction<SetCurrentAstrologerId['payload']>(TYPES.SET_CURRENT_ASTROLOGER_ID);
export const setIsPalmReadingOffer = createAction<SetIsPalmReadingOffer['payload']>(TYPES.SET_IS_PALM_READING_OFFER);
export const setPalmPhoto = createAction<SetPalmPhoto['payload']>(TYPES.SET_PALM_PHOTO);
export const setSuggestsVisible = createAction<SetSuggestsVisible['payload']>(TYPES.SET_SUGGESTS_VISIBLE);
const setWasSessionConversation = createAction<SetWasSessionConversation['payload']>(TYPES.SET_WAS_SESSION_CONVERSATION);
export const setPlayingVoiceId = createAction<SetPlayingVoiceId['payload']>(TYPES.SET_PLAYING_VOICE_ID);
export const setReviewModalVisible = createAction<SetReviewModalVisible['payload']>(TYPES.SET_REVIEW_MODAL_VISIBLE);
export const setForcedOnlineAdvisorId = createAction<SetForcedOnlineAdvisorId['payload']>(TYPES.SET_FORCED_ONLINE_ADVISOR_ID);
export const hideSoftCurrencyRateInChat = createAction<HideSoftCurrencyRateInChat['payload']>(TYPES.HIDE_SOFT_CURRENCY_RATE_IN_CHAT);
export const setForceTyping = createAction<SetForceTyping['payload']>(TYPES.SET_FORCE_TYPING);

const startTemplateById = (chatId: Astrologist['astrologer_id'], templateId: TEMPLATE_IDS, useDelay = false, hiddenMessage = '') => {
  return async (_dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const params: { [key: string]: string } = {};

    if (templateId === TEMPLATE_IDS.ASTROEVENT) {
      const transit = getAstroeventMessage(state);
      params.transit = transit;
    }

    await useTemplate(chatId, templateId, params, useDelay, hiddenMessage);
  };
};

const trackSentMessageEvent = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        chat: { messagePlace, currentChatId, currentAstrologerId },
      },
      profile: {
        profileData: { id },
      },
    } = getState();

    let place = MESSAGE_PLACE.TYPING;

    if (messagePlace) {
      place = messagePlace;

      dispatch(setMessagePlace(null));
    }

    Analytics.trackEvent('Astrologist', 'Chat_Message_Sent', {
      place,
      chatId: currentChatId,
      astrologerId: currentAstrologerId,
      userId: id,
    });
  };
};

export const trackChatWithAstrologistEvent = (
  eventName: EventName,
  params: {
    advisorId: Astrologist['astrologer_id'];
    place: ASTROLOGERS_OPEN_PLACE | 'other';
    chatId: number | null;
    pushNotification?: string;
    mode?: 'read' | 'chat';
  },
) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();

    const {
      astrologers: {
        notifications: { isPressed: isNotificationPressed, notification },
        core: { allAstrologers },
      },
      profile: {
        profileData: { id, device_id },
      },
    } = state;

    const { advisorId, pushNotification, place, chatId, mode } = params;
    const isFromPushNotification = !!pushNotification;

    let notificationCategory: AdvisorNotificationCategories | 'none' = 'none';

    if (isFromPushNotification) {
      notificationCategory = 'general';
    }

    if (!_.isEmpty(notification) && isNotificationPressed) {
      notificationCategory = notification.category;
      dispatch(setNotificationPressed(false));
    }

    const isPremium = !!allAstrologers.find(item => `${item.astrologer_id}` === `${advisorId}`)?.is_premium;

    Analytics.track(eventName, {
      astrologerId: advisorId,
      userId: id,
      idfm: device_id,
      chatId: chatId || 'not_created',
      fromNotification: isNotificationPressed,
      isFromPushNotification,
      place: place ? place : 'other',
      notificationCategory,
      isPremium,
      ...(mode && { mode }),
    });
  };
};

export const startChatWithAstrologist = (
  advisorId: Astrologist['astrologer_id'],
  message: HistoryItem,
  needResponse?: boolean,
  templateId?: TEMPLATE_IDS,
  place?: ASTROLOGERS_OPEN_PLACE,
  pushNotification?: string,
) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const {
      astrologers: {
        modals: {
          astroeventCounters: { resetCount },
        },
        time: { isTimeUp },
      },
    } = state;

    dispatch(setTimePaused(false));
    dispatch(resetAdvisorNotifications());

    if (!isTimeUp) {
      dispatch(setQuestionAction(null));
    }

    let startChatMessage = '';
    let metadata = selectChatMetadata(state, advisorId);

    if (place) {
      metadata = {
        ...metadata,
        place,
      };
    }

    const isFromPushNotification = !!pushNotification;
    if (isFromPushNotification) {
      startChatMessage = pushNotification;
      metadata = {
        ...metadata,
        notificationCategory: 'general',
      };
    }

    let hiddenMessage;
    if (templateId === TEMPLATE_IDS.PALM_READING) {
      const [palmReadingStartMessage, palmReadingMetadata] = getPalmReadingMetadata(getState(), advisorId);
      hiddenMessage = palmReadingMetadata.hiddenMessage;
      dispatch(setHiddenMessage(hiddenMessage));
      if (palmReadingStartMessage) {
        startChatMessage = palmReadingStartMessage;
        metadata = {
          ...metadata,
          ...palmReadingMetadata,
        };

        dispatch(setAdvisorPalmReadingData(null));
      }
    }

    dispatch(setSuggestsVisible(!message?.message?.length));

    const { chat_id: chatId } =
      (await startChat({
        advisorId,
        message: startChatMessage,
        needResponse,
        metadata: metadata,
        ...getChatParams(),
      })) || {};

    if (!chatId) {
      return;
    }

    dispatch(setCurrentChatId(chatId));

    if (templateId) {
      const isDelayed = !!startChatMessage?.length;
      dispatch(startTemplateById(advisorId, templateId, isDelayed, hiddenMessage));
    }

    dispatch(trackChatWithAstrologistEvent('Astrologist_Chat_Started', { advisorId, place: metadata.place, chatId, pushNotification }));

    await dispatch(getHistoryMessages(advisorId));

    const sessionNumber = (Analytics.getSessionNumber() ?? 0) + 1;
    dispatch(setLastConnectionSessionNumber(sessionNumber));

    const hasChatResetShow = getState().remoteConfig.astroeventModalConfig?.hasChatResetShow;

    dispatch(
      setAstroeventCounters({
        resetCount: resetCount <= hasChatResetShow ? resetCount + 1 : resetCount,
        withConnections: 0,
        withoutConnections: 0,
      }),
    );

    if (isTimeUp) {
      dispatch(showAutoRefillModal());
      return false;
    }

    const isChatHaveUnreadMessages = !!getState().astrologers.chat.chats[advisorId]?.messages?.find(mess => mess?.is_viewed_by_user);

    if (isChatHaveUnreadMessages) {
      await dispatch(setMessagesRead(advisorId));
    }

    if (message.message?.length) {
      dispatch(addNewMessage({ advisorId, message }));

      setTimeout(() => {
        dispatch(setLastMessageRead(advisorId));
      }, 1000);
    }
  };
};

export const sendPalmToAdvisor = (advisorId: Astrologist['astrologer_id']) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const palmPhoto = state.astrologers.chat.palmPhoto;
    const rightHandAdvisorData = selectRightHandData(state);

    const hiddenMessage = rightHandAdvisorData?.astrologersMessage;
    const message = getHistoryItemTemplate({
      is_viewed: true,
      metadata: {
        hiddenMessage,
        type: 'palmScanData',
        url: palmPhoto,
      },
    });
    await useTemplate(advisorId, TEMPLATE_IDS.PALM_READING_IN_CHAT, {}, true, hiddenMessage);
    dispatch(addNewMessage({ advisorId, message }));
    dispatch(setPalmPhoto(''));
  };
};

export const addNewMessage = (params: { advisorId: Astrologist['astrologer_id']; message: HistoryItem; needAnswer?: boolean }) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const { advisorId, message, needAnswer = true } = params;
    const state = getState();

    const {
      astrologers: {
        chat: { chats, hiddenMessage: hiddenMessageText },
      },
    } = state;

    const history = chats[advisorId]?.messages || [];
    const messages = appendMessageToHistory({ message, history });

    dispatch(
      setChatMessages({
        advisorId,
        messages,
      }),
    );

    trackSpecificMessageEvent(message);
    dispatch(setWasSessionConversation(true));

    const { type, message: messageText, metadata } = message;

    /* Send user message to server */
    if (type === 'question') {
      addMessage({
        advisorId,
        messageText,
        hiddenMessageText,
        needAnswer,
        metadata,
      });

      dispatch(trackSentMessageEvent());
    }

    /* Handle palm request message */
    if (type === 'answer' && checkMessageMetadataType(message, 'palmRequest')) {
      dispatch(setTimePaused(true));
      dispatch(setIsPalmReadingOffer(true));
    }

    /* Show proactive advisor notification*/
    if (metadata?.type && checkIsProactiveMessageType(metadata.type)) {
      const notificationParams: ProactiveNotification = {
        advisorId,
        message_id: message.id,
        type: metadata.type,
        message: message.message,
        created_at: message.created_at,
        category: metadata?.category || 'none',
      };

      dispatch(showProactiveNotificationMessage(notificationParams));
    }
  };
};

export const getHistoryMessages = (advisorId: Astrologist['astrologer_id']) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: { receiveTarot },
    } = getState();
    const response = await getHistory(advisorId, !!receiveTarot);

    if (!response) {
      dispatch(setChatMessages({ advisorId, messages: [] }));
      return false;
    }

    const messages = response?.length ? convertMessagesToHistory(response) : [];

    dispatch(setChatMessages({ advisorId, messages }));

    return true;
  };
};

export const deleteChatById = (chatId: string) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        chat: { chats },
      },
    } = getState();
    const chatIds = Object.keys(chats);
    const chatIndexForDeletion = chatIds.findIndex((id: string) => chatId === id);

    if (chatIndexForDeletion !== -1) {
      const updatedChats = chatIds.splice(chatIndexForDeletion, 1);
      updatedChats.forEach((id: string) => {
        dispatch(
          setChatMessages({
            advisorId: id,
            messages: chats[id]?.messages,
          }),
        );
      });

      return true;
    }

    return false;
  };
};

export const getLastMessages = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        chat: { chats },
      },
    } = getState();
    const chatIds = Object.keys(chats);
    const response = await getChats();

    dispatch(setCurrentChats(response));

    if (!chatIds.length && !response.length) {
      return false;
    }

    if (chatIds.length && !response.length) {
      dispatch(resetAllChats());

      return false;
    }

    const responseIds = response.map(({ astrologer_id }) => astrologer_id);
    const chatsForDeletion = chatIds.filter(id => !responseIds?.includes?.(`${id}`));
    chatsForDeletion.forEach(id => {
      dispatch(deleteChatById(id));
    });

    response.forEach((chat: Chats) => {
      const id = chat.astrologer_id;
      const storedChat = chats[id];

      if (!storedChat) {
        return dispatch(
          setChatMessages({
            advisorId: id,
            messages: [chat.lastMessage],
          }),
        );
      }

      const lastStoredMessage = storedChat?.messages?.[0]?.message;

      if (lastStoredMessage && chat.lastMessage.message !== lastStoredMessage) {
        dispatch(
          setChatMessages({
            advisorId: id,
            messages: [chat.lastMessage, ...storedChat.messages],
          }),
        );
      }
    });
  };
};

export const setLastMessageRead = (advisorId: Astrologist['astrologer_id']) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const messages = getState().astrologers.chat.chats[advisorId].messages.map(message => {
      if (+message?.is_viewed === 0 && message.type === 'question') {
        message.is_viewed = true;
      }

      return message;
    });

    dispatch(setChatMessages({ advisorId, messages }));
  };
};

export const pingChatById = (chatId: Astrologist['astrologer_id']) => {
  return async () => {
    await pingChat(chatId);
  };
};

export const countUnreadMessages = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        chat: { chats },
      },
      remoteConfig: {
        chatAssistantConfig: { hiddenAdvisorId },
      },
    } = getState();
    const chatsIds = Object.keys(chats).filter(id => id !== `${hiddenAdvisorId}`);

    let unreadMessagesCounter = 0;

    if (chatsIds.length) {
      chatsIds.forEach((id: string) => {
        const unreadMessages = chats[id].messages?.filter(message => !message?.is_viewed_by_user && message.type === 'answer') || [];
        unreadMessagesCounter += unreadMessages.length;
      });
    }

    dispatch(setUnreadChatCount(unreadMessagesCounter));
  };
};

export const submitQuestion = (question: Question): AppThunk => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setQuestionAction(question));
      await sendAstrologersQuestion(question.text);
    } catch (error) {
      console.log('[SUBMIT QUESTION ERROR]', error);
    }
  };
};

export const startAstrologersPromo = (
  place: ASTROLOGERS_PROMO_PLACES,
  additionParams: {
    taroCard?: string;
    message: string;
    period?: string;
  },
  astrologistId?: Astrologist['astrologer_id'],
) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const availableAstrologist = selectAdvisorForCrossPromo(state);
    const currentAstrologist = astrologistId ? getAstrologistDataById(state, astrologistId) : null;
    const consultant = currentAstrologist ? currentAstrologist : availableAstrologist;

    const isPremium = selectIsPremiumWithoutForcedOnlineAdvisor(state, consultant);

    const sectionName = t2(`ASTROLOGER_PROMO.SECTIONS_NAMES.${place.toUpperCase()}`);
    let question: Question = {
      text: t('ASTROLOGER_PROMO.MESSAGE', { sectionName }),
      metadata: {
        type: QUESTION_TYPES.ASTROLOGERS_PROMO,
      },
    };

    if (place === ASTROLOGERS_PROMO_PLACES.TAROT) {
      question = {
        ...question,
        text: `${t('ASTROLOGER_PROMO.MESSAGE_TAROT')} ${additionParams?.taroCard || ''}`,
      };
    }

    if (place === ASTROLOGERS_PROMO_PLACES.ADVISOR_HOROSCOPE) {
      question = {
        ...question,
        text: t('ASTROLOGER_PROMO.MESSAGE_HOROSCOPE', {
          period: additionParams?.period || '',
        }),
      };
    }

    dispatch(setMessagePlace(MESSAGE_PLACE.CROSS_PROMO));

    dispatch(setHiddenMessage(additionParams.message));
    dispatch(submitQuestion(question));

    dispatch(setCompleted());

    const route = ADVISORS_CONNECTION;
    const params = {
      id: consultant.astrologer_id,
      place: ASTROLOGERS_OPEN_PLACE.CROSS_PROMO_BANNER,
    };

    if (isPremium) {
      dispatch(
        navigateWithMonetization({
          trigger: TRIGGERS.PREMIUM_ADVISOR_START_CHAT,
          route,
          params,
        }),
      );

      return;
    }

    navigate(route, params);
  };
};

export const setMessagesRead = (advisorId: Astrologist['astrologer_id']) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        chat: { chats },
      },
    } = getState();
    let messages = chats[advisorId]?.messages;

    if (!Array.isArray(messages)) {
      messages = [];
    }

    readMessages(advisorId);

    messages?.forEach(message => {
      if (typeof message === 'object') {
        message.is_viewed_by_user = true;
      }
    });

    dispatch(setChatMessages({ advisorId, messages }));
    dispatch(countUnreadMessages());
  };
};

export const navigateAfterEndChat = (place: ASTROLOGERS_OPEN_PLACE) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig,
      astrologers: {
        chat: { wasSessionConversation },
      },
    } = getState();

    const isServicesEnabled = remoteConfig.advisorsServicesConfig?.enabled;
    const isNavigateToServices = remoteConfig.advisorsServicesConfig?.navigateAfterEndChatEnabled;

    if (isServicesEnabled && isNavigateToServices && wasSessionConversation) {
      dispatch(setActiveServicesTab(true));
    }

    if (place === ASTROLOGERS_OPEN_PLACE.WHERE_TO_NAVIGATE || place === ASTROLOGERS_OPEN_PLACE.READING_CHAT_WITH_INPUT) {
      return reset(MAIN);
    }

    if (place === ASTROLOGERS_OPEN_PLACE.CHAT || place === ASTROLOGERS_OPEN_PLACE.READING_CHAT) {
      return navigate(ADVISOR_PAGE);
    }

    if (place === ASTROLOGERS_OPEN_PLACE.ASTROEVENT) {
      return reset(MAIN, { initial: false, screen: ADVISORS });
    }

    if (canGoBack()) {
      back();
    } else {
      reset(MAIN, { initial: false, screen: ADVISORS });
    }
  };
};

export const endChat = (params: { id: Astrologist['astrologer_id']; isReviewAvailable: boolean; place: ASTROLOGERS_OPEN_PLACE }) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        chat: { chats },
      },
      remoteConfig: {
        astrologistConfig: { enableAstrologistReview: isAstrologistReviewEnabled },
        enableIncreasingTimer,
      },
    } = getState();

    const { id, isReviewAvailable, place } = params;

    const isReviewCompleted = chats[id]?.isReviewCompleted || false;

    dispatch(setHiddenMessage(''));
    dispatch(setTimePaused(true));
    dispatch(setTimerTooltipVisibility(false));
    dispatch(resetAdvisorNotifications());

    const handleSkip = () => {
      const activeModal = getState().modals.activeModal;

      if (enableIncreasingTimer) {
        dispatch(setChatTimer(0));
      }

      if (!activeModal && isAstrologistReviewEnabled && !isReviewCompleted && isReviewAvailable) {
        dispatch(setReviewModalVisible(true));
      } else {
        dispatch(navigateAfterEndChat(place));
        dispatch(setCurrentChatId(0));
      }
    };

    const handleSuccess = () => {
      dispatch(setTimePaused(false));
    };

    dispatch(
      astrologersMonetizationHandler({
        trigger: ASTROLOGERS_TRIGGERS_TYPES.END_CHAT,
        onSkip: handleSkip,
        onSuccess: handleSuccess,
      }),
    );
  };
};

export const updateChatInteractionStatus = (statuses: StatusesRetrieveData) => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        chat: { typingIds, recordingIds },
      },
    } = getState();

    const chatsTypingIds = statuses.chatsTyping;
    const chatsRecordingIds = statuses.chatsRecording;

    if (isArraysDifferent(typingIds, chatsTypingIds)) {
      dispatch(setTypingIds(chatsTypingIds));
    }

    if (isArraysDifferent(recordingIds, chatsRecordingIds)) {
      dispatch(setRecordingIds(chatsRecordingIds));
    }
  };
};
