import type { Astrologist } from 'api/astrology-chat/types';

import type { TutorStep } from '../chat-tutorial/types';

export const ASSISTANT_CHAT_STEPS: TutorStep[] = [
  {
    id: 'greeting',
    substeps: [
      {
        type: 'assistantText',
        autorun: true,
        contentKey: 'chatAssistant.steps.greeting.msg1',
      },
      {
        type: 'userText',
        autorun: false,
        contentKey: 'chatAssistant.steps.greeting.msg2',
      },
    ],
  },
  {
    id: 'tarot',
    substeps: [
      {
        type: 'assistantText',
        autorun: true,
        contentKey: 'chatAssistant.steps.tarot.msg1',
      },
      {
        type: 'userText',
        autorun: false,
        contentKey: 'chatAssistant.steps.tarot.msg2',
      },
      {
        type: 'assistantText',
        autorun: true,
        contentKey: 'chatAssistant.steps.tarot.msg3',
      },
      {
        type: 'tarotCard',
        autorun: true,
        imageURL: '/uploads/astrologers_tarot/2024-01-25/0902177963066e971f024a798accf6a6.png',
      },
      {
        type: 'assistantText',
        autorun: true,
        contentKey: 'chatAssistant.steps.tarot.msg4',
      },
      {
        type: 'userText',
        autorun: false,
        contentKey: 'chatAssistant.steps.tarot.msg5',
      },
    ],
  },
  {
    id: 'suggests',
    substeps: [
      {
        type: 'assistantText',
        autorun: true,
        contentKey: 'chatAssistant.steps.suggests.msg1',
      },
      {
        type: 'suggest',
        autorun: false,
      },
    ],
  },
  {
    id: 'palmReading',
    substeps: [
      {
        type: 'assistantText',
        autorun: true,
        contentKey: 'chatAssistant.steps.palmReading.msg1',
      },
      {
        type: 'takePalmPhoto',
        autorun: false,
      },
    ],
  },
  {
    id: 'palmReadingFail',
    substeps: [
      {
        type: 'assistantText',
        autorun: true,
        contentKey: 'chatAssistant.steps.palmReadingFail.msg1',
      },
    ],
  },
  {
    id: 'palmReadingSuccess',
    substeps: [
      {
        type: 'assistantText',
        autorun: true,
        contentKey: 'chatAssistant.steps.palmReadingSuccess.msg1',
      },
    ],
  },
  {
    id: 'finish',
    substeps: [
      {
        type: 'advisorFromAssistant',
        autorun: true,
        contentKey: 'chatAssistant.steps.finish.msg1',
      },
      {
        type: 'endTutorial',
        autorun: true,
      },
    ],
  },
];

export const ADVISOR_ASSISTANT: Astrologist = {
  age: 28,
  astrologer_id: 93,
  experience: '6 years experience',
  expertise: '',
  gender: 'Female',
  location: 'Austin, United States',
  name: 'Christine',
  origin: 'San Jose, United States',
  rating: 4.93,
  reviews: 213,
  description: '',
  short_description: 'Over 6 years of practice',
  skills: [],
  avatar: require('./img/manager.webp'),
  is_active: 1,
  is_premium: 0,
};
