import { fs, paddingVertical } from '@wowmaking/ui/src/utils';
import React, { memo } from 'react';
import type { FC } from 'react';
import { StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';

import ICON from 'assets/icons/attention/attention.png';
import Text from 'components/text';
import { DARK_TEAL } from 'constants/colors';
import { t2 } from 'localization';
import type { AppDispatch } from 'store';
import { removeTriggerNotifications } from 'store/astrologers/notifications/thunks';

import BaseTriggerNotification from './base';

import { EXTERNAL_TRIGGER_NOTIFICATIONS } from './index';

interface Props {}

const PaymentFailed: FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();

  const handleClose = () => {
    dispatch(removeTriggerNotifications([EXTERNAL_TRIGGER_NOTIFICATIONS.PAYMENT_FAILED]));
  };

  return (
    <BaseTriggerNotification icon={ICON} onPress={handleClose} onClose={handleClose} type={EXTERNAL_TRIGGER_NOTIFICATIONS.PAYMENT_FAILED}>
      <View style={styles.wrap}>
        <Text style={styles.title}>{t2('TRIGGER_NOTIFICATION.PAYMENT_FAILED.TITLE')}</Text>
        <Text style={styles.text}>{t2('TRIGGER_NOTIFICATION.PAYMENT_FAILED.TEXT')}</Text>
      </View>
    </BaseTriggerNotification>
  );
};

export default memo(PaymentFailed);

const styles = StyleSheet.create({
  wrap: {
    flex: 1,
  },
  title: {
    fontSize: fs(13),
    lineHeight: fs(18),
    fontWeight: '700',
    color: DARK_TEAL,
  },
  text: {
    marginTop: paddingVertical(2),
    fontSize: fs(13),
    fontWeight: '500',
    color: DARK_TEAL,
    lineHeight: fs(18),
  },
});
