export enum NOTIFICATIONS {
  PWA_INSTRUCTIONS = 'PWA_INSTRUCTIONS',
  PERSONAL_REPORTS = 'PERSONAL_REPORTS',
  WHATS_APP = 'WHATS_APP',
  MESSENGERS = 'MESSENGERS',
  ADVISOR_HOROSCOPE = 'ADVISOR_HOROSCOPE',
  PROACTIVE = 'PROACTIVE',
  PROACTIVE_ALTERNATE = 'PROACTIVE_ALTERNATE',
}

export type AdvisorNotificationTypes = NOTIFICATIONS.PROACTIVE | NOTIFICATIONS.PROACTIVE_ALTERNATE;
