import type { Astrologist } from 'api/astrology-chat/types';

interface ChatTutorialState {
  isStarted: boolean;
  isCompleted: boolean;
  isBusy: boolean;
  currentStep: number;
  currentSubstep: number;
  advisorId: Astrologist['astrologer_id'] | null;
  advisorFromAssistant: Astrologist | null;
  messageText: string;
  suggestCategory: string;
  tarotCard: string;
  showPalmScan: boolean;
  palmPhotoUrl: string | null;
  debug: boolean;
}

export const initialState: ChatTutorialState = {
  isStarted: false,
  isCompleted: false,
  isBusy: false,
  currentStep: 0,
  currentSubstep: 0,
  advisorId: null,
  advisorFromAssistant: null,
  messageText: '',
  suggestCategory: '',
  tarotCard: '',
  showPalmScan: false,
  palmPhotoUrl: null,
  debug: false,
};
