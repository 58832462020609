import api from 'api';
import { fixBooleanParams } from 'utils/api';

import { ADD_MESSAGE } from './constants';
import type { HistoryItemMetadata, Astrologist } from './types';

interface AddMessageParams {
  advisorId: Astrologist['astrologer_id'];
  messageText: string;
  hiddenMessageText?: string;
  needAnswer?: boolean;
  fromAstrologer?: boolean;
  metadata?: HistoryItemMetadata;
}

const addMessage = async (params: AddMessageParams): Promise<void> => {
  const { advisorId, messageText, hiddenMessageText = '', needAnswer = true, fromAstrologer = false, metadata = {} } = params;

  const data = fixBooleanParams({
    astrologer_id: advisorId,
    message: messageText,
    hiddenMessage: hiddenMessageText,
    needAnswer,
    fromAstrologer,
    metadata,
  });

  try {
    await api.fetch(ADD_MESSAGE, {
      method: 'POST',
      data,
    });
  } catch (error) {
    console.log('ERROR [Astrologist chat] addMessage', error);
  }
};

export default addMessage;
