import { useCallback, useEffect } from 'react';

import getEventsRetrieveData from 'api/events-data';
import { CHAT_ROUTES } from 'constants/routes';
import { useAppDispatch, useAppSelector } from 'store';
import { addNewMessage, countUnreadMessages, setMessagesRead, updateChatInteractionStatus } from 'store/astrologers/chat/actions';

const useEventsRetrieve = () => {
  const dispatch = useAppDispatch();
  const currentRoute = useAppSelector(state => state.navigation.currentRoute);
  const currentAstrologerId = useAppSelector(state => state.astrologers.chat.currentAstrologerId);

  const isChatRoute = CHAT_ROUTES.includes(currentRoute);
  const pingInterval = isChatRoute ? 1000 : 5000;

  const requestEventsData = useCallback(async () => {
    const response = await getEventsRetrieveData();
    const events = response.events;

    if (events.length) {
      events.forEach(event => {
        switch (event.type) {
          case 'chatMessage': {
            const advisorId = event.payload.advisor_id;
            const message = event.payload.message;
            console.warn('!__message', message); // TODO: need remove
            dispatch(addNewMessage({ advisorId, message }));

            if (isChatRoute && currentAstrologerId === advisorId) {
              dispatch(setMessagesRead(advisorId));
            } else {
              dispatch(countUnreadMessages());
            }
            break;
          }

          case 'proactiveMessageFailed': {
            console.log('proactiveMessageFailed');
            break;
          }
        }
      });
    }

    dispatch(updateChatInteractionStatus(response.statuses));
  }, [currentAstrologerId, dispatch, isChatRoute]);

  useEffect(() => {
    const interval = setInterval(() => {
      requestEventsData();
    }, pingInterval);

    return () => {
      clearInterval(interval);
    };
  }, [pingInterval, requestEventsData]);
};

export default useEventsRetrieve;
