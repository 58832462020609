import type { TutorStep, TutorStepId, TutorSuggestCategory } from './types';

export const TUTOR_STEPS: TutorStep[] = [
  {
    id: 'greeting',
    substeps: [
      {
        type: 'assistantText',
        autorun: true,
        contentKey: 'chatTutor.steps.greeting.msg1',
      },
      {
        type: 'userText',
        autorun: false,
        contentKey: 'chatTutor.steps.greeting.msg2',
      },
    ],
  },
  {
    id: 'beforeSuggests',
    substeps: [
      {
        type: 'assistantText',
        autorun: true,
        contentKey: 'chatTutor.steps.beforeSuggests.msg1',
      },
      {
        type: 'userText',
        autorun: false,
        contentKey: 'chatTutor.steps.beforeSuggests.msg2',
      },
    ],
  },
  {
    id: 'suggests',
    substeps: [
      {
        type: 'assistantText',
        autorun: true,
        contentKey: 'chatTutor.steps.suggests.msg1',
      },
      {
        type: 'suggest',
        autorun: false,
      },
    ],
  },
  {
    id: 'tarot',
    substeps: [
      {
        type: 'assistantText',
        autorun: true,
        contentKey: 'chatTutor.steps.tarot.msg1',
      },
      {
        type: 'userText',
        autorun: false,
        contentKey: 'chatTutor.steps.tarot.msg2',
      },
      {
        type: 'assistantText',
        autorun: true,
        contentKey: 'chatTutor.steps.tarot.msg3',
      },
      {
        type: 'tarot',
        autorun: true,
      },
      {
        type: 'userText',
        autorun: false,
        contentKey: 'chatTutor.steps.tarot.msg4',
      },
      {
        type: 'assistantText',
        autorun: true,
        contentKey: 'chatTutor.steps.tarot.msg5',
      },
    ],
  },
  {
    id: 'palmReading',
    substeps: [
      {
        type: 'assistantText',
        autorun: true,
        contentKey: 'chatTutor.steps.palmReading.msg1',
      },
      {
        type: 'takePalmPhoto',
        autorun: false,
      },
    ],
  },
  {
    id: 'palmReadingFail',
    substeps: [
      {
        type: 'assistantText',
        autorun: true,
        contentKey: 'chatTutor.steps.palmReadingFail.msg1',
      },
      {
        type: 'assistantText',
        autorun: true,
        contentKey: 'chatTutor.steps.palmReadingFail.msg2',
      },
      {
        type: 'userText',
        autorun: false,
        contentKey: 'chatTutor.steps.palmReadingFail.msg3',
      },
    ],
  },
  {
    id: 'palmReadingSuccess',
    substeps: [
      {
        type: 'assistantText',
        autorun: true,
        contentKey: 'chatTutor.steps.palmReadingSuccess.msg1',
      },
      {
        type: 'userText',
        autorun: false,
        contentKey: 'chatTutor.steps.palmReadingSuccess.msg2',
      },
      {
        type: 'assistantText',
        autorun: true,
        contentKey: 'chatTutor.steps.palmReadingSuccess.msg3',
      },
      {
        type: 'palmIntro',
        autorun: true,
        contentKey: 'chatTutor.steps.palmReadingSuccess.intro',
      },
      {
        type: 'palmReport',
        autorun: true,
      },
    ],
  },
  {
    id: 'palmReadingDisabled',
    substeps: [
      {
        type: 'assistantText',
        autorun: true,
        contentKey: 'chatTutor.steps.palmReadingFail.msg2',
      },
      {
        type: 'userText',
        autorun: false,
        contentKey: 'chatTutor.steps.palmReadingFail.msg3',
      },
    ],
  },
  {
    id: 'finish',
    substeps: [
      {
        type: 'endTutorial',
        autorun: true,
      },
    ],
  },
];

export const TUTORIAL_SUGGEST_CATEGORIES: TutorSuggestCategory[] = ['love', 'future', 'career'];
export const PALM_READING_STEPS: TutorStepId[] = ['palmReading', 'palmReadingFail', 'palmReadingSuccess'];
export const DEFAULT_CARD_NAME = 'ten_of_cups';
