import type { ZodiacSign } from '@wowmaking/birthchart';

import { fixBooleanParams } from 'utils/api';

import api from '..';

import type { Question, SuggestsResponseData, ProactiveRequestData, AdvisorsTarotCardNames, ProactiveResponseData } from './interfaces';

const QUESTIONS = '/questions/ask';
const ASTROLOGY_QUESTIONS = '/astrology-questions';

export const sendDataToAstrologers = (email: string, question: string, sign?: ZodiacSign): Promise<Question> => {
  return api.fetch(`${QUESTIONS}`, {
    method: 'POST',
    data: {
      email,
      question,
      sign,
    },
  });
};

export const sendAstrologersQuestion = (question: string): Promise<null> => {
  return api.fetch(`${ASTROLOGY_QUESTIONS}/create-question`, {
    method: 'POST',
    data: {
      question,
    },
  });
};

export const getAdvisorSuggests = async (): Promise<SuggestsResponseData | null> => {
  try {
    const res = api.fetch(`${ASTROLOGY_QUESTIONS}/suggests`, {
      method: 'GET',
    });

    return res || null;
  } catch (err) {
    console.log('ERROR getAdvisorSuggests', err);
  }

  return null;
};

export const generateProactiveNotification = async (params: ProactiveRequestData): Promise<ProactiveResponseData | null> => {
  const data = fixBooleanParams(params);

  try {
    const response = await api.fetch(`${ASTROLOGY_QUESTIONS}/add-notification`, {
      method: 'POST',
      data,
    });

    return response;
  } catch (error) {
    console.log('ERROR: Generate proactive notification', error);
  }
  return null;
};

export const getAdvisorsTarotCardNames = async (): Promise<AdvisorsTarotCardNames[]> => {
  try {
    const response = await api.fetch(`${ASTROLOGY_QUESTIONS}/tarot`, {
      method: 'GET',
    });

    return response;
  } catch (error) {
    console.log('ERROR [Advisors tarot card names] getAdvisorsTarotCardNames', error);
    return [];
  }
};
