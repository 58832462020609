export const fixBooleanParams = (params: object): object => {
  if (!params || typeof params !== 'object') {
    return params;
  }

  const result = {};

  for (const key in params) {
    const value = params[key];

    switch (typeof value) {
      case 'boolean': {
        result[key] = value ? 1 : 0;
        break;
      }

      /* For now apply only for root params */

      // case 'object': {
      //   result[key] = value ? fixBooleanParams(value) : value;
      //   break;
      // }

      default: {
        result[key] = value;
      }
    }
  }

  return result;
};
