import React, { useEffect } from 'react';
import type { FC } from 'react';
import { StyleSheet, View, StatusBar, Platform } from 'react-native';

import BackgroundPlacement from 'components/background-placement';
import LoaderOverlay from 'components/loader/overlay';
import NotificationHandler from 'components/notification-handler';
import RateUsModal from 'components/rate-us/modal';
import WebNotificationRequestOverlay from 'components/web-notification-request-overlay';
import { BLACK_OPACITY } from 'constants/colors';
import StaticHooksRunner from 'hooks/static-hooks-runner';
import useBackHandler from 'hooks/use-back-handler';
import useChatTimeBalance from 'hooks/use-chat-time-balance';
import useEventsRetrieve from 'hooks/use-events-retrieve';
import NoInternetModal from 'reusable/no-internet-modal';
import { useAppDispatch, useAppSelector } from 'store';
import { onMountApp } from 'store/app/actions';
import { isStandalone } from 'utils/pwa';

import AstroCalendarNotification from './astro-calendar/components/astro-calendar-notification';
import AdvisorAstroevent from './modal/advisor-astroevent';
import AdvisorServiceInfoModal from './modal/advisor-service-info';
import BirthChartReportModal from './modal/birth-chart-report';
import OfferWelcome from './modal/family/offer';
import FeedbackModal from './modal/feedback';
import InstallMobileApp from './modal/install-mobile-app';
import MessengersModal from './modal/messengers';
import PersonalReportsModal from './modal/personal-reports';
import TrustPilotPromoModal from './modal/trust-pilot-promo';
import WebAstrologersOneTimeModal from './modal/web-astrologers-one-time';
import WebBirthChartModal from './modal/web-birth-chart';
import WebFreeConsultationModal from './modal/web-free-consultation';
import WebGetPDFGuideModal from './modal/web-get-pdf-reports';
import WebReactivationModal from './modal/web-reactivation';
import ReactivationThankYouModal from './modal/web-reactivation/thank-you';
import Navigator from './navigator';
import PWAInstructionsModal from './web/pwa-instructions';

const App: FC = () => {
  const dispatch = useAppDispatch();
  const isOverlayLoaderActive = useAppSelector(state => state.app.isOverlayLoaderActive);
  const feedbackUrl = useAppSelector(state => state.remoteConfig.feedbackUrl);

  useBackHandler();
  useEventsRetrieve();
  useChatTimeBalance();

  useEffect(() => {
    dispatch(onMountApp());
  }, []);

  return (
    <View style={styles.container}>
      <StatusBar barStyle="light-content" translucent backgroundColor="transparent" animated />
      <Navigator />
      <NoInternetModal />
      <RateUsModal />
      {feedbackUrl ? <FeedbackModal /> : null}
      <LoaderOverlay visible={isOverlayLoaderActive} style={{ backgroundColor: BLACK_OPACITY }} />
      <OfferWelcome />
      <AdvisorAstroevent />
      <MessengersModal />
      <AstroCalendarNotification />
      <PersonalReportsModal />
      {Platform.OS === 'web' && (
        <>
          <WebReactivationModal />
          <ReactivationThankYouModal />
          <WebGetPDFGuideModal />
          <WebBirthChartModal />
          <PWAInstructionsModal />
          <WebFreeConsultationModal />
          <WebAstrologersOneTimeModal />
          {!isStandalone() && <InstallMobileApp />}
          <TrustPilotPromoModal />
        </>
      )}
      <AdvisorServiceInfoModal />
      <BirthChartReportModal />
      <BackgroundPlacement />
      <NotificationHandler />
      <WebNotificationRequestOverlay />
      <StaticHooksRunner />
    </View>
  );
};

export default App;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
