import { createSelector } from 'reselect';

import type { ProactiveNotificationTypeCodes, ProactiveRequestData } from 'api/astrologers/interfaces';
import type { EXTERNAL_TRIGGER_NOTIFICATIONS } from 'components/trigger-notification/components';
import { NOTIFICATIONS } from 'constants/notification-center';
import type { AdvisorNotificationTypes } from 'constants/notification-center';
import type { RootState } from 'store';

import { PROACTIVE_TYPES_BY_CODE } from './constants';
import type { ProactiveNotification } from './types';

const getTriggerNotifications = (state: RootState) => state.astrologers.notifications.triggerNotifications;
const getAdvisorNotificationConfig = (state: RootState) => state.remoteConfig.advisorNotificationConfig;
const getAdvisorOnlineStatus = (state: RootState) => state.remoteConfig.advisorOnlineStatusesEnabled;
const getAlternateAdvisorNotificationConfig = (state: RootState) => state.remoteConfig.alternateAdvisorNotificationConfig;
const getAstroeventTransit = (state: RootState) => state.transits.astroeventTransit;
const getNotificationData = (state: RootState) => state.astrologers.notifications.notification;
const getAlternateNotificationData = (state: RootState) => state.astrologers.notifications.alternateNotification;

export const selectTriggerNotificationSeconds = createSelector(
  [getTriggerNotifications, (_: RootState, notification: EXTERNAL_TRIGGER_NOTIFICATIONS) => notification],
  (notifications, notification) => {
    return notifications?.find(({ type }) => type === notification)?.seconds;
  },
);

export const selectProactiveNotificationRequestData = createSelector(
  [
    getAdvisorNotificationConfig,
    getAlternateAdvisorNotificationConfig,
    getAstroeventTransit,
    getAdvisorOnlineStatus,
    (_state: RootState, typeCode: ProactiveNotificationTypeCodes) => typeCode,
  ],
  (notificationConfig, alternateNotificationConfig, { title, duration }, advisorOnlineStatusEnabled, typeCode): ProactiveRequestData => {
    const notificationType = PROACTIVE_TYPES_BY_CODE[typeCode] || 'pt0';
    const transit = `'${title}' ${duration}`;

    switch (notificationType) {
      case 'pt0': {
        return {
          type: notificationType,
          onlyOnline: advisorOnlineStatusEnabled,
          categories: notificationConfig.categories,
          params: { transit },
        };
      }

      case 'pt1': {
        return {
          type: notificationType,
          onlyOnline: advisorOnlineStatusEnabled,
          categories: alternateNotificationConfig.categories,
          params: { transit },
        };
      }

      case 'pt2': {
        return {
          type: notificationType,
          onlyOnline: advisorOnlineStatusEnabled,
        };
      }

      case 'pt3': {
        return {
          type: notificationType,
          onlyOnline: advisorOnlineStatusEnabled,
        };
      }
    }
  },
);

export const selectProactiveNotificationData = createSelector(
  [getNotificationData, getAlternateNotificationData, (_state: RootState, notificationType: AdvisorNotificationTypes) => notificationType],
  (notificationData, alternateNotificationData, notificationType): ProactiveNotification => {
    return (notificationType === NOTIFICATIONS.PROACTIVE ? notificationData : alternateNotificationData) || ({} as ProactiveNotification);
  },
);
