import _ from 'lodash';
import { Platform } from 'react-native';
import uuid from 'react-native-uuid';

import { GOOGLE_CLOUD_API_KEY } from 'constants/general';

import api from '..';

import type { PalmReadingResponse, PalmReading } from './interfaces';

const GOOGLE_VISION_API = 'https://vision.googleapis.com/v1/images:annotate?key=' + GOOGLE_CLOUD_API_KEY;

const PALM_READING = '/image-recognition/palm-reading?hand_type=right';

export const GOOGLE_VISION_ERROR = "we didn't receive response from server";

/**
 * Google Cloud Vision Label Detection. See more: https://cloud.google.com/vision/docs/reference/rest/v1/images/annotate
 */
export function getImageLabels(base64Image: string) {
  return fetch(GOOGLE_VISION_API, {
    method: 'POST',
    body: JSON.stringify({
      requests: [
        {
          image: {
            content: base64Image,
          },
          features: [
            {
              type: 'LABEL_DETECTION',
            },
          ],
        },
      ],
    }),
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw response;
    })
    .catch(e => {
      console.warn(GOOGLE_VISION_API, e);
      return GOOGLE_VISION_ERROR;
      // throw e;
    });
}

export const getResultScore = (results: any, labels: any) =>
  _.sumBy(labels, (l: any) => _.find(results, r => r.description.toLowerCase() === l.toLowerCase())?.score || 0);

export const getPalm = (): Promise<PalmReadingResponse> => {
  return api.fetch(PALM_READING, {
    method: 'GET',
  });
};

export const postPalm = async (uri: string): Promise<PalmReading | null> => {
  try {
    let res: PalmReadingResponse | null = null;

    if (Platform.OS === 'web') {
      res = await api.fetch(PALM_READING, {
        method: 'POST',
        data: {
          image_file: uri.replace('data:image/jpeg;base64,', ''),
        },
      });
    } else {
      const form = new FormData();

      form.append('image_file', {
        uri,
        type: 'image/jpg',
        name: `${uuid.v4()}.jpg`,
      } as unknown as Blob);

      res = await api.fetch(
        PALM_READING,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: form,
        },
        true,
      );
    }

    if (res && 'palm_reading' in res && res.palm_reading) {
      return res.palm_reading;
    }
  } catch (err) {
    console.log('ERROR [Astrologist chat] postPalm', err);
  }

  return null;
};
