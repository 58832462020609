import type { AdvisorNotificationCategories, ProactiveNotificationTypeCodes, ProactiveNotificationTypes } from 'api/astrologers/interfaces';
import type { Astrologist, HistoryItem } from 'api/astrology-chat/types';
import type { EXTERNAL_TRIGGER_NOTIFICATIONS } from 'components/trigger-notification/components';

export type ProactiveNotification = {
  advisorId: Astrologist['astrologer_id'];
  type: ProactiveNotificationTypes;
  message: string;
  message_id: HistoryItem['id'];
  created_at: string;
  category: AdvisorNotificationCategories | 'none';
};

export type TriggerNotification = {
  type: EXTERNAL_TRIGGER_NOTIFICATIONS;
  seconds?: number;
};

export type ProactiveTypesByCode = {
  [key in ProactiveNotificationTypeCodes]: ProactiveNotificationTypes;
};

interface NotificationsState {
  notification: ProactiveNotification | null;
  alternateNotification: ProactiveNotification | null;
  isPressed: boolean;
  triggerNotifications: TriggerNotification[];
  isPromocodeAddedInPwa: boolean;
  generationDaysCounter: number;
  alternateGenerationDaysCounter: number;
}

export const initialState: NotificationsState = {
  notification: null,
  alternateNotification: null,
  isPressed: false,
  triggerNotifications: [],
  isPromocodeAddedInPwa: false,
  generationDaysCounter: 0,
  alternateGenerationDaysCounter: 0,
};
