import type { HistoryItem, Chats, Astrologist } from 'api/astrology-chat/types';
import type { MESSAGE_PLACE } from 'screens/advisors/constants';

import type { Question } from '../core/types';
import type { ResetUserData } from '../types';

export enum TYPES {
  SET_CHAT_MESSAGES = 'ASTROLOGERS/SET_CHAT_MESSAGES',
  SET_UNREAD_CHAT_COUNT = 'ASTROLOGERS/SET_UNREAD_CHAT_COUNT',
  SET_CURRENT_CHAT_ID = 'ASTROLOGERS/SET_CURRENT_CHAT_ID',
  SET_CURRENT_CHATS = 'ASTROLOGERS/SET_CURRENT_CHATS',
  SET_HIDDEN_MESSAGE = 'ASTROLOGERS/SET_HIDDEN_MESSAGE',
  RESET_ALL_CHATS = 'ASTROLOGERS/RESET_ALL_CHATS',
  SET_QUESTION = 'ASTROLOGERS/SET_QUESTION',
  SET_CHAT_REVIEW_COMPLETED = 'ASTROLOGERS/SET_CHAT_REVIEW_COMPLETED',
  SET_MESSAGE_PLACE = 'ASTROLOGERS/SET_MESSAGE_PLACE',
  SET_IS_PALM_READING_OFFER = 'ASTROLOGERS/SET_IS_PALM_READING_OFFER',
  SET_PALM_PHOTO = 'ASTROLOGERS/SET_PALM_PHOTO',
  SET_SUGGESTS_VISIBLE = 'ASTROLOGERS/SET_SUGGESTS_VISIBLE',
  SET_CURRENT_ASTROLOGER_ID = 'ASTROLOGERS/SET_CURRENT_ASTROLOGER_ID',
  SET_WAS_SESSION_CONVERSATION = 'ASTROLOGERS/SET_WAS_SESSION_CONVERSATION',
  SET_PLAYING_VOICE_ID = 'ASTROLOGERS/SET_PLAYING_VOICE_ID',
  SET_REVIEW_MODAL_VISIBLE = 'ASTROLOGERS/SET_REVIEW_MODAL_VISIBLE',
  SET_TYPING_IDS = 'ASTROLOGERS/SET_TYPING_IDS',
  SET_RECORDING_IDS = 'ASTROLOGERS/SET_RECORDING_IDS',
  SET_FORCED_ONLINE_ADVISOR_ID = 'ASTROLOGERS/SET_FORCED_ONLINE_ADVISOR_ID',
  HIDE_SOFT_CURRENCY_RATE_IN_CHAT = 'ASTROLOGERS/HIDE_SOFT_CURRENCY_RATE_IN_CHAT',
  SET_FORCE_TYPING = 'ASTROLOGERS/SET_FORCE_TYPING',
}

export interface State {
  chats: {
    [id: string]: {
      messages: HistoryItem[];
      isReviewCompleted: boolean;
      rate: number;
    };
  };
  unreadMessages: number;
  currentChatId: number;
  currentChats: Chats[];
  hiddenMessage: string;
  question: Question | null;
  messagePlace: MESSAGE_PLACE | null;
  currentAstrologerId: Astrologist['astrologer_id'];
  isSuggestsVisible: boolean;
  isPalmReadingOffer: boolean;
  palmPhoto: string;
  wasSessionConversation: boolean;
  playingVoiceId: string | null;
  typingIds: number[];
  recordingIds: number[];
  isReviewModalVisible: boolean;
  forcedOnlineAdvisorId: Astrologist['astrologer_id'];
  showSoftCurrencyRateInChat: boolean;
  forceTyping: boolean;
}

export interface SetChatMessages {
  type: typeof TYPES.SET_CHAT_MESSAGES;
  payload: {
    advisorId: Astrologist['astrologer_id'];
    messages: HistoryItem[];
  };
}

export interface SetUnreadMessagesCounter {
  type: typeof TYPES.SET_UNREAD_CHAT_COUNT;
  payload: number;
}

export interface SetTypingIds {
  type: typeof TYPES.SET_TYPING_IDS;
  payload: number[];
}

export interface SetRecordingIds {
  type: typeof TYPES.SET_RECORDING_IDS;
  payload: number[];
}

export interface ResetAllChats {
  type: typeof TYPES.RESET_ALL_CHATS;
  payload: void;
}

export interface SetCurrentChatId {
  type: typeof TYPES.SET_CURRENT_CHAT_ID;
  payload: number;
}

export interface SetCurrentChats {
  type: typeof TYPES.SET_CURRENT_CHATS;
  payload: Chats[];
}

export interface SetHiddenMessage {
  type: typeof TYPES.SET_HIDDEN_MESSAGE;
  payload: string;
}

export interface SetQuestion {
  type: typeof TYPES.SET_QUESTION;
  payload: Question | null;
}

export interface SetChatReviewCompleted {
  type: typeof TYPES.SET_CHAT_REVIEW_COMPLETED;
  payload: {
    advisorId: Astrologist['astrologer_id'];
    isReviewCompleted: boolean;
    rate: number;
  };
}

export interface SetMessagePlace {
  type: typeof TYPES.SET_MESSAGE_PLACE;
  payload: MESSAGE_PLACE | null;
}

export interface SetCurrentAstrologerId {
  type: typeof TYPES.SET_CURRENT_ASTROLOGER_ID;
  payload: Astrologist['astrologer_id'];
}

export interface SetSuggestsVisible {
  type: typeof TYPES.SET_SUGGESTS_VISIBLE;
  payload: boolean;
}

export interface SetPalmPhoto {
  type: typeof TYPES.SET_PALM_PHOTO;
  payload: string;
}

export interface SetIsPalmReadingOffer {
  type: typeof TYPES.SET_IS_PALM_READING_OFFER;
  payload: boolean;
}

export interface SetWasSessionConversation {
  type: typeof TYPES.SET_WAS_SESSION_CONVERSATION;
  payload: boolean;
}

export interface SetReviewModalVisible {
  type: typeof TYPES.SET_REVIEW_MODAL_VISIBLE;
  payload: boolean;
}

export interface SetPlayingVoiceId {
  type: typeof TYPES.SET_PLAYING_VOICE_ID;
  payload: string | null;
}

export interface SetForcedOnlineAdvisorId {
  type: typeof TYPES.SET_FORCED_ONLINE_ADVISOR_ID;
  payload: Astrologist['astrologer_id'];
}

export interface HideSoftCurrencyRateInChat {
  type: typeof TYPES.HIDE_SOFT_CURRENCY_RATE_IN_CHAT;
  payload: void;
}

export interface SetForceTyping {
  type: typeof TYPES.SET_FORCE_TYPING;
  payload: boolean;
}

export type ActionType =
  | SetChatReviewCompleted
  | SetChatMessages
  | SetUnreadMessagesCounter
  | SetTypingIds
  | SetRecordingIds
  | ResetAllChats
  | SetCurrentChatId
  | SetCurrentChats
  | SetHiddenMessage
  | SetQuestion
  | SetMessagePlace
  | SetCurrentAstrologerId
  | SetIsPalmReadingOffer
  | SetPalmPhoto
  | SetSuggestsVisible
  | SetWasSessionConversation
  | ResetUserData
  | SetReviewModalVisible
  | SetPlayingVoiceId
  | SetForcedOnlineAdvisorId
  | HideSoftCurrencyRateInChat
  | SetForceTyping;
