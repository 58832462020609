import { fs, paddingHorizontal, paddingVertical, sw } from '@wowmaking/ui/src/utils';
import { take } from 'lodash';
import React, { memo, useCallback } from 'react';
import type { FC } from 'react';
import { StyleSheet, View, ScrollView, TouchableOpacity } from 'react-native';
import type { StyleProp, ViewStyle } from 'react-native';

import Analytics from 'analytics';
import Icon from 'components/icon';
import Text from 'components/text';
import * as COLORS from 'constants/colors';
import { ADVISORS_CHATS } from 'constants/routes';
import { t } from 'localization';
import { useAppDispatch, useAppSelector } from 'store';
import { selectAnAdvisor } from 'store/astrologers/catalog/actions';
import { selectHoroscopeSubscriptionsAndActiveChats } from 'store/astrologers/selectors';
import { navigate } from 'store/navigation/actions';

import ActionButton from '../../../../components/action-button';

import ChatPreviewItem from './components/chat-preview-item';

const NUMBER_OF_CHATS_PREVIEWS = 7;

interface Props {
  style?: StyleProp<ViewStyle>;
}

const MyChatsBlock: FC<Props> = ({ style }) => {
  const dispatch = useAppDispatch();

  const astrologers = useAppSelector(state => state.astrologers.core.allAstrologers);
  const chats = useAppSelector(selectHoroscopeSubscriptionsAndActiveChats);
  const horoscopeSubscriptions = useAppSelector(state => state.astrologers.horoscope.subscriptions);

  const showViewAllButton = chats.length > NUMBER_OF_CHATS_PREVIEWS;

  const handleViewAllPress = () => {
    Analytics.trackEvent('AdvisorsCatalog', 'MyChatsViewAll_Click');
    navigate(ADVISORS_CHATS);
  };

  const handleChatPress = useCallback((id: number | string) => {
    Analytics.trackEvent('AdvisorsCatalog', 'MyChat_Click', { id });
    dispatch(selectAnAdvisor(id));
  }, []);

  if (!chats.length) {
    return null;
  }

  return (
    <View style={[styles.root, style]}>
      <View style={styles.titleContainer}>
        <Text font={'Philosopher'} style={styles.title}>
          {t('ADVISORS_CATALOG.CHATS.TITLE')}
        </Text>
        {showViewAllButton ? <ActionButton title={t('ADVISORS_CATALOG.CHATS.BUTTON')} onPress={handleViewAllPress} /> : null}
      </View>
      <ScrollView style={styles.scrollContainer} contentContainerStyle={styles.contentContainer} showsHorizontalScrollIndicator={false} horizontal>
        {take(chats, NUMBER_OF_CHATS_PREVIEWS).map(chat => {
          const advisor = astrologers.find(astrologist => `${astrologist.astrologer_id}` === `${chat.advisorId}`);

          if (!advisor) {
            return null;
          }

          const unreadMessages = chat.messages.filter(message => {
            return !message?.is_viewed_by_user && message?.type === 'answer';
          }).length;

          const horoscopeSubscription = horoscopeSubscriptions.find(subscription => `${subscription.id}` === `${advisor.astrologer_id}`);
          const unreadHoroscopeMessages = horoscopeSubscription ? (horoscopeSubscription.isViewed ? 0 : 1) : 0;

          return (
            <ChatPreviewItem
              key={advisor.astrologer_id}
              advisor={advisor}
              unreadCount={unreadMessages + unreadHoroscopeMessages}
              onPress={handleChatPress}
            />
          );
        })}
        {showViewAllButton ? (
          <TouchableOpacity onPress={handleViewAllPress}>
            <View style={styles.viewAllButton}>
              <Icon name={'arrow'} size={sw(16)} color={COLORS.AQUA} />
            </View>
            <Text style={styles.viewAllButtonTitle}>{t('ADVISORS_CATALOG.CHATS.BUTTON')}</Text>
          </TouchableOpacity>
        ) : null}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    width: '100%',
    marginBottom: paddingVertical(30),
  },
  titleContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: fs(25),
    fontWeight: '700',
    color: COLORS.BEIGE_2,
  },
  scrollContainer: {
    marginTop: paddingVertical(10),
    marginHorizontal: paddingHorizontal(-15),
  },
  contentContainer: {
    paddingHorizontal: paddingHorizontal(15),
  },
  viewAllButton: {
    width: sw(60),
    height: sw(60),
    borderRadius: sw(50),
    backgroundColor: COLORS.DARK_TEAL,
    justifyContent: 'center',
    alignItems: 'center',
  },
  viewAllButtonTitle: {
    fontSize: fs(12),
    fontWeight: '400',
    color: COLORS.AQUA,
    textAlign: 'center',
    marginTop: paddingVertical(8),
  },
});

export default memo(MyChatsBlock);
