import DeviceProps from '@magnus/react-native-device-props';
import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { Platform } from 'react-native';

import Analytics from 'analytics';
import type { ProactiveNotificationTypes } from 'api/astrologers/interfaces';
import type { HistoryItem, HistoryItemMetadata, ChatPlatforms } from 'api/astrology-chat/types';
import { PROJECT_ID } from 'constants/general';
import { isStandalone } from 'utils/pwa';

import { PROACTIVE_TYPES } from '../notifications/constants';

export const convertMessagesToHistory = (messages: HistoryItem[]): HistoryItem[] => {
  return messages
    .reduce<HistoryItem[]>((acc, item, index) => {
      const isFirst = index === 0;
      const separator = getSectionItem(item.created_at);

      if (isFirst) {
        return [separator, item, ...acc];
      }

      const lastItemInAcc = acc[acc.length - 1];

      if (!dayjs(item.created_at).isSame(lastItemInAcc.created_at, 'day')) {
        return [...acc, separator, item];
      }

      return [...acc, item];
    }, [])
    .reverse();
};

export const appendMessageToHistory = (params: { message: HistoryItem; history: HistoryItem[] }): HistoryItem[] => {
  const { message, history } = params;
  const addSeparator = !history.length || !dayjs(message.created_at).isSame(history[0].created_at, 'day');
  return addSeparator ? [message, getSectionItem(message.created_at), ...history] : [message, ...history];
};

export const getSectionItem = (date: string): HistoryItem => {
  return getHistoryItemTemplate({
    id: `section_${dayjs(date).add(1000, 'second').format('YYYY-MM-DD HH:mm:ss')}`,
    type: 'section',
    created_at: dayjs(date).add(1000, 'second').format('YYYY-MM-DD HH:mm:ss'),
  });
};

export const trackSpecificMessageEvent = (message: HistoryItem) => {
  if (checkMessageMetadataType(message, 'tarotCard')) {
    Analytics.trackEvent('Astrologist', 'Tarot_Card_Received');
  }

  if (checkMessageMetadataType(message, 'voiceMessage')) {
    Analytics.trackEvent('Voice_Message', 'Received');
  }

  if (checkMessageMetadataType(message, 'palmRequest')) {
    Analytics.track('advisor_palm_got_scan_offer');
  }

  if (message.metadata?.type && checkIsProactiveMessageType(message.metadata.type)) {
    Analytics.track('ProactiveNotification_Received', { notificationType: message.metadata.type });
  }
};

export const checkMessageMetadataType = (message: HistoryItem, type: HistoryItemMetadata['type']): boolean => {
  return message.metadata?.type === type;
};

export const checkIsProactiveMessageType = (type: HistoryItemMetadata['type']): type is ProactiveNotificationTypes => {
  return (PROACTIVE_TYPES as unknown as string[]).includes(type as string);
};

export const getChatParams = () => {
  return {
    platform: Platform.select<ChatPlatforms>({
      ios: 'ios',
      android: 'android',
      web: isStandalone() ? 'pwa' : 'rnw',
      default: 'undetected',
    }),
    webProject: PROJECT_ID,
    version: DeviceProps.getAppVersion(),
  };
};

export const getHistoryItemTemplate = (item: Partial<HistoryItem>): HistoryItem => {
  return {
    id: Math.random().toString(16),
    type: 'question',
    message: '',
    created_at: dayjs.utc(dayjs()).format('YYYY-MM-DD HH:mm:ss'),
    is_answered: true,
    is_viewed: false,
    is_viewed_by_user: true,
    is_blurred: false,
    ...item,
  };
};

export const isArraysDifferent = (arr1: number[], arr2: number[]): boolean => {
  return arr1.length !== arr2.length || !isEqual(arr1.sort(), arr2.sort());
};
